import api from '../api'

interface RegisterByDocumentIdServiceProps {
  documentId: string
}
class RegisterByDocumentIdService {
  async execute({
    documentId
  }: RegisterByDocumentIdServiceProps): Promise<any> {
    const url = `/v1/auth/documentId/${documentId}`

    const data = await api
      .get(url)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })
    return data
  }
}

export { RegisterByDocumentIdService }
