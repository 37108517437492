import React from 'react'
import { Box, Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setSnackbar } from '../../redux/slice/snackbarSlice'
import { useNavigate } from 'react-router-dom'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})
export const SnackbarMessage = () => {
  const dispatch = useAppDispatch()
  const snackbar = useAppSelector(state => state.snackbar)

  const navigate = useNavigate()

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(
      setSnackbar({
        isOpen: false,
        message: '',
        link: undefined,
        duration: 6000
      })
    )
  }

  const handleGoTo = () => {
    if (snackbar.link) {
      navigate(`${snackbar.link}`)
      handleClose()
    }
  }

  return (
    <Snackbar
      // open={true}
      open={snackbar.isOpen}
      autoHideDuration={snackbar.duration}
      onClose={handleClose}
      anchorOrigin={snackbar.anchor}
      sx={{ zIndex: 9999 }}
    >
      <Alert onClose={handleClose} severity={snackbar.severity}>
        {snackbar.link ? (
          <Box
            onClick={handleGoTo}
            sx={{
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            {snackbar.message}
          </Box>
        ) : (
          snackbar.message
        )}
      </Alert>
    </Snackbar>
  )
}
