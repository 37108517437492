import {
  Box,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import logo4IES from '../../assets/images/logo/logo-4ies-cinza.png'
import { Image } from '../Image'

interface Props {
  sx?: SxProps
  help?: boolean
  whatsapp?: boolean
}
const CoBrandingMark = ({ sx }: Props) => {
  const theme = useTheme()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#D9D9D9',
        position: 'fixed',
        height: theme.spacing(4),
        zIndex: 9999,
        bottom: 0,
        left: 0,
        right: 0,
        ...sx
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: matches ? 'center' : 'end',
          alignItems: 'center',
          px: 2
        }}
      >
        <Typography
          sx={{
            fontSize: theme.spacing(1.2),
            color: '#545454'
          }}
        >
          2023 - FINANCEIRO EDUCACIONAL SOB GESTÃO
        </Typography>
        <Box
          sx={{
            height: theme.spacing(2.5),
            width: theme.spacing(2.5)
            // p: '4px',
            // backgroundColor: 'red'
          }}
        >
          <Image
            src={logo4IES}
            alt=""
            sx={{
              objectFit: 'contain'
              // width: theme.spacing(7),
              // height: theme.spacing(7)
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export { CoBrandingMark }
