import React, { SyntheticEvent, useState } from 'react'

import Layout from '../../components/Layout'
import { Button } from '../../components/Button'

import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Fab,
  Grid,
  List,
  Paper,
  Stack,
  styled,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import ChevronLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ChevronRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

import DebtListItem from '../../components/DebtListItem'
import TabPanel from '../../components/TabPanel'
import DebtsListTotal from '../../components/DebtsListTotal'
import {
  getDebtsByCustomerAndOrganizationStart,
  getDebtsRecalculatedStart
} from '../../redux/slice/debtSlice'
import { getOrganizationByCustomerStart } from '../../redux/slice/organizationSlice'
import { Debt, DebtSituation } from '../../shared/interfaces/debt'
import { CreateChargeService } from '../../services/charge/CreateChargeService'
import { GetChargesByCustomerService } from '../../services/charge/GetChargesByCustomerService'
import { Charge } from '../../shared/interfaces/charge'
import { CancelChargeService } from '../../services/charge/CancelChargeService'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useNavigate } from 'react-router-dom'
import { delay } from '../../utils/delay'
import PageTitle from '../../components/PageTitle'
import { UserPreferencesDialog } from '../../components/UserPreferencesDialog'
import { UpdateUserCommunicationPreferencesService } from '../../services/user/UpdateUserCommunicationPreferencesService'
import PaperTextItem from '../../components/PaperTextItem'
import { grey } from '@mui/material/colors'
import { formatCurrency, formatDate } from '../../utils/format'
import { GetDebtsRecalculatedService } from '../../services/debt/GetDebtsRecalculatedService'
import { activityEnd, activityStart } from '../../redux/slice/appSlice'
import { getUserDataStart } from '../../redux/slice/userSlice'
import { ActiveBilletAlert } from '../../components/ActiveBilletAlert'
import { NewsPopup } from '../../components/NewsPopup'

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}
const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 3,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  // position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)'
}))

// const debtList = [
//   { debtId: 11, dueDate: '10/01/2022' },
//   { debtId: 11, dueDate: '10/01/2022' },
//   { debtId: 11, dueDate: '10/01/2022' },
//   { debtId: 11, dueDate: '10/01/2022' },
//   { debtId: 22, dueDate: '10/02/2023' }
// ]

const Board = () => {
  const theme = useTheme()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { data: authData, customer } = useAppSelector(state => state.auth)
  const { customerSettings } = useAppSelector(state => state.app)

  const { user } = useAppSelector(state => state.user)
  const { debts } = useAppSelector(state => state.debt)
  const { activity } = useAppSelector(state => state.app)
  const { organizations } = useAppSelector(state => state.organization)

  const [selectedDebts, setSelectedDebts] = useState<
    { debtId: number; selected: boolean }[]
  >([])
  const [total, setTotal] = useState({ subTotal: 0, additional: 0, total: 0 })

  // Tabs
  const [tabValue, setTabValue] = useState(0)
  const [collapse, setCollapse] = useState(false)
  const [chargeModalOpen, setChargeModalOpen] = useState(false)

  const [userPreferencesOpen, setUserPreferencesOpen] = useState(false)

  const [chargesCreated, setChargesCreated] = useState<Charge[]>([])
  const [chargesActive, setChargesActive] = useState<Charge[]>([])
  const [chargesPending, setChargesPending] = useState<Charge[]>([])
  // const [chargeActive, setChargeActive] = useState<Charge[]>([])

  const [detailOpen, setDetailOpen] = useState(false)
  const [debtDetail, setDebtDetail] = useState<Debt>()

  const [chargeActiveIndex, setChargeActiveIndex] = useState(0)
  const [chargePendingIndex, setChargePendingIndex] = useState(0)
  const [isOpenBilletActiveAlert, setIsOpenBilletActiveAlert] = useState(false)
  const [isOpenBilletPendingAlert, setIsOpenBilletPendingAlert] =
    useState(false)

  // TODO: remove after api done
  const [TEMP_DEBT, setTempDebt] = useState('COUNTERPROPOSAL')

  React.useEffect(() => {
    sessionStorage.removeItem('billet_alert')
    if (customer) {
      if (
        !authData?.user.typeCommunication ||
        !authData?.user.timeCommunication
      ) {
        const preferences = sessionStorage.getItem('preferences')
        if (!preferences) {
          setUserPreferencesOpen(true)
        }
      }
      // handleVerifyChargesByCustomer()

      dispatch(getUserDataStart(customer.id))

      dispatch(
        getOrganizationByCustomerStart({
          idCustomer: Number(customer?.id)
        })
      )
    }
  }, [])

  React.useEffect(() => {
    if (user && organizations.length > 0) {
      dispatch(activityStart({ loading: true, rule: 'tab.change' }))
      getDebtsByCustomerAndOrganization()
      handleVerifyChargesByCustomer()
    }
  }, [tabValue, organizations])

  React.useEffect(() => {
    if (debts.length) {
      const newSelected = debts.map(item => ({
        debtId: item.id,
        selected: !!item.selected
      }))

      setSelectedDebts(newSelected)
      handleUpdateTotal(debts.filter(item => item.selected))
      dispatch(activityEnd())
    }
  }, [debts])

  const getDebtsByCustomerAndOrganization = async () => {
    dispatch(
      getDebtsByCustomerAndOrganizationStart({
        idCustomer: Number(customer?.id),
        idOrganization: organizations[tabValue].id
      })
    )
  }

  const handleCloseUserPreferences = () => {
    setUserPreferencesOpen(false)
  }

  const handleSubmitUserCommunicationPreferences = async (props: {
    timeCommunication: string
    typeCommunication: string
  }) => {
    const service = new UpdateUserCommunicationPreferencesService()
    await service.execute(props)
    sessionStorage.setItem('preferences', 'ok')
    handleCloseUserPreferences()
  }

  const handleToggle = (item: { debtId: number }) => () => {
    dispatch(activityStart({ loading: true, rule: 'debt.select' }))
    let newSelected = [...selectedDebts]

    // Encontra na store o débito do evento
    const currentDebt = debts.find(debt => debt.id === item.debtId)
    // newSelected.push({ debtId: 4444, selected: true })
    if (currentDebt?.negativation) {
      // Se o débito alvo for negativado,
      // será aplicada a mesma ação para os outros débitos negativados
      const negativedDebts = debts.filter(debt => debt.negativation)
      negativedDebts.forEach(negatived => {
        // Verifica os débitos negativados e aplica a alteração em todos negativados
        newSelected = newSelected.map(debt => {
          if (debt.debtId === negatived.id) {
            return { ...debt, selected: !debt.selected }
          }
          return debt
        })
      })
    } else {
      newSelected = newSelected.map(debt => {
        if (debt.debtId === item.debtId) {
          return { ...debt, selected: !debt.selected }
        }
        return debt
      })
    }

    if (newSelected.filter(debt => debt.selected).length < 1) {
      dispatch(activityEnd())
      return
    }
    // Solicita o recalculo dos débitos e atualiza o estado
    setSelectedDebts(newSelected)
    dispatch(
      getDebtsRecalculatedStart({
        idPersonDebt: Number(user?.id),
        idCustomer: Number(customer?.id),
        idOrganization: organizations[tabValue].id,
        debts: newSelected.filter(debt => debt.selected)
      })
    )
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    // TODO: remove setChecked([])
    setTotal({ subTotal: 0, additional: 0, total: 0 })
    setTabValue(newValue)
  }

  const handleCollapseToggle = () => {
    setCollapse(!collapse)
  }

  const handleUpdateTotal = (debts: Debt[]) => {
    setTotal({
      subTotal: debts.reduce((sum, current) => sum + current.amount, 0),
      additional: debts.reduce(
        (sum, current) =>
          sum + current.fine + current.interest + current.additionalValues,
        0
      ),
      total: debts.reduce((sum, current) => sum + current.totalAmount, 0)
    })
  }

  const handleCreateCharge = async (
    event?: SyntheticEvent<HTMLButtonElement>
  ) => {
    const buttonSource = event?.currentTarget.name

    const service = new CreateChargeService()
    dispatch(activityStart({ loading: true, rule: 'create.charge' }))
    service
      .execute({
        idCustomer: Number(customer?.id),
        idOrganization: organizations[tabValue].id,
        totalAmount: Number(total.total.toFixed(2)),
        debts: selectedDebts.filter(item => item.selected),
        ...(buttonSource === 'detailPaymentButton' && {
          totalAmount: Number(debtDetail?.totalAmount.toFixed(2)),
          debts: [{ debtId: Number(debtDetail?.id) }]
        })
      })
      .then(response => {
        dispatch(activityEnd())
        navigate(`/checkout/${response.shareHash}`)
      })
      .catch(err => {
        dispatch(activityEnd())
        console.log({ err })
      })
  }

  const handleCancelCharges = async () => {
    dispatch(activityStart({ loading: true, rule: 'chargeDialog.cancel' }))
    chargesCreated.forEach(charge => {
      handleCancelCharge(charge.id, Number(customer?.id))
    })
    delay(1).then(() => {
      dispatch(
        getDebtsByCustomerAndOrganizationStart({
          idCustomer: Number(customer?.id),
          idOrganization: organizations[tabValue].id
        })
      )
      setChargeModalOpen(false)
      dispatch(activityEnd())
    })
  }

  const handleCancelCharge = async (idCharge: number, idCustomer: number) => {
    const service = new CancelChargeService()

    if (user) {
      service
        .execute({
          idCharge,
          idCustomer
        })
        .then(() => {
          console.log('canceled')
        })
        .catch(err => {
          throw err
        })
    }
  }

  const handleGoToCheckout = (hash: string) => {
    dispatch(activityStart({ loading: true, rule: 'chargeDialog.confirm' }))
    navigate(`/checkout/${hash}`)
    dispatch(activityEnd())
  }

  const handleVerifyChargesByCustomer = async () => {
    const service = new GetChargesByCustomerService()
    const response = await service.execute({
      idCustomer: Number(customer?.id)
    })
    if (response.length > 0) {
      const created = response.filter(
        item => item.type === 'DEFAULT' && item.status === 'CREATED'
      )
      const active = response.filter(
        item => item.type === 'BOLETO_PIX' && item.status === 'ACTIVE'
      )
      const pending = response.filter(
        item => item.type === 'BOLETO_PIX' && item.status === 'PENDING'
      )
      if (created.length > 0) {
        setChargesCreated(created)
        setChargeModalOpen(true)
      } else {
        setChargesCreated([])
      }
      if (active.length > 0) {
        setChargesActive(active)
        handleOpenBilletActiveAlert()
      } else {
        setChargesActive([])
      }
      if (pending.length > 0) {
        setChargesPending(pending)
        handleOpenBilletPendingAlert()
      } else {
        setChargesPending([])
      }
    }
  }

  const handleOpenDetail = async ({ debt }: { debt: Debt }) => {
    const service = new GetDebtsRecalculatedService()
    if (debt.negativation) {
      const negativedDebts = debts.filter(item => item.negativation)
      const response = await service.execute({
        debts: negativedDebts.map(item => ({ debtId: item.id })),
        idCustomer: Number(customer?.id),
        idPersonDebt: Number(user?.id),
        idOrganization: debt.organization.id
      })
      setDebtDetail(response.find(item => item.id === debt.id))
      setDetailOpen(true)
      return
    }
    const response = await service.execute({
      debts: [{ debtId: debt.id }],
      idCustomer: Number(customer?.id),
      idPersonDebt: Number(user?.id),
      idOrganization: debt.organization.id
    })
    setDebtDetail(response[0])
    setDetailOpen(true)
  }

  const handleCloseDetail = () => {
    setDebtDetail(undefined)
    setDetailOpen(false)
  }

  const handleOpenBilletActiveAlert = () => {
    setIsOpenBilletActiveAlert(true)
  }
  const handleOpenBilletPendingAlert = () => {
    setIsOpenBilletPendingAlert(true)
  }

  const handleAlreadyHasPaidBilletActive = () => {
    const idActive = chargesActive[chargeActiveIndex].id
    const newChargesActive = chargesActive.filter(item => item.id !== idActive)
    setChargeActiveIndex(0)
    setChargesActive(newChargesActive)
    if (newChargesActive.length === 0) {
      setIsOpenBilletActiveAlert(false)
    }
  }
  const handleAlreadyHasPaidBilletPending = () => {
    const idPending = chargesPending[chargePendingIndex].id
    const newChargesPending = chargesPending.filter(
      item => item.id !== idPending
    )
    setChargePendingIndex(0)
    setChargesPending(newChargesPending)
    if (newChargesPending.length === 0) {
      setIsOpenBilletPendingAlert(false)
    }
  }

  if (!user) {
    return (
      <Box
        sx={{
          // backgroundColor: theme.palette.primary.main,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    )
  }

  return (
    <Layout title="Home">
      {/* <SelectSource /> */}
      <NewsPopup
        principiaMigration={customerSettings.principiaMigration}
        customer={customer?.displayName ?? ''}
      />
      <UserPreferencesDialog
        activity={activity}
        open={userPreferencesOpen}
        onClose={handleCloseUserPreferences}
        customer={String(customer?.displayName)}
        onSubmit={handleSubmitUserCommunicationPreferences}
      />
      {/* TODO: Component */}
      <Dialog
        open={chargeModalOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Pagamento${chargesCreated.length > 1 ? 's' : ''} não finalizado${
            chargesCreated.length > 1 ? 's' : ''
          }`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Você possui ${chargesCreated.length} operaç${
              chargesCreated.length > 1 ? 'ões' : 'ão'
            } de pagamento não finalizada${
              chargesCreated.length > 1 ? 's' : ''
            }, deseja prosseguir para pagamento ou
						cancelar a${chargesCreated.length > 1 ? 's' : ''} operaç${
              chargesCreated.length > 1 ? 'ões' : 'ão'
            }?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelCharges}
            // color="primary"
            loading={
              activity.rule === 'chargeDialog.cancel' && activity.loading
            }
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleGoToCheckout(chargesCreated[0].shareHash)}
            variant="contained"
            color="primary"
            loading={
              activity.rule === 'chargeDialog.confirm' && activity.loading
            }
          >
            Ir para pagamento
          </Button>
        </DialogActions>
      </Dialog>
      {/* TODO: Component END */}
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: '1100px'
          },
          p: 2
        }}
      >
        <PageTitle
          title={`Olá ${user?.name.split(' ')[0]}`}
          subtitle={
            <Typography>
              acompanhe aqui os valores das suas parcelas na{' '}
              <strong>{customer?.displayName}</strong>
            </Typography>
          }
          divider={false}
        />
        <Paper
          {...(matches && { elevation: 0 })}
          sx={{
            p: { xs: 0, md: 2 },
            ...(matches && { backgroundColor: 'transparent' })
          }}
        >
          {organizations.length < 1 && (
            <Box
              sx={{
                height: theme.spacing(50),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography>Não possui débitos ativos.</Typography>
            </Box>
          )}
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scroll force tabs"
            value={tabValue}
            onChange={handleChangeTab}
          >
            {organizations.map(({ name }, index) => {
              return (
                <Tab
                  label={`${name}`}
                  {...a11yProps(index)}
                  key={index}
                  sx={{
                    ...(index === tabValue && {
                      backgroundColor: ' rgba(0, 0, 0, 0.05)'
                    })
                  }}
                />
              )
            })}
          </Tabs>

          {organizations.map((organization, index) => {
            return (
              <TabPanel
                value={tabValue}
                index={index}
                dir={theme.direction}
                key={index}
                sx={{
                  ...(matches && {
                    m: theme.spacing(0, 0, 24)
                  })
                }}
              >
                <Collapse
                  in={matches || collapse}
                  // collapsedSize={theme.spacing(14 * 3)}
                  collapsedSize={matches ? 0 : theme.spacing(14 * 3 + 1)}
                >
                  {debts.length < 1 ? (
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                        py: 2
                      }}
                    >
                      <Typography>
                        Não possui débitos ativos para esta organização
                      </Typography>
                    </Box>
                  ) : activity.rule === 'tab.change' ? (
                    <Box
                      sx={{
                        height: '40vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 2,
                        py: 2
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <List sx={{ width: '100%' }}>
                      {debts.map((debt, index) => {
                        const labelId = `checkbox-list-label-${debt.id}`
                        return (
                          <DebtListItem
                            activity={activity}
                            key={index}
                            debt={debt}
                            // checked={checked.indexOf(debt.id) !== -1}
                            checked={!!debt.selected}
                            onToggleCheck={handleToggle({ debtId: debt.id })}
                            labelId={labelId}
                            onClickDetail={handleOpenDetail}
                          />
                        )
                      })}
                    </List>
                  )}
                </Collapse>
              </TabPanel>
            )
          })}
          {/* </SwipeableViews> */}
          {debts.length > 3 && (
            <Box
              sx={{
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                m: theme.spacing(4, 0, 2),
                display: { xs: 'none', md: 'flex' }
              }}
            >
              <Divider
                absolute
                sx={{
                  left: '1%',
                  right: '99%',
                  width: '98%',
                  top: '50%',
                  bottom: '50%'
                }}
              />
              <Fab
                color="primary"
                size="small"
                onClick={handleCollapseToggle}
                sx={{
                  display: {
                    xs: 'none',
                    md: debts.length > 3 ? 'inline-flex' : 'none'
                  },
                  position: 'absolute'
                }}
              >
                {collapse ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Fab>
            </Box>
          )}
          <DebtsListTotal
            activity={activity}
            subTotal={total.subTotal}
            taxes={total.additional}
            total={total.total}
            onClick={handleCreateCharge}
            visible={!!debts.length}
            disabled={debts.filter(item => item.selected).length === 0}
          />
        </Paper>
      </Box>
      <SwipeableDrawer
        anchor="bottom"
        // open={false}
        open={detailOpen}
        onClose={handleCloseDetail}
        onOpen={() => console.log('open')}
        disableSwipeToOpen
        PaperProps={{
          sx: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            left: { xs: 0, xl: '25%' },
            right: { xs: 0, xl: '25%' },
            px: { xs: 1, md: 2 },
            pb: 2
          }
        }}
        // disableSwipeToOpen={false}
      >
        <Box
          sx={{
            // position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            p: 1
          }}
        >
          <Puller />
        </Box>
        <Divider />
        <Container
          sx={{
            p: 3
          }}
        >
          <Typography sx={{ textAlign: 'center', mb: 2 }} variant="h5">
            Detalhes
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            {debtDetail?.description}
          </Typography>
          {!!debtDetail && (
            <Box
              sx={{
                py: 2,
                display: 'flex',
                gap: 0.5,
                justifyContent: 'center'
              }}
            >
              {debtDetail.overdueDays > 0 && (
                <Chip
                  size="small"
                  variant="outlined"
                  label="VENCIDO"
                  color="error"
                />
              )}
              {debtDetail.negativation && (
                <Chip
                  size="small"
                  variant="outlined"
                  label="NEGATIVADO"
                  color="error"
                />
              )}
              {debtDetail.situation !== 'ACTIVATION' && (
                <Chip
                  size="small"
                  variant="outlined"
                  label={DebtSituation[
                    debtDetail.situation as keyof typeof DebtSituation
                  ].toUpperCase()}
                  color="warning"
                />
              )}
            </Box>
          )}

          <PaperTextItem
            primary={`Valor até o vencimento ${formatDate(
              debtDetail?.dueDate
            )}`}
            secondary={formatCurrency(debtDetail?.amount)}
          />
          <Divider sx={{ my: 2 }} />
          <Grid container rowSpacing={2}>
            <Grid item xs={4}>
              <PaperTextItem
                primary="Multa"
                secondary={formatCurrency(debtDetail?.fine)}
              />
            </Grid>
            <Grid item xs={3}>
              <PaperTextItem
                primary="Juros"
                secondary={formatCurrency(debtDetail?.interest)}
                sx={{ flexGrow: 'none' }}
              />
            </Grid>
            <Grid item xs={5} justifyContent="center" alignItems="center">
              <PaperTextItem
                primary="Perda de Convênio"
                secondary={formatCurrency(debtDetail?.additionalValues)}
                sx={{
                  flexGrow: 'none',
                  justifyContent: 'center'
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <PaperTextItem
                primary="Dias vencidos"
                secondary={
                  Number(debtDetail?.overdueDays) > 0
                    ? debtDetail?.overdueDays
                    : 0 || 0
                }
                sx={{ flexGrow: 'none' }}
              />
            </Grid>
            <Grid item xs={8}>
              <PaperTextItem
                primary="Valor até a data de hoje:"
                secondary={formatCurrency(debtDetail?.totalAmount)}
                sx={{ flexGrow: 'none' }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          {debtDetail?.situation === 'ACTIVATION' && debtDetail.negativation && (
            <Typography
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
              color="error"
            >
              Parcelas negativadas devem ser pagas juntas.
            </Typography>
          )}
          {debtDetail?.situation === 'ACTIVATION' && !debtDetail.negativation && (
            <Button
              name="detailPaymentButton"
              variant="contained"
              rounded
              fullWidth
              // size="large"
              onClick={handleCreateCharge}
            >
              Pagar esta parcela
            </Button>
          )}
        </Container>
      </SwipeableDrawer>
      {chargesActive.length > 0 && (
        <ActiveBilletAlert
          charges={chargesActive}
          open={isOpenBilletActiveAlert && !isOpenBilletPendingAlert}
          onAlreadyHasPaid={handleAlreadyHasPaidBilletActive}
          onCancel={getDebtsByCustomerAndOrganization}
        />
      )}
      {chargesPending.length > 0 && (
        <ActiveBilletAlert
          charges={chargesPending}
          open={isOpenBilletPendingAlert}
          onAlreadyHasPaid={handleAlreadyHasPaidBilletPending}
          onCancel={getDebtsByCustomerAndOrganization}
        />
      )}
    </Layout>
  )
}

export default Board
