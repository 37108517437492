import api from '../api'

interface CancelChargeServiceProps {
  idCharge: number
  idCustomer: number
}

class CancelChargeService {
  async execute({ idCharge, idCustomer }: CancelChargeServiceProps) {
    const url = `/v1/appCharges/customer/${idCustomer}/charge/${idCharge}/cancellation`

    const data = await api
      .post(url)
      .then(({ data }) => ({ ...data, status: 'success' }))
      .catch(err => {
        throw err
      })
    return data
  }
}

export { CancelChargeService }
