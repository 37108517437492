import { format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

export const formatCurrency = (value: number | undefined) => {
  if (typeof value !== 'number') {
    return 'NaN'
  }
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value)
}
export const formatDate = (value: string | undefined) => {
  if (!value) {
    return 'Invalid date'
  }
  return format(parseISO(value), 'dd/MM/yyyy', {
    locale: ptBR
  })
}
export const formatTimestampToDay = (value: number | undefined) => {
  if (!value) {
    return 'Invalid date'
  }
  return value / 1000 / 3600 / 24
}
