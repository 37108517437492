import { Box, Typography } from '@mui/material'
import { HelpBox } from '../../components/HelpBox'
import Layout from '../../components/Layout'
import RequireAuth from '../../components/RequireAuth'
import SignInLayout from '../../components/SignInLayout'
import { useAppSelector } from '../../redux/hooks'

import HomeIcon from '@mui/icons-material/Home'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

const Help = () => {
  const { isAuthenticated } = useAppSelector(state => state.auth)

  if (!isAuthenticated) {
    return (
      <SignInLayout>
        <Box
          sx={{
            flexGrow: 1,
            p: 2
          }}
        >
          <HelpBox
            size={{
              sm: 12
            }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Link to={ROUTES.HOME.pathname}>
            <Typography
              textAlign="center"
              sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
            >
              <HomeIcon />
              Voltar para tela inicial
            </Typography>
          </Link>
        </Box>
      </SignInLayout>
    )
  }
  return (
    <RequireAuth>
      <Layout title="Ajuda">
        <Box
          sx={{
            flexGrow: 1,
            p: 2
          }}
        >
          <HelpBox />
        </Box>
      </Layout>
    </RequireAuth>
  )
}

export default Help
