import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionSummary,
  Box,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  styled,
  SwipeableDrawer,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  AccordionDetails
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ReceiptIcon from '@mui/icons-material/Receipt'
import pixLogo from '../../assets/images/pix-106.svg'

import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'
import PaperTextItem from '../../components/PaperTextItem'
import { formatCurrency, formatDate } from '../../utils/format'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { GetChargesByCustomerService } from '../../services/charge/GetChargesByCustomerService'
import { Charge } from '../../shared/interfaces/charge'
import { grey } from '@mui/material/colors'

import CopyToClipboard from 'react-copy-to-clipboard'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { CancelChargeService } from '../../services/charge/CancelChargeService'
import { AxiosError } from 'axios'
import { setSnackbar } from '../../redux/slice/snackbarSlice'
import { activityEnd, activityStart } from '../../redux/slice/appSlice'
import { delay } from '../../utils/delay'
import { fileDownloader } from '../../utils/FileDownloader'
import { format, parseISO } from 'date-fns'

// import { Container } from './styles';

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 3,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  // position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)'
}))

const PAYMENT_TYPE = {
  BOLETO: 'Boleto',
  CREDIT_CARD: 'Cartão de Crédito',
  PIX: 'PIX',
  BOLETO_PIX: 'Boleto PIX'
}

const PAYMENT_STATUS = {
  PAID: 'Pago',
  CANCELLED: 'Cancelado',
  OPEN: 'Pendente',
  CREATED: 'Aguardando Pagamento',
  ACTIVE: 'Aguardando Pagamento',
  PAID_AT_CLIENT: 'Pago na instituição',
  PENDING: 'Pendente'
}

const PaymentHistory = () => {
  const theme = useTheme()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { customer } = useAppSelector(state => state.auth)
  const { user } = useAppSelector(state => state.user)
  const { activity } = useAppSelector(state => state.app)

  const [charges, setCharges] = React.useState<Charge[]>([])
  const [chargeDetail, setChargeDetail] = React.useState<Charge>()
  const [detailOpen, setDetailOpen] = React.useState(false)

  const [downloadLoading, setDownloadLoading] = useState(false)

  React.useEffect(() => {
    if (user) {
      handleGetChargesByCustomer(Number(customer?.id))
    }
  }, [])

  const handleGetChargesByCustomer = async (idCustomer: number) => {
    const service = new GetChargesByCustomerService()
    try {
      const response = await service.execute({ idCustomer })
      if (!response) {
        setCharges([])
      }
      if (response) {
        setCharges(
          response.sort((a, b) => {
            const dateA: number = new Date(a.dueDate).getTime()
            const dateB: number = new Date(b.dueDate).getTime()

            return dateB - dateA
          })
        )
      }
    } catch (err) {
      console.log({ err })
    }
  }

  const handleCancelCharge = async (idCharge: number) => {
    const service = new CancelChargeService()
    try {
      dispatch(activityStart({ loading: true, rule: 'charge.cancel' }))
      const response = await service.execute({
        idCharge,
        idCustomer: Number(customer?.id)
      })
      if (response) {
        delay(4).then(() => {
          setDetailOpen(false)
          handleGetChargesByCustomer(Number(customer?.id))
          dispatch(activityEnd())
          dispatch(
            setSnackbar({
              isOpen: true,
              message: 'Boleto cancelado com sucesso',
              severity: 'success'
            })
          )
        })
      }
    } catch (err) {
      console.log({ err })
      const error = err as AxiosError
      if (error.isAxiosError) {
        const { response } = error
        // const { message } = response?.data
        dispatch(activityEnd())
        dispatch(
          setSnackbar({
            isOpen: true,
            message:
              'Houve um erro durante o cancelamento, por favor tente novamente em alguns minutos!',
            severity: 'error'
          })
        )
      }
    }
  }

  const handleGoToCheckout = (hash: string) => {
    navigate(`/checkout/${hash}`)
  }

  const handleOpenDetail = (charge: Charge) => {
    setChargeDetail(charge)
    setDetailOpen(true)
  }
  const handleCloseDetail = () => {
    // setChargeDetail(null)
    setDetailOpen(false)
  }

  const handleDownloadBillet = async () => {
    setDownloadLoading(true)
    await fileDownloader(
      String(chargeDetail?.billetLink),
      `BOLETO_${format(
        parseISO(String(chargeDetail?.dueDate)),
        'yyyy_MM_dd'
      )}.pdf`
    )
    setDownloadLoading(false)
  }

  return (
    <Layout title="Histórico de Pagamentos">
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: theme.breakpoints.values.md
          },
          p: 2
        }}
      >
        <PageTitle
          title="Histórico de pagamentos"
          subtitle="Aqui você encontrará seu histórico de pagamento."
        />
        <Paper sx={{ p: { xs: 0, md: 2 } }} elevation={matches ? 0 : 1}>
          {charges.map((charge, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <PaperTextItem
                  primary={`Vencimento: ${formatDate(charge.dueDate)}`}
                  secondary={`${formatCurrency(
                    charge.amount || charge.amountPaid
                  )} - ${charge.organization?.name}`}
                  tail={
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        color:
                          theme.palette[
                            charge.status === 'PAID' ||
                            charge.status === 'PAID_AT_CLIENT'
                              ? 'success'
                              : 'error'
                          ].main
                      }}
                    >
                      {charge.status === 'PAID' ||
                      charge.status === 'PAID_AT_CLIENT'
                        ? 'Pago'
                        : 'Pendente'}
                    </Typography>
                  }
                />
              </AccordionSummary>
              <AccordionDetails>
                <Box key={index} sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontWeight: 'bold' }}>Descrição</Typography>
                  {charge.debts.map((debt, index) => (
                    <PaperTextItem
                      key={index}
                      primary={debt.description}
                      secondary={` - Vencimento: ${formatDate(debt.dueDate)}`}
                    />
                  ))}
                  <Divider />
                  <PaperTextItem
                    sx={{ mt: 2 }}
                    primary={
                      <>
                        {charge.type &&
                          charge.status !== 'CREATED' &&
                          `${
                            PAYMENT_TYPE[
                              charge.type as keyof typeof PAYMENT_TYPE
                            ]
                          } - `}
                        <Typography
                          component="span"
                          sx={{
                            ...(charge.status !== 'PAID_AT_CLIENT' && {
                              color:
                                theme.palette[
                                  charge.status === 'PAID' ? 'success' : 'info'
                                ].main
                            })
                          }}
                        >
                          {`${
                            PAYMENT_STATUS[
                              charge.status as keyof typeof PAYMENT_STATUS
                            ]
                          }`}
                        </Typography>
                      </>
                    }
                    {...(charge.status === 'PAID' && {
                      secondary: (
                        <>
                          <Typography variant="body1" component="span">
                            {`${formatCurrency(charge.amountPaid)} - ${
                              charge.paymentDate &&
                              formatDate(charge.paymentDate)
                            }`}
                          </Typography>
                        </>
                      )
                    })}
                    tail={
                      charge.status === 'PAID' ? (
                        <IconButton>
                          <Link
                            to={`/voucher/${customer?.id}/${charge.id}/${charge.organization.id}`}
                            target="_blank"
                          >
                            <ReceiptIcon />
                          </Link>
                        </IconButton>
                      ) : charge.status === 'ACTIVE' ? (
                        <IconButton onClick={() => handleOpenDetail(charge)}>
                          <VisibilityIcon />
                        </IconButton>
                      ) : (
                        charge.status === 'CREATED' && (
                          <IconButton
                            onClick={() => handleGoToCheckout(charge.shareHash)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )
                      )
                    }
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
        <SwipeableDrawer
          anchor="bottom"
          open={detailOpen}
          onClose={handleCloseDetail}
          onOpen={() => console.log('open')}
          swipeAreaWidth={56}
          PaperProps={{
            sx: {
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              left: { xs: 0, xl: '25%' },
              right: { xs: 0, xl: '25%' },
              px: { xs: 1, md: 2 }
            }
          }}
          // disableSwipeToOpen={false}
        >
          <Box
            sx={{
              // position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              p: 1
            }}
          >
            <Puller />
          </Box>
          <Divider />
          <Container sx={{ p: 3 }}>
            <Typography sx={{ textAlign: 'center', mb: 2 }} variant="h5">
              Detalhes
            </Typography>
            <Box
              sx={{
                display: 'flex',
                mb: 2,
                justifyContent: 'space-around'
              }}
            >
              <PaperTextItem
                primary="Vencimento"
                secondary={formatDate(chargeDetail?.dueDate)}
                sx={{ flexGrow: 'none' }}
              />
              <PaperTextItem
                primary="Valor"
                secondary={formatCurrency(chargeDetail?.amount)}
                sx={{ flexGrow: 'none' }}
              />
              <PaperTextItem
                primary="Emissão"
                secondary={formatDate(chargeDetail?.createdAt)}
                sx={{ flexGrow: 'none' }}
              />
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  gap: 2
                }}
              >
                <img
                  src={pixLogo}
                  alt="PIX Logo"
                  height="150px"
                  width="150px"
                />
                <img
                  src={`data:image/jpeg;base64,${chargeDetail?.chargePix?.image}`}
                  alt="PIX QR Code"
                  height="125px"
                  width="125px"
                />
                {/* </Box> */}
              </Box>
            </Box>
            <Stack spacing={2}>
              <TextField
                fullWidth
                label="Pix Copia e Cola"
                value={chargeDetail?.chargePix?.payload}
                size="small"
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard
                      text={String(chargeDetail?.chargePix?.payload)}
                      onCopy={() =>
                        dispatch(
                          setSnackbar({
                            isOpen: true,
                            message: 'Copiado',
                            severity: 'success'
                          })
                        )
                      }
                    >
                      <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            color: theme.palette.info.main
                          }}
                        >
                          Copiar
                        </Typography>
                      </InputAdornment>
                    </CopyToClipboard>
                  )
                }}
              />
              <TextField
                fullWidth
                label="Boleto - Código de barras"
                value={chargeDetail?.billetPayNumber}
                size="small"
                InputProps={{
                  endAdornment: (
                    <CopyToClipboard
                      text={String(chargeDetail?.billetPayNumber)}
                      onCopy={() =>
                        dispatch(
                          setSnackbar({
                            isOpen: true,
                            message: 'Copiado',
                            severity: 'success'
                          })
                        )
                      }
                    >
                      <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            color: theme.palette.info.main
                          }}
                          color="info"
                        >
                          Copiar
                        </Typography>
                      </InputAdornment>
                    </CopyToClipboard>
                  )
                }}
              />
              <Divider />
              <Button
                variant="contained"
                rounded
                onClick={handleDownloadBillet}
                loading={downloadLoading}
                // sx={{ display: { xs: 'none', md: 'inline-flex' } }}
              >
                Gerar PDF do Boleto
              </Button>
              <Button
                variant="contained"
                rounded
                color="error"
                loading={activity.rule === 'charge.cancel' && activity.loading}
                onClick={() => handleCancelCharge(Number(chargeDetail?.id))}
              >
                Cancelar Boleto
              </Button>
            </Stack>
          </Container>
        </SwipeableDrawer>
      </Box>
    </Layout>
  )
}

export default PaymentHistory
