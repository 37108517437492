import React from 'react'
import {
  Box,
  ListItemText,
  Typography,
  ListItemTextProps,
  ListItemSecondaryAction
} from '@mui/material'

// import { Container } from './styles';
interface PaperTextItemProps {
  primary?: string | React.ReactNode
  secondary?: string | React.ReactNode
  tail?: string | React.ReactNode
}
const PaperTextItem: React.FC<ListItemTextProps & PaperTextItemProps> = ({
  primary,
  secondary,
  tail,
  sx
}) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...sx
      }}
    >
      <ListItemText
        primary={primary}
        secondary={secondary}
        sx={{ flexGrow: 1 }}
      />
      {/* <ListItemSecondaryAction> */}
      {typeof tail === 'string' ? <Typography>{tail}</Typography> : tail}
      {/* </ListItemSecondaryAction> */}
    </Box>
  )
}

export default PaperTextItem
