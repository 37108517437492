import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Debt } from '../../shared/interfaces/debt'

export interface DebtState {
  status: 'idle' | 'loading' | 'failed'
  error?: string
  debts: Debt[]
}

const initialState: DebtState = {
  status: 'idle',
  debts: []
}

export const debtSlice = createSlice({
  name: 'debt',
  initialState,
  reducers: {
    getDebtsByCustomerAndOrganizationStart: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ idCustomer: number; idOrganization: number }>
    ) => ({ ...state, status: 'loading' }),
    getDebtsByCustomerAndOrganizationSuccess: (
      state,
      action: PayloadAction<Debt[]>
    ) => ({ ...state, status: 'idle', debts: action.payload }),
    getDebtsByCustomerAndOrganizationFailure: (
      state,
      action: PayloadAction<string>
    ) => ({ ...state, status: 'failed', error: action.payload, debts: [] }),
    getDebtsRecalculatedStart: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        idPersonDebt: number
        idCustomer: number
        idOrganization: number
        debts: { debtId: number }[]
      }>
    ) => ({ ...state, status: 'loading' }),
    getDebtsRecalculatedSuccess: (state, action: PayloadAction<Debt[]>) => ({
      ...state,
      status: 'idle',
      debts: action.payload
    }),
    getDebtsRecalculatedFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      status: 'failed',
      error: action.payload
    })
  }
})

export const {
  getDebtsByCustomerAndOrganizationStart,
  getDebtsByCustomerAndOrganizationSuccess,
  getDebtsByCustomerAndOrganizationFailure,
  getDebtsRecalculatedStart,
  getDebtsRecalculatedSuccess,
  getDebtsRecalculatedFailure
} = debtSlice.actions

export default debtSlice.reducer
