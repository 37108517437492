import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'
import { AuthenticateUserService } from '../../services/auth/AuthenticateUserService'
import { clearToken, login, storeCustomer } from '../../services/auth/common'
import { SignOutUserService } from '../../services/auth/SignOutUserService'
import { SingleSignOnService } from '../../services/auth/SingleSignOnService'
import { ValidateUserAuthService } from '../../services/auth/ValidateUserAuthService'
import { Authentication } from '../../shared/interfaces/authentication'
import { Customer } from '../../shared/interfaces/customer'
import { activityEnd, setUtmSource } from '../slice/appSlice'

import {
  signInFailure,
  signInStart,
  signInSuccess,
  signOutFailure,
  signOutStart,
  signOutSuccess,
  singleSignOnFailure,
  singleSignOnStart,
  validateAuthFailure,
  validateAuthStart,
  validateAuthSuccess
} from '../slice/authSlice'
import { setSnackbar } from '../slice/snackbarSlice'

export function* handleUserSignIn(
  action: PayloadAction<{
    username: string
    password: string
    customer?: Customer
  }>
): Generator<unknown, void, Authentication> {
  const service = new AuthenticateUserService()
  try {
    const response = yield call(service.execute, {
      username: action.payload.username,
      password: action.payload.password,
      ...(!!action.payload.customer && {
        utmSource: action.payload.customer?.utmSource
      })
    })
    // login(response.accessToken, response.refreshToken)

    if (action.payload.customer) {
      const customerIsValid = response.customers.find(
        item => item.id === action.payload.customer?.id
      )
      if (!customerIsValid) {
        yield put(
          setSnackbar({
            isOpen: true,
            message: 'Usuário ou senha incorretos',
            severity: 'error'
          })
        )
        yield put(activityEnd())
        return
      }
      storeCustomer(customerIsValid)
      const payload = {
        data: response,
        customer: customerIsValid
      }
      yield put(signInSuccess(payload))
      yield put(activityEnd())
    }

    if (!action.payload.customer) {
      if (response.customers.length === 1) {
        storeCustomer(response.customers[0])
      }

      const payload = {
        data: response,
        ...(response.customers.length === 1 && {
          customer: response.customers[0]
        })
      }
      yield put(signInSuccess(payload))
      yield put(activityEnd())
    }
    login(response.accessToken, response.refreshToken)
    // FIXME: END
  } catch (err) {
    yield put(signInFailure(String(err)))
    console.log({ err })
    yield put(activityEnd())
    const error = err as AxiosError
    if (error.isAxiosError) {
      const { response } = error
      const { message } = response?.data
      if (
        message === 'User not found' ||
        message === 'Invalid email or password'
      ) {
        yield put(
          setSnackbar({
            isOpen: true,
            message: 'Usuário ou senha incorretos',
            severity: 'error'
          })
        )
      } else if (
        message ===
        'Access to this account has been temporarily disabled due to many failed login attempts'
      ) {
        yield put(
          setSnackbar({
            isOpen: true,
            message:
              'Acesso bloqueado temporariamente devida a muitas tentativas de acesso. Aguarde 5 minutos e tente novamente, ou recupere sua senha.',
            severity: 'error'
          })
        )
      } else {
        yield put(
          setSnackbar({
            isOpen: true,
            message:
              'Houve um erro na operação, por favor tente novamente mais tarde',
            severity: 'error'
          })
        )
      }
    }
  }
}

export function* handleUserSingleSignOn(
  action: PayloadAction<{
    utmSource: string
    identificationHash: string
    authorization: string
  }>
): Generator<unknown, void, Authentication> {
  const service = new SingleSignOnService()
  const { utmSource, identificationHash, authorization } = action.payload
  try {
    const response = yield call(service.execute, {
      utmSource,
      identificationHash,
      authorization
    })
    storeCustomer(response.customers[0])
    const payload = {
      data: response,
      ...(response.customers.length === 1 && {
        customer: response.customers[0]
      })
    }
    yield put(signInSuccess(payload))
    login(response.accessToken, response.refreshToken)
  } catch (err) {
    console.log({ err })
    const error = err as AxiosError
    if (error.isAxiosError) {
      const { message } = error.response?.data
      if (message === 'User not found') {
        yield put(
          setSnackbar({
            isOpen: true,
            message: 'Usuário não encontrado',
            severity: 'error'
          })
        )
      }
    }
    yield put(singleSignOnFailure(String(err)))
    // throw err
  }
}

export function* handleUserSignOut(): Generator<unknown, void, any> {
  yield put(
    setSnackbar({
      isOpen: false,
      message: ''
    })
  )
  try {
    const service = new SignOutUserService()
    const signOut = yield call(service.execute)
    if (signOut) {
      yield put(activityEnd())
      yield put(signOutSuccess())
      clearToken()
    }
  } catch (err) {
    clearToken()
    yield put(activityEnd())
    yield put(signOutFailure(String(err)))
  }
}

export function* handleValidateAuth(): Generator<
  unknown,
  string | void,
  Authentication
> {
  try {
    const service = new ValidateUserAuthService()
    const response = yield call(service.execute)
    const payload = {
      data: response,
      ...(response.customers.length === 1 && {
        customer: response.customers[0]
      })
    }
    // yield put(setUtmSource(response.customers[0].utmSource))
    yield put(validateAuthSuccess(payload))
    yield put(activityEnd())
  } catch (err) {
    clearToken()
    yield put(validateAuthFailure(String(err)))
  }
}

export function* watchSignIn() {
  yield takeEvery(signInStart.type, handleUserSignIn)
  yield takeEvery(singleSignOnStart.type, handleUserSingleSignOn)
  yield takeEvery(signOutStart.type, handleUserSignOut)
  yield takeEvery(validateAuthStart.type, handleValidateAuth)
}
