import { Organization } from '../../shared/interfaces/organization'
import api from '../api'

interface GetOrganizationByCustomerServiceProps {
  idCustomer: number
}
class GetOrganizationByCustomerService {
  async execute({
    idCustomer
  }: GetOrganizationByCustomerServiceProps): Promise<Organization[]> {
    const url = `/v1/appOrganizations/customer/${idCustomer}`

    const data = await api
      .get(url)
      .then(({ data }) => {
        if (data) {
          return data
        }
        return []
      })
      .catch(err => {
        throw err
      })
    return data
  }
}
export { GetOrganizationByCustomerService }
