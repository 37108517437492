/* eslint-disable prettier/prettier */

import Billet from '../pages/Billet'
import Board from '../pages/Board'
import Checkout from '../pages/Checkout'
import CheckoutOutOff from '../pages/CheckoutOutOff'
import CustomerSelection from '../pages/CustomerSelection'
import Help from '../pages/Help'
import NextDebts from '../pages/NextDebts'
import PaymentHistory from '../pages/PaymentHistory'
import Recover from '../pages/Recover'
import Redirect from '../pages/Redirect'
import SignIn from '../pages/SignIn'
import SingleSignOn from '../pages/SingleSignOn'
import Voucher from '../pages/Voucher'

// export const ROUTES = {
//   HOME: '/',
//   SIGN_IN: '/auth',
//   SINGLE_SIGN_ON: '/auth/direct',
//   SIGN_IN_BY_CUSTOMER: '/:utm',
//   CUSTOMER: '/customer',
//   BOARD: '/board',
//   NEXT_DEBTS: '/next-debts',
//   PAYMENT_HISTORY: '/payment-history',
//   VOUCHER: '/voucher/:idCustomer/:idCharge/:idOrganization',
//   CHECKOUT: '/checkout/:hash',
//   CHECKOUT_OUT_OFF: '/checkout/payment/redirect/:hash',
//   RECOVER: '/recover',
//   REGISTER: '/register',
//   HELP: '/help',
//   REDIRECT: '/redirect',
//   BILLET: '/billet'
// }

// export const ROUTES_PROPS = [
//   {
//     // OK
//     name: 'HOME',
//     pathname: '/',
//     private: false,
//     whiteLabel: false,
//     component: SignIn
//   },
//   {
//     // OK
//     name: 'SIGN_IN',
//     pathname: '/auth',
//     private: false,
//     whiteLabel: false,
//     component: SignIn
//   },
//   {
//     // OK
//     name: 'SINGLE_SIGN_ON',
//     pathname: '/auth/direct',
//     private: false,
//     whiteLabel: false,
//     component: SingleSignOn
//   },
//   {
//     // OK
//     name: 'SIGN_IN_BY_CUSTOMER',
//     pathname: '/:utm',
//     private: false,
//     whiteLabel: false,
//     component: SignIn
//   },

//   {
//     // OK
//     name: 'CUSTOMER',
//     pathname: '/customer',
//     private: true,
//     whiteLabel: false,
//     component: CustomerSelection
//   },
//   {
//     // OK
//     name: 'BOARD',
//     pathname: '/board',
//     private: true,
//     whiteLabel: false,
//     component: Board
//   },
//   {
//     // OK
//     name: 'NEXT_DEBTS',
//     pathname: '/next-debts',
//     private: true,
//     whiteLabel: false,
//     component: NextDebts
//   },
//   {
//     // OK
//     name: 'PAYMENT_HISTORY',
//     pathname: '/payment-history',
//     private: true,
//     whiteLabel: false,
//     component: PaymentHistory
//   },
//   {
//     // OK
//     name: 'VOUCHER',
//     pathname: '/voucher/:idCustomer/:idCharge/:idOrganization',
//     private: false,
//     whiteLabel: false,
//     component: Voucher
//   },
//   {
//     // OK
//     name: 'CHECKOUT',
//     pathname: '/checkout/:hash',
//     private: true,
//     whiteLabel: false,
//     component: Checkout
//   },
//   {
//     // OK
//     name: 'CHECKOUT_OUT_OFF',
//     pathname: '/checkout/payment/redirect/:hash',
//     private: false,
//     whiteLabel: false,
//     component: CheckoutOutOff
//   },
//   {
//     // OK
//     name: 'BILLET',
//     pathname: '/billet/:hash',
//     private: false,
//     whiteLabel: false,
//     component: Billet
//   },
//   {
//     // OK
//     name: 'RECOVER',
//     pathname: '/recover',
//     private: false,
//     whiteLabel: false,
//     component: Recover
//   },
//   {
//     // OK
//     name: 'REGISTER',
//     pathname: '/register',
//     private: false,
//     whiteLabel: false,
//     component: Recover
//   },
//   {
//     name: 'HELP',
//     pathname: '/help',
//     private: false,
//     whiteLabel: false,
//     component: Help
//   },
//   {
//     // OK
//     name: 'REDIRECT',
//     pathname: '/redirect/:destination',
//     private: false,
//     whiteLabel: false,
//     component: Redirect
//   }
// ]
export const ROUTES = {
  HOME: {
    // OK
    name: 'HOME',
    pathname: '/',
    private: false,
    whiteLabel: false,
    component: SignIn
  },
  SIGN_IN: {
    // OK
    name: 'SIGN_IN',
    pathname: '/auth',
    private: false,
    whiteLabel: false,
    component: SignIn
  },
  SINGLE_SIGN_ON: {
    // OK
    name: 'SINGLE_SIGN_ON',
    pathname: '/auth/direct',
    private: false,
    whiteLabel: false,
    component: SingleSignOn
  },
  SIGN_IN_BY_CUSTOMER: {
    // OK
    name: 'SIGN_IN_BY_CUSTOMER',
    pathname: '/:utm',
    private: false,
    whiteLabel: true,
    component: SignIn
  },

  CUSTOMER: {
    // OK
    name: 'CUSTOMER',
    pathname: '/customer',
    private: true,
    whiteLabel: false,
    component: CustomerSelection
  },
  BOARD: {
    // OK
    name: 'BOARD',
    pathname: '/board',
    private: true,
    whiteLabel: true,
    component: Board
  },
  NEXT_DEBTS: {
    // OK
    name: 'NEXT_DEBTS',
    pathname: '/next-debts',
    private: true,
    whiteLabel: true,
    component: NextDebts
  },
  PAYMENT_HISTORY: {
    // OK
    name: 'PAYMENT_HISTORY',
    pathname: '/payment-history',
    private: true,
    whiteLabel: true,
    component: PaymentHistory
  },
  VOUCHER: {
    // OK
    name: 'VOUCHER',
    pathname: '/voucher/:idCustomer/:idCharge/:idOrganization',
    private: false,
    whiteLabel: true,
    component: Voucher
  },
  CHECKOUT: {
    // OK
    name: 'CHECKOUT',
    pathname: '/checkout/:hash',
    private: true,
    whiteLabel: true,
    component: Checkout
  },
  CHECKOUT_OUT_OFF: {
    // OK
    name: 'CHECKOUT_OUT_OFF',
    pathname: '/checkout/payment/redirect/:hash',
    private: false,
    whiteLabel: true,
    component: CheckoutOutOff
  },
  BILLET: {
    // OK
    name: 'BILLET',
    pathname: '/billet/:hash',
    private: false,
    whiteLabel: true,
    component: Billet
  },
  RECOVER: {
    // OK
    name: 'RECOVER',
    pathname: '/recover',
    private: false,
    whiteLabel: true,
    component: Recover
  },
  REGISTER: {
    // OK
    name: 'REGISTER',
    pathname: '/register',
    private: false,
    whiteLabel: true,
    component: Recover
  },
  HELP: {
    name: 'HELP',
    pathname: '/help',
    private: false,
    whiteLabel: true,
    component: Help
  },
  REDIRECT: {
    // OK
    name: 'REDIRECT',
    pathname: '/redirect/:destination',
    private: false,
    whiteLabel: false,
    component: Redirect
  }
}
export const ROUTES_PROPS = Object.values(ROUTES)
