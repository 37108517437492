import { Box } from '@mui/material'
import { useRouteError } from 'react-router-dom'

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)

  return (
    <Box
      id="error-page"
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h1>Oops!</h1>
      {/* <p>Sorry, an unexpected error has occurred.</p> */}
      <p>Desculpe, ocorreu um erro inesperado ao carregar esta página.</p>
      {/* <p>
        <i>{error.statusText || error.message}</i>
      </p> */}
    </Box>
  )
}
