import { useEffect, useState } from 'react'
import { Box, useTheme, Toolbar, CssBaseline, AppBar } from '@mui/material'

import PageTitle from '../../components/PageTitle'
import { useParams } from 'react-router-dom'

import JunoCheckout from '../../utils/cardHash'

import { ImageBox, Image } from '../../components/Image'
import { CheckoutScreen } from '../../components/CheckoutScreen'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import LoadingBox from '../../components/LoadingBox'
import {
  customerSettingsInitialState,
  setCustomerSettings,
  setUtmSource
} from '../../redux/slice/appSlice'
import { GetChargeByHashWithNoAuthService } from '../../services/charge/GetChargeByHashWithNoAuthService'
import { CheckoutData } from '../../shared/interfaces/checkoutData'

// import { Container } from './styles';

const CheckoutOutOff = () => {
  const dispatch = useAppDispatch()
  const { customerSettings } = useAppSelector(state => state.app)
  const theme = useTheme()
  const { hash } = useParams()
  const [checkoutData, setCheckoutData] = useState<CheckoutData>()
  const [isExpired, setIsExpired] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.title = `4IES | Redirect Checkout`
  }, [])

  useEffect(() => {
    if (hash) {
      handleGetChargeByHash(hash)
    }
  }, [hash])

  const handleGetChargeByHash = async (shareHash: string) => {
    try {
      const service = new GetChargeByHashWithNoAuthService()
      const response = await service.execute({ shareHash })

      if (!response) {
        dispatch(
          setCustomerSettings({
            ...customerSettingsInitialState,
            ready: true
          })
        )
        setIsExpired(true)
      }
      if (response) {
        dispatch(setUtmSource(response.customer.utmSource))
        dispatch(
          setCustomerSettings({
            ...response.customer.customerSetting,
            ready: true
          })
        )
        setCheckoutData(response)
      }
      setLoading(false)
    } catch (err) {
      console.log({ err })
      dispatch(
        setCustomerSettings({
          ...customerSettingsInitialState,
          ready: true
        })
      )
      setIsExpired(true)
      setLoading(false)
    }
  }

  return (
    <Box
      sx={{
        // width: '100vw',
        // height: '100vh',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        pb: '4rem'
      }}
    >
      <CssBaseline />
      {!loading && !!customerSettings.ready && (
        <AppBar
          position="fixed"
          sx={{
            color: { md: theme.palette.text.primary },
            boxShadow: { md: 'none' }
          }}
        >
          <Toolbar sx={{ height: '100%', paddingLeft: 0 }}>
            <Box
              sx={{
                flexGrow: 1
              }}
            >
              <ImageBox>
                <Image
                  src={theme.logo.secondary}
                  alt=""
                  sx={{
                    objectFit: 'contain',
                    width: theme.spacing(7),
                    height: theme.spacing(7)
                  }}
                />
              </ImageBox>
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Box sx={{ width: { md: theme.spacing(74) }, mt: 16 }}>
        <JunoCheckout />
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: {
              xs: '100%',
              sm: theme.breakpoints.values.sm
            },
            p: 1
          }}
        >
          {!!customerSettings.ready && <PageTitle title="Pagamento" />}
          <CheckoutScreen shareHash={String(hash)} withAuth={false} />
        </Box>
      </Box>
    </Box>
  )
}

export default CheckoutOutOff
