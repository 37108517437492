import { Voucher } from '../../shared/interfaces/voucher'
import api from '../api'

interface GetChargeVoucherServiceProps {
  idCharge: number
  idCustomer: number
  idOrganization: number
}

class GetChargeVoucherService {
  async execute({
    idCharge,
    idCustomer,
    idOrganization
  }: GetChargeVoucherServiceProps): Promise<Voucher> {
    const url = `/v2/appCharges/voucher/charge/${idCharge}/customer/${idCustomer}/organization/${idOrganization}`

    const data = await api
      .get(url)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { GetChargeVoucherService }
