import React from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Link, useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import SignInLayout from '../../components/SignInLayout'
import TabPanel from '../../components/TabPanel'

import HomeIcon from '@mui/icons-material/Home'
import HelpIcon from '@mui/icons-material/Help'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import { ROUTES } from '../../constants/routes'
import { cpfMask } from '../../utils/mask'
import { RegisterByDocumentIdService } from '../../services/account/RegisterByDocumentIdService'
import { RecoverPasswordByDocumentIdService } from '../../services/account/RecoverPasswordByDocumentIdService'
import { AxiosError } from 'axios'
import { RequestPinService } from '../../services/account/RequestPinService'
import { ValidatePinService } from '../../services/account/ValidatePinService'
import { RegisterNewPasswordService } from '../../services/account/RegisterNewPasswordService'
import { StyledTextField } from '../../components/StyledTextField'
import cpfValidator from '../../utils/validator'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setSnackbar } from '../../redux/slice/snackbarSlice'

const BottomButtonsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignSelf: 'center',
  padding: theme.spacing(1, 0),
  textDecoration: 'underline'
}))

type RecoverContactType = {
  id: number
  type: string
  contact: string
}
type RecoverDataType = {
  id: number
  typeId: string
  contacts: RecoverContactType[]
}

const PANELS = {
  VERIFY_DOCUMENT: 'VERIFY_DOCUMENT',
  SELECT_CONTACT: 'SELECT_CONTACT',
  PIN_CONFIRMATION: 'PIN_CONFIRMATION',
  NEW_PASSWORD: 'NEW_PASSWORD',
  HELP: 'HELP'
}

const Recover = () => {
  const dispatch = useAppDispatch()
  const { customer } = useAppSelector(state => state.auth)

  const theme = useTheme()
  const location = useLocation()
  // const loaderData = useLoaderData()
  const navigate = useNavigate()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)
  const [value, setValue] = React.useState(PANELS.VERIFY_DOCUMENT)
  const [helperText, setHelperText] = React.useState('')
  const [recoverData, setRecoverData] = React.useState<RecoverDataType>()
  const [cpf, setCPF] = React.useState('')
  const [accessMethod, setAccessMethod] = React.useState<number | null>(null)
  const [pin, setPin] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  React.useEffect(() => {
    const contact = recoverData?.contacts.find(item => item.id === accessMethod)
  }, [accessMethod])

  const next = (panel: string) => {
    setValue(panel)
  }

  const recover = async () => {
    const service = new RecoverPasswordByDocumentIdService()
    setHelperText('')
    const response = await service.execute({
      documentId: cpf.replace(/\D/g, '')
    })
    return response
  }
  const register = async () => {
    const service = new RegisterByDocumentIdService()
    // setHelperText('')
    const response = await service.execute({
      documentId: cpf.replace(/\D/g, '')
    })
    return response
  }

  const handleVerifyCpf = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!cpfValidator(cpf.replace(/\D/g, ''))) {
      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'CPF informado é inválido',
          severity: 'error'
        })
      )
    }

    if (location.pathname === ROUTES.REGISTER.pathname) {
      try {
        const response = await register()
        setRecoverData(response)
        next(PANELS.SELECT_CONTACT)
      } catch (err) {
        const error = err as AxiosError
        if (error.isAxiosError) {
          console.log({ error })
          const { response } = error
          const { message } = response?.data
          if (message === 'User already exists') {
            // setHelperText('Este usuário já existe')
            dispatch(
              setSnackbar({
                isOpen: true,
                message: 'Este CPF já está cadastrado',
                severity: 'warning'
              })
            )
          }
          if (message === 'DocumentId not found') {
            // setHelperText('Este usuário já existe')
            dispatch(
              setSnackbar({
                isOpen: true,
                message:
                  'CPF não encontrado em nossa base! Clique aqui para ajuda.',
                link: ROUTES.HELP.pathname,
                severity: 'warning'
              })
            )
          }
        }
      }
    }
    if (location.pathname === ROUTES.RECOVER.pathname) {
      try {
        const response = await recover()
        setRecoverData(response)
        next(PANELS.SELECT_CONTACT)
      } catch (err) {
        const error = err as AxiosError
        if (error.isAxiosError) {
          const { response } = error
          const { message } = response?.data
          if (message === 'User not found') {
            // setHelperText('Este usuário não possui cadastro')
            dispatch(
              setSnackbar({
                isOpen: true,
                message: 'Este CPF não está cadastrado',
                severity: 'warning',
                link: '/help',
                duration: 30000
              })
            )
          }
        }
      }
    }
  }

  const handleRequestPin = async (event: React.FormEvent) => {
    event.preventDefault()
    const service = new RequestPinService()
    setHelperText('')
    const contact = recoverData?.contacts.find(item => item.id === accessMethod)
    try {
      const response = await service.execute({
        id: Number(recoverData?.id),
        contactId: Number(contact?.id),
        documentId: cpf.replace(/\D/g, ''),
        typeId: String(recoverData?.typeId)
      })
      next(PANELS.PIN_CONFIRMATION)
    } catch (err) {
      const error = err as AxiosError
      if (error.isAxiosError) {
        const { response } = error
        const { message } = response?.data
        if (message === 'User already exists') {
          setHelperText('Este usuário já existe')
        }
      }
    }
  }

  const handleValidatePin = async (event: React.FormEvent) => {
    event.preventDefault()
    setHelperText('')

    const service = new ValidatePinService()
    try {
      const response = await service.execute({
        id: Number(recoverData?.id),
        typeId: String(recoverData?.typeId),
        documentId: cpf.replace(/\D/g, ''),
        pin: pin
      })
      if (response) {
        next(PANELS.NEW_PASSWORD)
      }
    } catch (err) {
      const error = err as AxiosError
      console.log(error.response)
      if (error.isAxiosError) {
        const { response } = error
        const { message } = response?.data
        if (message === 'Pin not found') {
          // setHelperText('Código inválido')
          dispatch(
            setSnackbar({
              isOpen: true,
              message: 'Código inválido',
              severity: 'error'
            })
          )
        }
      }
    }
  }

  const handleSaveNewPassword = async (event: React.FormEvent) => {
    event.preventDefault()
    setHelperText('')
    if (password !== confirmPassword) {
      // setHelperText('As duas senhas devem ser iguais')
      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'As duas senhas devem ser iguais',
          severity: 'error'
        })
      )
      return
    }
    if (password.length < 8) {
      setHelperText('A senha deve conter 8 ou mais caracteres')
      dispatch(
        setSnackbar({
          isOpen: true,
          message: 'A senha deve conter 8 ou mais caracteres',
          severity: 'error'
        })
      )
      return
    }
    const service = new RegisterNewPasswordService()
    try {
      const response = await service.execute({
        id: Number(recoverData?.id),
        typeId: String(recoverData?.typeId),
        documentId: cpf.replace(/\D/g, ''),
        pin: pin,
        pass: password
      })
      if (location.pathname === ROUTES.REGISTER.pathname) {
        dispatch(
          setSnackbar({
            isOpen: true,
            message: 'Senha cadastrada com sucesso',
            severity: 'success'
          })
        )
      }
      if (location.pathname === ROUTES.RECOVER.pathname) {
        dispatch(
          setSnackbar({
            isOpen: true,
            message: 'Senha alterada com sucesso',
            severity: 'success'
          })
        )
      }
      navigate(
        customer?.utmSource ? `/${customer?.utmSource}` : ROUTES.HOME.pathname
      )
    } catch (err) {
      const error = err as AxiosError
      console.log(error.response)
      // setHelperText(
      //   'Houve um erro na operação, tente novamente em alguns minutos'
      // )
      dispatch(
        setSnackbar({
          isOpen: true,
          message:
            'Houve um erro na operação, tente novamente em alguns minutos',
          severity: 'error'
        })
      )
    }
  }

  return (
    <SignInLayout
      title={
        location.pathname === ROUTES.RECOVER.pathname
          ? 'Recuperar senha'
          : location.pathname === ROUTES.REGISTER.pathname
          ? 'Cadastro'
          : undefined
      }
    >
      <TabPanel
        index={PANELS.VERIFY_DOCUMENT}
        value={value}
        dir={theme.direction}
      >
        <Box component="form" onSubmit={handleVerifyCpf}>
          <Stack
            spacing={4}
            sx={{
              margin: theme.spacing(2, 0)
            }}
          >
            {location.pathname === ROUTES.REGISTER.pathname && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignSelf: 'center',
                  padding: theme.spacing(1, 0),
                  alignItems: 'center'
                }}
              >
                <Typography sx={{ mr: 1, fontSize: theme.spacing(1.5) }}>
                  Antes de começar, veja como realizar seu cadastro:
                </Typography>
                <a
                  href="https://www.youtube.com/watch?v=mkDhfSSanms&ab_channel=4IES"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    sx={{
                      fontSize: theme.spacing(1.5),
                      fontWeight: 'bold',
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center'
                    }}
                  >
                    <LiveTvIcon sx={{ fontSize: theme.spacing(2) }} />
                    Assista agora
                  </Typography>
                </a>
              </Box>
            )}
            <Typography sx={{ fontWeight: 'bold' }} alignSelf="center">
              Informe seu CPF para começar
            </Typography>
            <StyledTextField
              mobile={matches}
              value={cpf}
              label="CPF"
              onChange={e => setCPF(cpfMask(e.target.value))}
              inputProps={{
                inputMode: 'numeric'
              }}
              error={!!helperText}
              helperText={helperText}
            />
            <Button
              variant="contained"
              type="submit"
              color={matches ? 'secondary' : 'primary'}
              size="large"
            >
              Enviar
            </Button>
            <BottomButtonsBox>
              <Link
                to={
                  customer?.utmSource
                    ? `/${customer?.utmSource}`
                    : ROUTES.HOME.pathname
                }
              >
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <HomeIcon />
                  Voltar para tela inicial
                </Typography>
              </Link>
              <Link to={ROUTES.HELP.pathname}>
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <HelpIcon />
                  Ajuda
                </Typography>
              </Link>
            </BottomButtonsBox>
          </Stack>
        </Box>
      </TabPanel>
      <TabPanel index={PANELS.SELECT_CONTACT} value={value}>
        <Box component="form" onSubmit={handleRequestPin}>
          <Stack
            spacing={4}
            sx={{
              margin: theme.spacing(2, 0)
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} alignSelf="center">
              Confirmar acesso por
            </Typography>
            <Paper
              sx={{ p: 1, ...(matches && { backgroundColor: 'transparent' }) }}
              elevation={0}
            >
              <FormControl sx={{ flexGrow: 1, width: '100%' }}>
                <RadioGroup
                  sx={{ ml: 1, fontSize: '32px' }}
                  onChange={e => setAccessMethod(Number(e.target.value))}
                >
                  {recoverData?.contacts.map((contact, index) => (
                    <FormControlLabel
                      key={index}
                      label={contact.contact}
                      value={contact.id}
                      control={
                        <Radio
                          sx={{
                            ...(matches && {
                              color: '#fff',
                              '&.Mui-checked': {
                                color: '#fff'
                              }
                            })
                          }}
                        />
                      }
                      sx={{
                        ...(matches && {
                          fontSize: '45px',
                          fontWeight: 'bold',
                          color: '#fff'
                        })
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Typography
                color="error"
                textAlign="center"
                sx={{ mt: 2, display: helperText ? 'block' : 'none' }}
              >
                {helperText}
              </Typography>
            </Paper>
            <Button
              variant="contained"
              type="submit"
              color={matches ? 'secondary' : 'primary'}
              size="large"
              disabled={accessMethod === null}
            >
              Receber código de acesso
            </Button>
            <BottomButtonsBox>
              <Link
                to={
                  customer?.utmSource
                    ? `/${customer?.utmSource}`
                    : ROUTES.HOME.pathname
                }
              >
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <HomeIcon />
                  Voltar para tela inicial
                </Typography>
              </Link>
              <Link to={ROUTES.HELP.pathname}>
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <NotInterestedIcon />
                  E-mail ou celular não são meus
                </Typography>
              </Link>
            </BottomButtonsBox>
          </Stack>
        </Box>
      </TabPanel>
      <TabPanel index={PANELS.PIN_CONFIRMATION} value={value}>
        <Box component="form" onSubmit={handleValidatePin}>
          <Stack
            spacing={4}
            sx={{
              margin: theme.spacing(2, 0)
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} alignSelf="center">
              Informe o código recebido
            </Typography>
            <StyledTextField
              name="pin"
              mobile={matches}
              label="Código de confirmação"
              inputProps={{
                inputMode: 'numeric'
              }}
              value={pin}
              onChange={e =>
                setPin(
                  e.target.value.replace(/\D/g, '').replace(/(\d{6})(\d)/, '$1')
                )
              }
              error={!!helperText}
              helperText={
                helperText ||
                'Lembre-se de verificar a Caixa de SPAM e a aba de PROMOÇÕES'
              }
              FormHelperTextProps={{
                sx: { color: '#fff', alignSelf: 'center' }
              }}
            />
            <Button
              variant="contained"
              type="submit"
              color={matches ? 'secondary' : 'primary'}
              size="large"
              disabled={!pin}
            >
              Enviar
            </Button>
            <BottomButtonsBox>
              <Link
                to={
                  customer?.utmSource
                    ? `/${customer?.utmSource}`
                    : ROUTES.HOME.pathname
                }
              >
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <HomeIcon />
                  Voltar para tela inicial
                </Typography>
              </Link>
              <Box
                onClick={() => setValue(PANELS.SELECT_CONTACT)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <ArrowBackIcon />
                  Receber código por outro meio
                </Typography>
              </Box>
              <Link to={ROUTES.HELP.pathname}>
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <SentimentVeryDissatisfiedIcon />
                  Não recebi o código
                </Typography>
              </Link>
            </BottomButtonsBox>
          </Stack>
        </Box>
      </TabPanel>
      <TabPanel index={PANELS.NEW_PASSWORD} value={value}>
        <Box component="form" onSubmit={handleSaveNewPassword}>
          <Stack
            spacing={4}
            sx={{
              margin: theme.spacing(2, 0)
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} alignSelf="center">
              Insira a nova senha
            </Typography>
            <StyledTextField
              name="password"
              mobile={matches}
              label="Senha"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              error={!!helperText}
              FormHelperTextProps={{
                sx: { color: '#fff', alignSelf: 'center' }
              }}
            />
            <StyledTextField
              name="confirmPassword"
              mobile={matches}
              label="Confirmação da senha"
              type="password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              error={!!helperText}
              helperText={helperText}
              FormHelperTextProps={{
                sx: { color: '#fff', alignSelf: 'center' }
              }}
            />
            <Button
              variant="contained"
              type="submit"
              color={matches ? 'secondary' : 'primary'}
              size="large"
            >
              Enviar
            </Button>
            <BottomButtonsBox>
              <Link
                to={
                  customer?.utmSource
                    ? `/${customer?.utmSource}`
                    : ROUTES.HOME.pathname
                }
              >
                <Typography
                  textAlign="center"
                  sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
                >
                  <HomeIcon />
                  Voltar para tela inicial
                </Typography>
              </Link>
            </BottomButtonsBox>
          </Stack>
        </Box>
      </TabPanel>
      {/* <TabPanel index={PANELS.HELP} value={value}>
        <HelpBox />
      </TabPanel> */}
    </SignInLayout>
  )
}

export default Recover
