import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Authentication } from '../../shared/interfaces/authentication'
import { Customer } from '../../shared/interfaces/customer'

export interface AuthState {
  status: 'idle' | 'loading' | 'failed'
  error?: string
  data: Authentication | null
  customer: Customer | null
  isAuthenticated: boolean
}

const initialState: AuthState = {
  status: 'idle',
  data: null,
  customer: null,
  isAuthenticated: false
}

// TODO: SignOut Clear State function

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInStart: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        username: string
        password: string
        customer?: Customer
      }>
    ) => ({
      ...state,
      status: 'loading'
    }),
    signInSuccess: (
      state,
      action: PayloadAction<{ data: Authentication; customer?: Customer }>
    ) => ({
      ...state,
      status: 'idle',
      isAuthenticated: true,
      ...action.payload
    }),
    signInFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      status: 'failed',
      isAuthenticated: false,
      error: action.payload
    }),
    singleSignOnStart: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        utmSource: string
        identificationHash: string
        authorization: string
      }>
    ) => ({
      ...state,
      status: 'loading'
    }),
    singleSignOnSuccess: (
      state,
      action: PayloadAction<{ data: Authentication; customer?: Customer }>
    ) => ({
      ...state,
      status: 'idle',
      isAuthenticated: true,
      ...action.payload
    }),
    singleSignOnFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      status: 'failed',
      isAuthenticated: false,
      error: action.payload
    }),
    signOutStart: state => ({
      ...state,
      status: 'loading'
    }),
    signOutSuccess: state => ({
      ...state,
      status: 'idle',
      data: null,
      customer: null,
      isAuthenticated: false
    }),
    signOutFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      status: 'failed',
      data: null,
      customer: null,
      isAuthenticated: false,
      error: action.payload
    }),
    selectCustomer: (state, action: PayloadAction<Customer | null>) => ({
      ...state,
      customer: action.payload
    }),
    validateAuthStart: state => ({
      ...state,
      status: 'loading'
    }),
    validateAuthSuccess: (
      state,
      action: PayloadAction<{ data: Authentication; customer?: Customer }>
    ) => ({
      ...state,
      status: 'idle',
      isAuthenticated: true,
      ...action.payload
    }),
    validateAuthFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      status: 'failed',
      isAuthenticated: false,
      error: action.payload
    })
    // selectCustomer: (
    //   { user, ...restState },
    //   action: PayloadAction<Customer>
    // ) => ({
    //   ...restState,
    //   user: { ...user }
    // })
  }
})

export const {
  signInStart,
  signInSuccess,
  signInFailure,
  singleSignOnStart,
  singleSignOnSuccess,
  singleSignOnFailure,
  signOutStart,
  signOutSuccess,
  signOutFailure,
  selectCustomer,
  validateAuthStart,
  validateAuthSuccess,
  validateAuthFailure
} = authSlice.actions

export default authSlice.reducer
