import { Box, CircularProgress, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  customerSettingsInitialState,
  setCustomerSettings
} from '../../redux/slice/appSlice'
import { CustomerSettings } from '../../shared/interfaces/customerSettings'
import { CoBrandingMark } from '../CoBrandingMark'
import { EnvironmentMark } from '../EnvironmentMark'
import { SnackbarMessage } from '../SnackbarMessage'

interface LoaderProps {
  name: string
  pathname: string
  private: boolean
  whiteLabel: boolean
  customerSettings: CustomerSettings
}

export const WhiteLabelWrapper: React.FC = ({ children }) => {
  const theme = useTheme()
  const loaderData = useLoaderData() as LoaderProps
  const dispatch = useAppDispatch()
  const { customerSettings } = useAppSelector(state => state.app)

  useEffect(() => {
    loadCustomerSettings()
  }, [loaderData])
  const loadCustomerSettings = async () => {
    if (!loaderData.whiteLabel) {
      dispatch(
        setCustomerSettings({
          ...customerSettingsInitialState,
          ready: false,
          principiaMigration: loaderData.customerSettings.principiaMigration
        })
      )
      return
    }
    if (loaderData.customerSettings.whiteLabel === 'ACTIVE') {
      dispatch(
        setCustomerSettings({ ...loaderData.customerSettings, ready: true })
      )
      return
    }

    dispatch(
      setCustomerSettings({
        ...customerSettings,
        ready: true,
        principiaMigration: loaderData.customerSettings.principiaMigration
      })
    )
  }

  if (loaderData.whiteLabel && !customerSettings.ready) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ height: '100%' }}>
      <SnackbarMessage />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
          // pb: theme.spacing(6),
          // pb: theme.spacing(4)
        }}
      >
        <EnvironmentMark />
        {children}
      </Box>
      {loaderData.customerSettings.whiteLabel === 'ACTIVE' && (
        <CoBrandingMark />
      )}
    </Box>
  )
}
