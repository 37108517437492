import api from '../api'

interface PaymentChargeServiceProps {
  chargeType: string
  shareHash: string
  totalAmount: number
  installments?: number
  creditCard?: {
    hash?: string
  }
}
class PaymentChargeWithNoAuthService {
  async execute(props: PaymentChargeServiceProps) {
    const url = `/v2/charges/checkout/pay`

    const data = await api
      .post(url, props)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { PaymentChargeWithNoAuthService }
