import { signOutStart } from '../../redux/slice/authSlice'
import { store } from '../../redux/store'
import { Customer } from '../../shared/interfaces/customer'

export const storeToken = (token: string) => {
  localStorage.setItem('token', token)
}

export const storeRefreshToken = (refreshToken: string) => {
  localStorage.setItem('rtoken', refreshToken)
}

export const storeCustomer = (customer: Customer) => {
  localStorage.setItem('customer', JSON.stringify(customer))
  localStorage.setItem('backpoint', customer.utmSource)
}

export const clearToken = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('rtoken')
  localStorage.removeItem('customer')
  sessionStorage.clear()
}

export const login = (token: string, refreshToken: string) => {
  storeToken(token)
  storeRefreshToken(refreshToken)
}

export const logout = async () => {
  store.dispatch(signOutStart())
}
