import { Box, CircularProgress, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'

const Redirect = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { destination } = useParams()

  // if (!destination) {

  useEffect(() => {
    document.title = `4IES | WhatsApp Redirect`
  }, [])
  useEffect(() => {
    if (destination === 'whatsapp') {
      if (window) {
        window.location.href = 'https://wa.me/5511915523600'
      }
    }
  }, [destination])

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  )
  // }
  // console.log(destination)
  // return <Navigate to="www.google.com.br" state={{ from: location }} />
}

export default Redirect
