import { Box, Link, Paper, Typography, useTheme } from '@mui/material'

interface HelpTypeProps {
  title: string
  image: {
    source: string
    alt: string
  }
  contact: {
    label: string
    value: string
  }
}

export const HelpTypes = ({ title, image, contact }: HelpTypeProps) => {
  const theme = useTheme()
  return (
    <>
      <Link
        href={contact.value}
        target="_blank"
        sx={{
          textDecoration: 'none'
        }}
      >
        <Paper
          sx={{
            p: 1,
            height: { xs: theme.spacing(12), sm: theme.spacing(16) },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          <Typography>{contact.label}</Typography>
          <Box
            sx={{
              position: 'absolute',
              width: theme.spacing(10),
              opacity: 0.2
            }}
          >
            <img src={image.source} alt={image.alt} />
          </Box>
        </Paper>
      </Link>
    </>
  )
}
