import { Charge } from '../../shared/interfaces/charge'
import api from '../api'

interface GetChargesByCustomerServiceProps {
  idCustomer: number
}
class GetChargesByCustomerService {
  async execute({
    idCustomer
  }: GetChargesByCustomerServiceProps): Promise<Charge[]> {
    const url = `/v1/appCharges/customer/${idCustomer}`

    const data = await api
      .get(url)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { GetChargesByCustomerService }
