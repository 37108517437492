import jwtDecode from 'jwt-decode'
import { Customer } from '../../shared/interfaces/customer'
import { logout, storeToken } from './common'

interface JwtToken {
  aud?: string
  // eslint-disable-next-line camelcase
  auth_time?: string
  iat?: string
  exp?: string
}

export const getToken = () => {
  return localStorage.getItem('token') || ''
}

export const getRefreshToken = () => {
  return localStorage.getItem('rtoken') || ''
}

export const getCustomer = (): Customer | undefined => {
  const customer = localStorage.getItem('customer') || undefined
  if (customer) {
    return JSON.parse(customer)
  }
  return undefined
}
export const getBackPoint = (): string | undefined => {
  const backPoint = localStorage.getItem('backpoint') || undefined
  if (backPoint) {
    return backPoint
  }
  return undefined
}

export const getJwtPayload = (token = getToken()) => {
  try {
    return jwtDecode<JwtToken>(token)
  } catch (_) {
    return {}
  }
}

export const getTokenWithRenew = async () => {
  const refreshToken = getRefreshToken()

  if (refreshToken) {
    const requestOption = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        refreshToken
      }
    }

    const newToken = await fetch(
      `${process.env.REACT_APP_API_URL}/v1/auth/refreshToken`,
      requestOption
    )
      .then(response =>
        response.json().then(data => {
          storeToken(data.accessToken)
          return data.accessToken
        })
      )
      .catch(() => {
        console.log('catch wrap')
        // logout()
        return null
      })
    return newToken
  }
}
