import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SnackbarState {
  isOpen: boolean
  duration?: number
  anchor?: {
    vertical: 'top' | 'bottom'
    horizontal: 'left' | 'center' | 'right'
  }
  link?: string
  message: string
  severity?: 'success' | 'error' | 'warning' | 'info'
}

const initialState: SnackbarState = {
  isOpen: false,
  duration: 6000,
  anchor: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  link: undefined,
  message: '',
  severity: 'info'
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, { payload }: PayloadAction<SnackbarState>) => ({
      ...state,
      ...payload
    })
  }
})

export const { setSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
