import api from '../api'

class SignOutUserService {
  async execute() {
    const url = `/v1/auth/signOut`
    const { data } = await api
      .post(url)
      .then(response => {
        return { data: response }
      })
      .catch(err => {
        throw err
      })
    return data
  }
}

export { SignOutUserService }
