/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable no-void */
import axios from 'axios'
import JSEncrypt from 'jsencrypt'
const __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
          resolve(value)
        })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator.throw(value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done
          ? resolve(result.value)
          : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
const INVALID_NUMBER = {
  code: 'INVALID_NUMBER',
  message: 'invalid card `number`'
}
const INVALID_NUMBER_LENGTH = {
  code: 'INVALID_NUMBER',
  message:
    'invalid field `number`. You must pass a value between 13 and 19 digits'
}
const INVALID_SECURITY_CODE = {
  code: 'INVALID_SECURITY_CODE',
  message:
    'invalid field `securityCode`. You must pass a value with 3, 4 or none digits'
}
const INVALID_EXPIRATION_MONTH = {
  code: 'INVALID_EXPIRATION_MONTH',
  message: 'invalid field `expMonth`. You must pass a value between 1 and 12'
}
const INVALID_EXPIRATION_YEAR = {
  code: 'INVALID_EXPIRATION_YEAR',
  message:
    'invalid field `expYear`. You must pass a value between 1900 and 2099'
}
const INVALID_PUBLIC_KEY = {
  code: 'INVALID_PUBLIC_KEY',
  message: 'invalid `publicKey`'
}
const INVALID_HOLDER = {
  code: 'INVALID_HOLDER',
  message: 'invalid `holder`'
}
const validationErrors = {
  INVALID_NUMBER,
  INVALID_NUMBER_LENGTH,
  INVALID_SECURITY_CODE,
  INVALID_EXPIRATION_MONTH,
  INVALID_EXPIRATION_YEAR,
  INVALID_PUBLIC_KEY,
  INVALID_HOLDER
}
export class DirectCheckout extends JSEncrypt {
  constructor() {
    super(...arguments)
    this.getFormattedPublicKey = key => {
      let publicKey = '-----BEGIN PUBLIC KEY-----\n'
      publicKey += this.wordWrap(this.b64_to_utf8(key)) + '\n'
      publicKey += '-----END PUBLIC KEY-----\n'
      return publicKey
    }
    this.getFormattedPrivateKey = key => {
      let publicKey = '-----BEGIN RSA PRIVATE KEY-----\n'
      publicKey += this.wordWrap(this.b64_to_utf8(key)) + '\n'
      publicKey += '-----END RSA PRIVATE KEY-----\n'
      return publicKey
    }
    this.encryptDataCard = dataCard =>
      __awaiter(this, void 0, void 0, function* () {
        const now = yield this.getTime(dataCard.environment)
        const card = [
          dataCard.cardNumber,
          dataCard.holderName,
          dataCard.securityCode,
          dataCard.expMonth,
          dataCard.expYear,
          now
        ]
        const publicKey = this.b64_to_utf8(dataCard.publicKey)
        this.setPublicKey(publicKey)
        const hash = this.encrypt(JSON.stringify(card))
        return hash
      })
    this.wordWrap = function (str) {
      const width = 64
      if (!str) return str
      const regex = '(.{1,' + width + '})( +|$\n?)|(.{1,' + width + '})'
      const result = str.match(RegExp(regex, 'g'))
      if (!result) return str
      return result.join('\n')
    }
  }

  validateAndEncryptCard(dataCard) {
    return __awaiter(this, void 0, void 0, function* () {
      const sanitizedCard = this.sanitize(dataCard)
      const result = this.validate(sanitizedCard)
      if (result.errors.length === 0) {
        const encryptedData = yield this.encryptDataCard(sanitizedCard)
        if (encryptedData) {
          result.hasErrors = false
          result.encryptedCard = encryptedData
        } else {
          result.errors.push(validationErrors.INVALID_PUBLIC_KEY)
        }
      }
      return result
    })
  }

  validate(sanitizedCard) {
    const result = this.validateCard(sanitizedCard)
    const hasErrors = result.length > 0
    return {
      errors: this.validateCard(sanitizedCard),
      encryptedCard: null,
      hasErrors
    }
  }

  getTime(environment) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        let urlCore = ''
        switch (environment) {
          case 'development':
            urlCore = 'https://api-dev.prpay.com.br/v1/time/get'
            break
          case 'stage':
            urlCore = 'https://api-stage.prpay.com.br/v1/time/get'
            break
          case 'production':
            urlCore = 'https://api.prpay.com.br/v1/time/get'
            break
          default:
            urlCore = 'https://api-dev.prpay.com.br/v1/time/get'
            break
        }
        const result = yield axios.get(urlCore)
        if (!(result === null || result === void 0 ? void 0 : result.data))
          return new Date().getTime()
        return result.data.timestamp
      } catch (error) {
        console.error('Error get time')
        return new Date().getTime()
      }
    })
  }

  sanitize(dataCard) {
    return {
      holderName: this.trunkString(dataCard.holderName, 30),
      cardNumber: this.formatToString(dataCard.cardNumber),
      securityCode: this.formatToString(dataCard.securityCode),
      expMonth: this.formatExpMonth(dataCard.expMonth),
      expYear: this.formatToString(dataCard.expYear),
      publicKey: this.formatToString(dataCard.publicKey),
      environment: this.formatToString(dataCard.environment)
    }
  }

  formatToString(field) {
    return ((field || '') + '').trim()
  }

  formatExpMonth(expMonth) {
    const month = this.formatToString(expMonth)
    return month.length === 1 ? `0${month}` : month
  }

  trunkString(field, maxLength) {
    return this.formatToString(field).substring(0, maxLength).toUpperCase()
  }

  utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)))
  }

  b64_to_utf8(str) {
    return decodeURIComponent(window.atob(str))
  }

  validateCard(dataCard) {
    function cardNumberEmptyValidation({ cardNumber }) {
      if (!cardNumber) {
        return validationErrors.INVALID_NUMBER
      }
    }
    function cardNumberOnlyNumberValidation({ cardNumber }) {
      if (cardNumber) {
        if (!isNumber(cardNumber)) {
          return validationErrors.INVALID_NUMBER
        }
      }
    }
    function cardNumberLengthValidator({ cardNumber }) {
      if (cardNumber) {
        if (!(cardNumber.length >= 13 && cardNumber.length <= 19)) {
          return validationErrors.INVALID_NUMBER_LENGTH
        }
      }
    }
    function containsNumber(input) {
      return /\d/.test(input)
    }
    function isNumber(numberInput) {
      return !isNaN(numberInput)
    }
    function securityCodeValidator({ securityCode }) {
      if (securityCode) {
        if (
          !(
            isNumber(securityCode) &&
            (securityCode.length === 3 || securityCode.length === 4)
          )
        ) {
          return validationErrors.INVALID_SECURITY_CODE
        }
      }
    }
    function expMonthValidator({ expMonth }) {
      if (
        !expMonth ||
        !(
          isNumber(expMonth) &&
          parseInt(expMonth, 10) >= 1 &&
          parseInt(expMonth, 10) <= 12
        )
      ) {
        return validationErrors.INVALID_EXPIRATION_MONTH
      }
    }
    function expYearValidator({ expYear }) {
      if (
        !expYear ||
        !(
          isNumber(expYear) &&
          parseInt(expYear, 10) >= 1900 &&
          parseInt(expYear, 10) <= 2099
        )
      ) {
        return validationErrors.INVALID_EXPIRATION_YEAR
      }
    }
    function holderValidator({ holderName }) {
      if (!holderName || containsNumber(holderName)) {
        return validationErrors.INVALID_HOLDER
      }
    }
    return [
      cardNumberEmptyValidation,
      cardNumberOnlyNumberValidation,
      cardNumberLengthValidator,
      securityCodeValidator,
      expMonthValidator,
      expYearValidator,
      holderValidator
    ]
      .map(validator => validator(dataCard))
      .filter(error => error)
  }

  formatCardNumber(data) {
    if (!data) return
    return data.replace(/\D/g, '')
  }

  formatHolderName(data) {
    if (!data) return
    return data
      .replace(/[^A-zÀ-ú ]/g, '')
      .trim()
      .toUpperCase()
  }
}
