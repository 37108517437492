import axios from 'axios'
import { isExpiredToken } from './auth/assertive'
import { getToken, getTokenWithRenew } from './auth/get'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

export default api

api.interceptors.request.use(async config => {
  const token = getToken()
  if (!config.headers) config.headers = {}

  if (
    token &&
    !config.headers.authorization &&
    !config.url?.includes('/v1/auth/signIn')
  ) {
    config.headers.authorization = `Bearer ${token}`
    if (isExpiredToken(token)) {
      try {
        const refreshedToken = await getTokenWithRenew()
        config.headers.authorization = `Bearer ${refreshedToken}`
        return
      } catch (err) {
        console.log({ err })
        return
      }
    }
  }
  return config
})
