/* eslint-disable @typescript-eslint/no-explicit-any */
export const cpfMask = (value: any) => {
  return value
    .replace(/\D/g, '') // substitui qualquer character que não seja número por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de número, o primeiro de 3 e o segundo de 1, após capturar o primeiro grupo ele adiciona um ponto antes do segundo
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2') // captura 2 números seguidos de um traço
    .replace(/(-\d{2})\d+?$/, '$1') // e não deixa digitar nada além
}
export const cpfHiddenMask = (value: any) => {
  return value
    .replace(/\D/g, '') // substitui qualquer character que não seja número por nada
    .replace(/(\d{3})(\d)/, '$1.')
    .replace(/(\d{4})(\d)/, '***.***-')
    .replace(/(-\d{2})\d+?$/, '$1') // e não deixa digitar nada além
}
export const cnpjMask = (value: any) => {
  return value
    .replace(/\D/g, '') // substitui qualquer character que não seja número por nada
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1/$2') // captura 2 números seguidos de um traço
    .replace(/(\d{4})(\d{1,2})/, '$1-$2') // captura 2 números seguidos de um traço
    .replace(/(-\d{2})\d+?$/, '$1') // e não deixa digitar nada além
}
export const cellphoneMask = (value: any) => {
  return value
    .replace(/\D/g, '') // substitui qualquer character que não seja número por nada
    .replace(/(\d{2})(\d)/, '($1)$2') // captura 2 grupos de número, o primeiro de 3 e o segundo de 1, após capturar o primeiro grupo ele adiciona um ponto antes do segundo
    .replace(/(\d{1})(\d{2})/, ' $1 $2')
    .replace(/(\d{4})(\d{4})/, '$1-$2')
  // .replace(/(\d{3})(\d{1,2})/, '$1-$2') // captura 2 números seguidos de um traço
  // .replace(/(-\d{2})\d+?$/, '$1'); // e não deixa digitar nada além
}

export const creditCardMask = (value: any) => {
  return value
    .replace(/\D/g, '') // substitui qualquer character que não seja número por nada
    .replace(/(\d{4})(\d)/, '$1 $2') // captura 2 grupos de número, o primeiro de 3 e o segundo de 1, após capturar o primeiro grupo ele adiciona um ponto antes do segundo
    .replace(/(\d{4})(\d)/, '$1 $2') // captura 2 grupos de número, o primeiro de 3 e o segundo de 1, após capturar o primeiro grupo ele adiciona um ponto antes do segundo
    .replace(/(\d{4})(\d)/, '$1 $2') // captura 2 grupos de número, o primeiro de 3 e o segundo de 1, após capturar o primeiro grupo ele adiciona um ponto antes do segundo
    .replace(/( \d{4})\d+?$/, '$1') // e não deixa digitar nada além
}
export const billetMask = (value: any) => {
  return (
    value
      // .replace(/\D/g, '') // substitui qualquer character que não seja número por nada
      .replace(
        /(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/,
        '$1.$2 $3.$4 $5 $6 $7 $8'
      )
  )
}

export const monthYearMask = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{1,2})/, '$1 / $2')
    .replace(/(\d{4})\d+?$/, '$1')
}

// \d{5}\.\d{5} \d{5}\.\d{6} \d{5}\.\d{6} \d \d{14}
