import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { Button } from '../Button'

interface UserPreferencesDialogProps {
  activity: { loading: boolean; rule?: string }
  customer: string
  open: boolean
  onClose: () => void
  onSubmit: (props: {
    timeCommunication: string
    typeCommunication: string
  }) => void
}

const languageTypes = {
  A: 'FORMAL',
  B: 'INFORMAL',
  C: 'INFORMAL'
}

const UserPreferencesDialog = ({
  activity,
  customer,
  open,
  onClose,
  onSubmit
}: UserPreferencesDialogProps) => {
  const [periodValue, setPeriodValue] = useState('')
  const [languageTypeValue, setLanguageTypeValue] = useState('')

  const handleChangePeriod = (event: ChangeEvent<HTMLInputElement>) => {
    setPeriodValue(event.target.value)
  }
  const handleChangeLanguageType = (event: ChangeEvent<HTMLInputElement>) => {
    setLanguageTypeValue(event.target.value)
  }

  const handleSubmit = () => {
    onSubmit({
      timeCommunication: periodValue,
      typeCommunication:
        languageTypes[languageTypeValue as keyof typeof languageTypes]
    })
  }
  return (
    <Dialog open={open}>
      <DialogTitle>Preferências de comunicação</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Olá, ative sua preferência de comunicação.
        </DialogContentText>
        <DialogContentText>Qual melhor horário para falar?</DialogContentText>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="period"
            name="period"
            value={periodValue}
            onChange={handleChangePeriod}
          >
            <FormControlLabel
              value="MORNING"
              control={<Radio color="primary" />}
              label="08h às 12h"
            />
            <FormControlLabel
              value="AFTERNOON"
              control={<Radio sx={{ py: 0 }} color="primary" />}
              label="13h às 17h"
            />
            <FormControlLabel
              value="NIGHT"
              control={<Radio color="primary" />}
              label="18h às 20h"
            />
          </RadioGroup>
        </FormControl>
        <Divider sx={{ my: 1 }} />
        <DialogContentText>
          Qual tipo de linguagem você se identifica?
        </DialogContentText>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="period"
            name="period"
            value={languageTypeValue}
            onChange={handleChangeLanguageType}
          >
            <FormControlLabel
              value="A"
              control={<Radio color="primary" />}
              label="Formal"
            />
            <FormControlLabel
              value="B"
              control={<Radio sx={{ py: 0 }} color="primary" />}
              label="Informal"
            />
            <FormControlLabel
              value="C"
              control={<Radio color="primary" />}
              label="Coloquial"
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" size="small" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleSubmit}
          disabled={!languageTypeValue || !periodValue}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { UserPreferencesDialog }
