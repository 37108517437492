import React from 'react'

import {
  Box,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Link as RRDLink, useNavigate, useParams } from 'react-router-dom'

import { Button } from '../../components/Button'
import SignInLayout from '../../components/SignInLayout'
import { StyledTextField } from '../../components/StyledTextField'
import { ROUTES } from '../../constants/routes'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectCustomer, signInStart } from '../../redux/slice/authSlice'
import { activityStart } from '../../redux/slice/appSlice'
import api from '../../services/api'
import { NewsPopup } from '../../components/NewsPopup'

const Link = styled(RRDLink)(({ theme }) => ({
  color: theme.palette.grey[200],
  [theme.breakpoints.up('md')]: {
    color: theme.palette.grey[900]
  },
  fontWeight: 'bold'
}))

const SignIn = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)

  const { isAuthenticated } = useAppSelector(state => state.auth)
  const { activity } = useAppSelector(state => state.app)

  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  // const [validSource, setValidSource] = React.useState(false)

  const { utm } = useParams()

  const dispatch = useAppDispatch()
  const { customerSettings } = useAppSelector(state => state.app)
  const { customer } = useAppSelector(state => state.auth)

  React.useEffect(() => {
    // localStorage.clear()
    if (utm) {
      getCustomer(String(utm))
    } else {
      dispatch(selectCustomer(null))
    }
  }, [])

  const getCustomer = async (utmSource: string) => {
    await api
      .get(`/v1/app/customers/utmSource/${utmSource.toLowerCase()}`)
      .then(response => response)
      .then(({ data }) => {
        dispatch(selectCustomer(data))
        return data
      })
      .catch(err => {
        // setValidSource(false)
        navigate(ROUTES.SIGN_IN.pathname, { replace: true })
        // throw err
        return err
      })
    // console.log(data)
    // dispatch(selectCustomer(data))
    // return data
  }

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.CUSTOMER.pathname, {
        replace: true
      })
    }
  }, [isAuthenticated])

  const handleSignIn = (event: React.FormEvent) => {
    event.preventDefault()
    dispatch(activityStart({ loading: true }))
    if (!username || !password) return

    let formattedUsername = ''

    if (username.search('@') > -1) {
      formattedUsername = username
    } else {
      formattedUsername = username.replace(/[^a-z0-9]/gi, '')
    }
    dispatch(
      signInStart({
        username: formattedUsername.trim(),
        password,
        ...(customer && { customer: customer })
      })
    )
  }

  return (
    <SignInLayout title="Login">
      <Box component="form" onSubmit={handleSignIn}>
        <Stack
          spacing={2}
          sx={{
            margin: theme.spacing(2, 0)
          }}
        >
          <Box sx={{ alignSelf: 'center', padding: theme.spacing(1, 0) }}>
            <Typography component="span" sx={{ mr: 1 }}>
              Primeiro acesso?
            </Typography>
            <Link to="/register">Cadastre-se</Link>
          </Box>
          <StyledTextField
            label={!utm ? 'CPF' : 'CPF ou RA'}
            mobile={!!matches}
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <StyledTextField
            label="Senha"
            type="password"
            mobile={!!matches}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Box
            sx={{
              alignSelf: 'center',
              padding: theme.spacing(1, 0),
              color: { xs: '#fff', md: '#000' }
            }}
          >
            <Link to={ROUTES.RECOVER.pathname}>
              <Typography sx={{ color: { xs: '#fff', md: '#000' } }}>
                Esqueci minha senha
              </Typography>
            </Link>
          </Box>
          <Button
            variant="contained"
            type="submit"
            color={matches ? 'secondary' : 'primary'}
            loading={activity.loading}
            sx={{
              height: theme.spacing(7),
              fontSize: theme.spacing(2)
              // display: { md: 'none' }
            }}
          >
            Acessar
          </Button>
        </Stack>
      </Box>

      <NewsPopup
        principiaMigration={customerSettings.principiaMigration}
        customer={customer?.displayName ?? ''}
      />
    </SignInLayout>
  )
}

export default SignIn
