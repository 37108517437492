import { Box, Typography, Paper } from '@mui/material'
import celebrationImg from '../../assets/images/celebration.svg'

const PaymentSuccessBox = () => {
  return (
    <Box>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2
        }}
      >
        <Typography variant="h4">Yeeeeeeei!!!</Typography>
        <Box>
          <img src={celebrationImg} alt="" width="100%" height="100%" />
        </Box>
        <Typography variant="h6" align="center">
          Parabéns! Seu pagamento foi realizado com sucesso!!
        </Typography>
      </Paper>
    </Box>
  )
}

export { PaymentSuccessBox }
