import { Box, Divider, SxProps, Typography, useTheme } from '@mui/material'
import React from 'react'

// import { Container } from './styles';

interface PageTitleProps {
  title: React.ReactNode | string
  subtitle?: React.ReactNode | string
  divider?: boolean
  dark?: boolean
  sx?: SxProps
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  subtitle,
  divider = true,
  dark = false,
  sx
}) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        mb: 2,
        ...(dark && {
          color: '#fff'
        }),
        ...sx
      }}
      component="header"
    >
      {typeof title === 'string' ? (
        <Typography variant="h5" component="h5">
          {title}
        </Typography>
      ) : (
        title
      )}
      {divider && (
        <Divider
          sx={{
            m: theme.spacing(2, 0),
            ...(dark && {
              borderColor: 'rgba(255,255,255,0.50)'
            })
          }}
        />
      )}
      {typeof subtitle === 'string' ? (
        <Typography
          component="h6"
          variant="caption"
          sx={{ fontWeight: 'bold' }}
        >
          {subtitle}
        </Typography>
      ) : (
        subtitle
      )}
    </Box>
  )
}

export default PageTitle
