import { Box, CircularProgress, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { selectCustomer, validateAuthStart } from '../../redux/slice/authSlice'
import { getUserDataStart } from '../../redux/slice/userSlice'
import { getBackPoint, getCustomer, getToken } from '../../services/auth/get'

interface RequireAuthProps {
  children: JSX.Element
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const location = useLocation()
  const theme = useTheme()
  const storedCustomer = getCustomer()
  const { data, isAuthenticated, customer, status } = useAppSelector(
    state => state.auth
  )
  const { user } = useAppSelector(state => state.user)

  const dispatch = useAppDispatch()

  const [hasToken, setHasToken] = useState(!!getToken())
  const [authIsValid, setAuthIsValid] = useState(false)

  useEffect(() => {
    if (hasToken) {
      dispatch(validateAuthStart())
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && customer) {
      dispatch(getUserDataStart(customer.id))
    }
  }, [customer])

  useEffect(() => {
    if (isAuthenticated && storedCustomer) {
      const previousCustomer = data?.customers.find(
        item => item.id === Number(storedCustomer.id)
      )
      if (previousCustomer) {
        dispatch(selectCustomer(previousCustomer))
      }
    }
    setHasToken(!!getToken())
  }, [isAuthenticated])

  if (isAuthenticated && customer && !user) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    )
  }

  if ((!hasToken && !isAuthenticated) || status === 'failed') {
    const backPoint = getBackPoint()
    return (
      <Navigate
        to={backPoint ? `/${backPoint}` : ROUTES.HOME.pathname}
        state={{ from: location }}
        replace
      />
    )
  }
  return children
}

export default RequireAuth
