import api from '../api'

interface GetCustomerSettingsServiceProps {
  utmSource: string
}

class GetCustomerSettingsService {
  async execute({ utmSource }: GetCustomerSettingsServiceProps) {
    const url = `/v1/app/customers/utmSource/${utmSource}`
    const { data } = await api
      .get(url)
      .then(response => {
        return { data: response }
      })
      .catch(err => {
        return err
      })

    return data
  }
}

export { GetCustomerSettingsService }
