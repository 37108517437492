import axios from 'axios'
import api from '../services/api'

interface DownloadFile {
  contentType: string
  dataBase64: string
}

export const fileDownloader = async (
  url: string,
  filename: string
): Promise<string | undefined> => {
  return api
    .get('/v1/srcs/file', {
      params: {
        u: url
      }
    })
    .then(response => {
      const data = response.data as DownloadFile

      const linkSource = `data:${data.contentType};base64,${data.dataBase64}`

      const downloadLink = document.createElement('a')
      // downloadLink.target = '_blank'
      downloadLink.download = filename
      downloadLink.setAttribute('target', '_top')
      downloadLink.setAttribute('rel', 'external')

      const byteCharacters = atob(data.dataBase64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const file = new Blob([byteArray], { type: data.contentType + ';base64' })
      const fileURL = URL.createObjectURL(file)
      downloadLink.href = fileURL
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      downloadLink.remove()

      return downloadLink.href
    })
    .catch(err => {
      console.error(err)
      return undefined
    })
}
