import api from '../api'

interface RequestPinServiceProps {
  id: number
  typeId: string
  documentId: string
  contactId: number
}

class RequestPinService {
  async execute(props: RequestPinServiceProps) {
    const url = `/v1/auth/pin`
    const data = await api
      .post(url, props)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { RequestPinService }
