import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'
import PaperTextItem from '../../components/PaperTextItem'
import TabPanel from '../../components/TabPanel'
import { useAppSelector } from '../../redux/hooks'
import { GetDebtsByCustomerAndOrganizationService } from '../../services/debt/GetDebtsByCustomerAndOrganizationService'
import { Debt, DebtSituation } from '../../shared/interfaces/debt'
import { formatCurrency, formatDate } from '../../utils/format'
import { Button } from '../../components/Button'
import { CreateChargeService } from '../../services/charge/CreateChargeService'
import { useNavigate } from 'react-router-dom'

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

const NextDebts = () => {
  const theme = useTheme()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)

  const navigate = useNavigate()

  const { organizations } = useAppSelector(state => state.organization)
  const { customer } = useAppSelector(state => state.auth)
  const { user } = useAppSelector(state => state.user)

  const [tabValue, setTabValue] = React.useState(0)
  const [debts, setDebts] = React.useState<Debt[]>()

  React.useEffect(() => {
    if (user && organizations.length > 0) {
      handleGetChargesByCustomer(
        Number(customer?.id),
        organizations[tabValue].id
      )
    }
  }, [])

  React.useEffect(() => {
    if (user && organizations.length > 0) {
      handleGetChargesByCustomer(
        Number(customer?.id),
        organizations[tabValue].id
      )
    }
  }, [tabValue, organizations])

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const handleGetChargesByCustomer = async (
    idCustomer: number,
    idOrganization: number
  ) => {
    const service = new GetDebtsByCustomerAndOrganizationService()
    try {
      const response = await service.execute({
        idCustomer,
        idOrganization,
        nextDueDateOnly: true
      })

      setDebts(response)
    } catch (err) {
      console.log({ err })
    }
  }

  async function handleCreateCharge(debt?: Debt) {
    const service = new CreateChargeService()
    if (debt) {
      await service
        .execute({
          idCustomer: Number(customer?.id),
          idOrganization: organizations[tabValue].id,
          debts: [{ debtId: debt.id }],
          totalAmount: Number(debt.totalAmount.toFixed(2))
        })
        .then(response => {
          navigate(`/checkout/${response.shareHash}`)
        })
        .catch(err => console.log({ err }))
    }
  }
  return (
    <Layout title="Próximas parcelas">
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: theme.breakpoints.values.md
          },
          p: 2
        }}
      >
        <PageTitle
          title="Próximas parcelas"
          subtitle="Aqui você encontrará suas próximas parcelas."
        />
        <Paper
          sx={{
            p: { xs: 0, md: 2 },
            ...(matches && { backgroundColor: 'transparent' })
          }}
          elevation={matches ? 0 : 1}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scroll force tabs"
            value={tabValue}
            onChange={handleChangeTab}
          >
            {organizations.map(({ name }, index) => {
              return (
                <Tab
                  label={name}
                  {...a11yProps(index)}
                  key={index}
                  sx={{
                    ...(index === tabValue && {
                      backgroundColor: ' rgba(0, 0, 0, 0.05)'
                    })
                  }}
                />
              )
            })}
          </Tabs>
          {organizations.map((organization, index) => {
            return (
              <TabPanel
                value={tabValue}
                index={index}
                dir={theme.direction}
                key={index}
                sx={{
                  ...(matches && {
                    m: theme.spacing(0, 0, 24)
                  })
                }}
              >
                {debts?.map(
                  (debt, index) =>
                    debt.situation !== 'SUSPENDED' && (
                      <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <PaperTextItem
                            primary={`Vencimento: ${formatDate(debt.dueDate)}`}
                            secondary={`${formatCurrency(debt.totalAmount)} - ${
                              debt.organization.name
                            }`}
                          />

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: theme.spacing(16)
                              // maxWidth: theme.spacing(16),
                              // backgroundColor: 'rgba(255, 61, 113, 0.1)'
                            }}
                          >
                            {debt.situation !== 'ACTIVATION' && (
                              <Typography
                                sx={{
                                  width: '100%',
                                  color: '#fff',
                                  backgroundColor: '#FFA800',
                                  fontSize: theme.spacing(1.5),
                                  borderRadius: theme.spacing(0.5),
                                  p: 0.5
                                }}
                                textAlign="center"
                              >
                                {DebtSituation[
                                  debt.situation as keyof typeof DebtSituation
                                ].toLocaleUpperCase()}
                              </Typography>
                            )}
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{debt.description}</Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              mt: 2
                            }}
                          >
                            {debt.situation === 'ACTIVATION' && (
                              <Button
                                variant="contained"
                                rounded
                                onClick={() => handleCreateCharge(debt)}
                              >
                                Pagar parcela
                              </Button>
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    )
                )}
              </TabPanel>
            )
          })}
        </Paper>
      </Box>
    </Layout>
  )
}

export default NextDebts
