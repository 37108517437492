import { styled, TextField, TextFieldProps } from '@mui/material'

export const StyledTextField = styled(
  ({ mobile, ...otherProps }: TextFieldProps & { mobile?: boolean }) => (
    <TextField
      {...otherProps}
      {...(mobile && { variant: 'filled' })}
      // variant="filled"
      InputProps={{
        ...(mobile && {
          style: {
            backgroundColor: '#fff'
          },
          disableUnderline: true
        })
      }}
      sx={{ '& p': { display: 'block' } }}
      FormHelperTextProps={{
        sx: {
          fontSize: '1rem',
          alignSelf: 'center',
          ...(mobile && {
            color: '#fff'
          })
        }
      }}
    />
  )
)(() => ({
  '& .MuiFilledInput-root': {
    borderRadius: 4
  }
}))
