import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import TagManager from 'react-gtm-module'

Sentry.init({
  dsn: 'https://5c3999aaf2db44d0ab27cbfb02b31153@o963197.ingest.sentry.io/5911404',
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_VERCEL_ENV
})

const tagManagerArgs = {
  gtmId: String(process.env.REACT_APP_GA_TRACKING_ID)
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
