import { Box, Divider, Paper, Typography, useTheme } from '@mui/material'
import { Customer } from '../../shared/interfaces/customer'

interface Props {
  customer: Customer
}

const CustomerCard: React.FC<Props> = ({ customer }: Props) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        // backgroundColor: '#1bc',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Paper
        sx={{
          flex: 1,
          maxWidth: theme.spacing(36),
          height: theme.spacing(16),
          p: 0.5,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            p: 1
          }}
        >
          <Typography>{customer.displayName}</Typography>
          <Divider />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
            // width: theme.spacing(10),
            // height: theme.spacing(10)
          }}
        >
          <img
            style={{
              // backgroundColor: '#b34',
              objectFit: 'contain'
            }}
            src={customer.urlImgLogo}
            alt={`Logo ${customer.name}`}
            width={theme.spacing(9)}
            height={theme.spacing(9)}
          />
        </Box>
      </Paper>
    </Box>
  )
}
export default CustomerCard
