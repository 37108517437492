import { useState } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemText,
  Paper,
  Typography,
  useTheme
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { formatDate, formatCurrency } from '../../utils/format'
import PaperTextItem from '../PaperTextItem'
import {
  CheckoutData,
  InstallmentsOption
} from '../../shared/interfaces/checkoutData'

interface CheckoutDetailsProps {
  data: CheckoutData
  installments?: InstallmentsOption
}

const CheckoutDetails = ({ data, installments }: CheckoutDetailsProps) => {
  const theme = useTheme()

  const [expandedDetail, setExpandedDetail] = useState(true)

  // TODO: remove later
  enum paymentMethod {
    CREDIT_CARD = 'CREDIT_CARD',
    BOLETO_PIX = 'BOLETO_PIX'
  }

  const handleToggleDetail = () => {
    setExpandedDetail(!expandedDetail)
  }

  return (
    <Paper sx={{ p: theme.spacing(1, 2, 2), mb: 2 }}>
      <Accordion
        elevation={0}
        expanded={expandedDetail}
        onChange={handleToggleDetail}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            p: 0,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
              Detalhes
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List>
            {data.debts.map((debt, index) => (
              <ListItemText
                key={index}
                primary={`${debt.description} `}
                secondary={` - Vencimento original: ${formatDate(
                  debt.dueDate
                )}`}
              />
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <PaperTextItem
        sx={{ color: theme.palette.success.main }}
        primary={
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: 'bold' }}
          >
            Vencimento
          </Typography>
        }
        tail={formatDate(data.dueDate)}
      />
      <PaperTextItem
        sx={{
          color: theme.palette.success.main,
          ...(!installments && { display: 'none' })
        }}
        primary={
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: 'bold' }}
          >
            Parcelamento
          </Typography>
        }
        tail={`${installments?.installment}x ${formatCurrency(
          installments?.amountInstallment
        )}`}
      />
      <PaperTextItem
        sx={{ color: theme.palette.success.main }}
        primary={
          <Typography
            variant="body1"
            component="span"
            sx={{ fontWeight: 'bold' }}
          >
            Total a Pagar
            {installments && (
              <Typography
                component="span"
                style={{ fontSize: '.75rem' }}
                sx={{ ml: 1 }}
              >
                (+ taxa de transação)
              </Typography>
            )}
          </Typography>
        }
        tail={
          <Typography sx={{ fontWeight: 'bold' }}>
            {installments
              ? formatCurrency(installments?.totalAmount)
              : formatCurrency(data.amount)}
          </Typography>
        }
      />
    </Paper>
  )
}

export { CheckoutDetails }
