import { Box, CircularProgress, Grid, useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomerCard from '../../components/CustomerCard'
import PageTitle from '../../components/PageTitle'
import { ROUTES } from '../../constants/routes'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { activityStart } from '../../redux/slice/appSlice'
import { selectCustomer } from '../../redux/slice/authSlice'
import { storeCustomer } from '../../services/auth/common'
import { Customer } from '../../shared/interfaces/customer'

const CustomerSelection = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { data, customer } = useAppSelector(state => state.auth)

  useEffect(() => {
    document.title = `4IES | Seleção Instituição`
  }, [])

  React.useEffect(() => {
    if (customer) {
      navigate(ROUTES.BOARD.pathname)
    }
  }, [customer])
  const handleSelectCustomer = (customer: Customer) => {
    dispatch(selectCustomer(customer))
    storeCustomer(customer)
  }

  if (customer) {
    return (
      <Box
        sx={{
          // backgroundColor: theme.palette.primary.main,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: '100vh'
      }}
    >
      <Box sx={{ p: 4 }}>
        <PageTitle title="Selecione a instituição" dark />
        <Box>
          <Grid container spacing={1}>
            {data?.customers.map((customer, index) => (
              <>
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Box
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        filter: 'brightness(0.9)',
                        mt: -1
                      },
                      transition: 'all 1s ease'
                    }}
                    onClick={() => handleSelectCustomer(customer)}
                  >
                    <CustomerCard customer={customer} />
                  </Box>
                </Grid>
              </>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerSelection
