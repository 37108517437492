import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CheckoutData } from '../../shared/interfaces/checkoutData'
import { BilletDataBox } from '../BilletDataBox'
import { Button } from '../Button'

import { FaBarcode, FaCreditCard } from 'react-icons/fa'

const paymentMethodType = {
  DEFAULT: { label: '', icon: <FaBarcode size={24} /> },
  BOLETO_PIX: { label: 'BOLETO PIX', icon: <FaBarcode size={24} /> },
  BOLETO: { label: 'BOLETO', icon: <FaBarcode size={24} /> },
  PIX: { label: 'PIX', icon: <FaBarcode size={24} /> },
  CREDIT_CARD: {
    label: 'CARTÃO DE CRÉDITO',
    icon: <FaCreditCard size={24} />
  }
}
interface Props {
  data: CheckoutData
  loading?: boolean
  onCreateBillet: () => void
}

const BilletBox = ({ data, loading, onCreateBillet }: Props) => {
  const theme = useTheme()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)

  return (
    <Box>
      {/* BilletBox */}
      {data?.status !== 'ACTIVE' && (
        <Stack spacing={2}>
          <Typography>
            O boleto bancário será exibido após a confirmação da escolha do
            método de pagamento.
          </Typography>
          <Button
            variant="contained"
            rounded
            size="large"
            sx={{ height: theme.spacing(6) }}
            onClick={onCreateBillet}
            loading={loading}
            // loading={activity.rule === 'pay.billet' && activity.loading}
          >
            {`GERAR ${
              paymentMethodType[data.type as keyof typeof paymentMethodType]
                .label
            }`}
          </Button>
        </Stack>
      )}
      {data?.status === 'ACTIVE' && (
        <BilletDataBox data={data} showPDFButton={!matches} />
      )}
    </Box>
  )
}

export { BilletBox }
