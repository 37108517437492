import { PayloadAction } from '@reduxjs/toolkit'
import {
  call,
  CallEffect,
  put,
  StrictEffect,
  TakeEffect,
  takeEvery
} from 'redux-saga/effects'
import { GetPersonDataByIdCustomer } from '../../services/person/GetPersonDataByIdCustomer'
import { User } from '../../shared/interfaces/user'
import {
  getUserDataFailure,
  getUserDataStart,
  getUserDataSuccess
} from '../slice/userSlice'

export function* handleGetUserData(
  action: PayloadAction<number>
): Generator<unknown, void, User> {
  const service = new GetPersonDataByIdCustomer()
  try {
    const person = yield handleGetUserPersonData(action)
    yield put(getUserDataSuccess(person))
  } catch (err) {
    console.log({ err })
    yield put(getUserDataFailure(String(err)))
  }
}
export function* handleGetUserPersonData(
  action: PayloadAction<number>
): Generator<unknown, User | null, User> {
  const service = new GetPersonDataByIdCustomer()
  try {
    const response = yield call(service.execute, { idCustomer: action.payload })
    return response
  } catch (err) {
    console.log({ err })
    return null
  }
}
// export function* handleGetUserPersonData(
//   action: PayloadAction<number>
// ): Generator<unknown, User | null, User> {
//   const service = new GetPersonDataByIdCustomer()
//   try {
//     const response = yield call(service.execute, { idCustomer: action.payload })
//     return response
//   } catch (err) {
//     console.log({ err })
//     return null
//   }
// }

export function* watchUser() {
  yield takeEvery(getUserDataStart.type, handleGetUserData)
}
