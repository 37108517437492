import { Debt } from '../../shared/interfaces/debt'
import api from '../api'
interface GetDebtsByCustomerAndOrganizationServiceProps {
  idOrganization: number
  idCustomer: number
  nextDueDateOnly?: boolean
}

class GetDebtsByCustomerAndOrganizationService {
  async execute({
    idOrganization,
    idCustomer,
    nextDueDateOnly
  }: GetDebtsByCustomerAndOrganizationServiceProps): Promise<Debt[]> {
    const url = `/v1/appDebts/customer/${idCustomer}/organization/${idOrganization}`

    const data = await api
      .get(url, {
        params: {
          nextDueDateOnly
        }
      })
      .then(({ data }) => {
        if (!data.length) {
          return []
        }
        return data
      })
      .catch(err => {
        throw err
      })

    return data
  }
}

export { GetDebtsByCustomerAndOrganizationService }
