import { Box, IconButton, Link, Typography } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import popupImage from '../../assets/images/popup-4ies-principia.jpeg'
import { useState, useEffect } from 'react'
import { Customer } from '../../shared/interfaces/customer'
import migration4iesPrincipia from '../../assets/images/4ies-e-principia.png'

interface NewsPopupProps {
  principiaMigration?: string
  customer: string
}

export const NewsPopup = ({ principiaMigration, customer }: NewsPopupProps) => {
  const [newsIsOpen, setNewsIsOpen] = useState(false)

  useEffect(() => {
    const stored = sessionStorage.getItem('news-popup')
    if (stored) {
      setNewsIsOpen(JSON.parse(stored))
    }
    setNewsIsOpen(true)
  }, [])
  useEffect(() => {
    console.log(newsIsOpen)
  }, [newsIsOpen])

  const handleClose = () => {
    sessionStorage.setItem('news-popup', 'false')
    setNewsIsOpen(false)
  }

  if (newsIsOpen === false) {
    return null
  }

  if (principiaMigration && newsIsOpen) {
    return (
      <Box
        sx={{
          position: 'fixed',
          zIndex: 9999,
          background: 'rgba(0, 0, 0, 0.6)',
          // width: '100vw',
          // height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            // p: 0.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#fff',
            borderRadius: 4,
            width: { xs: '90vw', sm: 600 },
            maxWidth: { xs: 425, sm: 600 },
            height: { xs: '90vh', sm: 600 },
            boxShadow: '1px 2px 8px rgba(10, 10, 10, 0.2)'
          }}
        >
          <Box
            sx={{
              position: 'relative',
              borderRadius: 4,
              width: { xs: '90vw', sm: 600 },
              maxWidth: { xs: 425, sm: 600 },
              height: { xs: '100%', sm: 600 }
            }}
          >
            <Box
              sx={{
                left: 0,
                top: 0,
                // border: '1px solid #000',
                // borderTopLeftRadius: 4,
                width: { xs: '50%' },
                height: { xs: '50%' },
                borderRadius: '16px 16px 0 0',
                position: 'absolute',
                background:
                  'linear-gradient(121.51deg, rgba(0, 110, 171, 0.50) 1.83%, rgba(255, 255, 255, 0) 35.88%)'
              }}
            ></Box>
            <Box
              sx={{
                right: 0,
                bottom: 0,
                width: { xs: '50%' },
                height: { xs: '50%' },
                borderRadius: '16px 16px 0 0',
                position: 'absolute',
                background:
                  'linear-gradient(131.51deg, rgba(204, 85, 199, 0.50) 1.83%, rgba(255, 255, 255, 0) 37.8%)',
                transform: 'rotate(-180deg)'
              }}
            ></Box>
            {/* <IconButton
              sx={{ position: 'absolute', top: 8, right: 8 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton> */}
            <Box
              sx={{
                // background: '#456',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2
              }}
            >
              <img src={migration4iesPrincipia} />
              <Box
                sx={{
                  px: { xs: 4, md: 8 },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: { xs: 24, md: 24 },
                    fontWeight: 'bold'
                  }}
                >
                  Para acompanhar suas mensalidades, acesse a nossa plataforma.
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  py: 1,
                  borderBottom: '1px #006EAB solid'
                }}
              >
                <Typography
                  sx={{
                    color: '#006EAB',
                    fontWeight: 'bold',
                    letterSpacing: '0.18em',
                    fontSize: { xs: 12, md: 14 }
                  }}
                >
                  Veja como é fácil acessar:
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 2.5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'start',
                  gap: 1.75,
                  px: 1.55
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    component="span"
                    sx={{
                      mr: 0.5,
                      minWidth: '26px',
                      fontSize: { xs: 12, md: 14 }
                    }}
                  >
                    1° -
                  </Typography>
                  <Typography className="w-full">
                    Acesse:{' '}
                    <Link
                      href={principiaMigration}
                      target="_blank"
                      sx={{
                        color: '#006EAB',
                        textDecoration: 'none',
                        fontSize: { xs: 12, md: 14 }
                      }}
                    >
                      {principiaMigration}
                    </Link>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    component="span"
                    sx={{
                      mr: 0.5,
                      minWidth: '26px',
                      fontSize: { xs: 12, md: 14 }
                    }}
                  >
                    2° -
                  </Typography>
                  <Typography sx={{ fontSize: { xs: 12, md: 14 } }}>
                    <p>
                      {`Coloque o seu CPF e data de nascimento (somente números)`}
                    </p>
                    <span>
                      Exemplo: se você nasceu em 18/06/1995, sua senha será
                      18061995
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  background: '#456',
                  width: 1,
                  py: 0.2,
                  mt: 4,
                  ml: -4,
                  mr: -4,
                  opacity: 0.15,
                  borderRadius: 5
                }}
              ></Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ pt: 3 }}>
                  <Typography
                    textAlign="center"
                    component="p"
                    sx={{ color: '#006EAB', fontSize: 24, fontWeight: 'bold' }}
                  >
                    Novo Portal:
                  </Typography>
                </Box>
                <Link
                  href={principiaMigration}
                  target="_blank"
                  sx={{
                    textDecoration: 'none',
                    background: '#006EAB',
                    p: 1.5,
                    color: '#fff',
                    borderRadius: 10,
                    fontWeight: 'bold',
                    fontSize: 16,
                    textAlign: 'center'
                  }}
                >
                  Clique Aqui
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        background: 'rgba(0, 0, 0, 0.6)',
        // width: '100vw',
        // height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          // p: 0.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // background: '#666',
          borderRadius: 1,
          width: { xs: 300, sm: 460 },
          height: { xs: 300, sm: 460 },
          boxShadow: '1px 2px 8px rgba(10, 10, 10, 0.2)'
        }}
      >
        <img
          src={popupImage}
          width="100%"
          height="100%"
          style={{ borderRadius: '8px' }}
        />
        <IconButton
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
