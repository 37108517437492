import {
  Box,
  Button,
  DialogContentText,
  Drawer,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { formatDate } from '../../utils/format'

import ChevronLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ChevronRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useState } from 'react'
import { Charge } from '../../shared/interfaces/charge'
import { useNavigate } from 'react-router-dom'
import { CancelChargeService } from '../../services/charge/CancelChargeService'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { ConfirmationDialog } from '../ConfirmationDialog'
import { setSnackbar } from '../../redux/slice/snackbarSlice'
import { delay } from '../../utils/delay'
import { SetExpiredChargeService } from '../../services/charge/SetExpiredChargeService'

interface Props {
  charges: Charge[]
  open: boolean
  onAlreadyHasPaid: () => void
  onCancel?: () => void
}

const ActiveBilletAlert = ({
  charges,
  open,
  onAlreadyHasPaid,
  onCancel
}: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { customer } = useAppSelector(state => state.auth)
  const { customerSettings } = useAppSelector(state => state.app)
  const [isLoading, setIsLoading] = useState(false)

  const chargesOverdue = charges.filter(item => item.overdueDays > 0)

  const [alertActiveIndex, setAlertActiveIndex] = useState(0)

  const handleUpdateAlertActiveIndex = (type: string) => {
    if (type === 'up') {
      if (alertActiveIndex >= charges.length - 1) return
      setAlertActiveIndex(alertActiveIndex + 1)
    }
    if (type === 'down') {
      if (alertActiveIndex <= 0) return
      setAlertActiveIndex(alertActiveIndex - 1)
    }
  }

  const handleEmitNewBillet = async () => {
    setIsLoading(true)
    const charge = charges[alertActiveIndex]

    await setExpiredBillet(charge)
      .then(() => {
        onAlreadyHasPaid()
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)

        setIsLoading(false)
      })
    handleCloseConfirmationDialog()
  }

  const cancelBillet = async (chargeToCancel: Charge) => {
    const service = new CancelChargeService()

    await service.execute({
      idCharge: chargeToCancel.id,
      idCustomer: Number(customer?.id)
    })
    await delay(1)
    if (onCancel) {
      onCancel()
    }
  }
  const setExpiredBillet = async (chargeToCancel: Charge) => {
    const service = new SetExpiredChargeService()

    await service.execute({
      idCharge: chargeToCancel.id,
      idCustomer: Number(customer?.id)
    })
    await delay(5)
    if (onCancel) {
      onCancel()
    }
  }

  // const getRecalculatedDebtsFromCanceledCharge = () => {
  // }

  const handleGoToCheckout = (method?: string) => {
    const charge = charges[alertActiveIndex]
    navigate(
      `/checkout/${charge.shareHash}${method ? `?method=${method}` : ''}`
    )
  }

  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] =
    useState(false)

  const handleOpenConfirmationDialog = () => {
    setIsOpenConfirmationDialog(true)
  }
  const handleCloseConfirmationDialog = () => {
    setIsOpenConfirmationDialog(false)
  }

  if (chargesOverdue.length > 0) {
    return (
      <Drawer
        anchor="bottom"
        open={open}
        // onClose={handleCloseBilletAlert}
        PaperProps={{
          sx: {
            backgroundColor: '#CA0202',
            pb: customerSettings.whiteLabel === 'ACTIVE' ? 4 : 0
          }
        }}
      >
        <ConfirmationDialog
          open={isOpenConfirmationDialog}
          title={
            <Typography>
              Confirmação que o boleto <u>não</u> foi pago
            </Typography>
          }
          onClose={handleCloseConfirmationDialog}
          onConfirm={handleEmitNewBillet}
          loading={isLoading}
          confirmationButtonProps={{ label: 'Não Paguei' }}
        >
          {/* <DialogContentText>
            Uma nova cobrança com juros e taxas atualizadas será gerada.
          </DialogContentText> */}
          <DialogContentText>
            Caso o boleto atual tenha sido pago, aguardar até 3 dias úteis para
            a confirmação do pagamento em nosso sistema.
          </DialogContentText>
        </ConfirmationDialog>
        <Box sx={{ pt: 1, pb: 2, color: '#fff' }}>
          {chargesOverdue.length > 1 && (
            <Box
              sx={{
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: 1
              }}
            >
              <Button
                sx={{
                  minHeight: 8,
                  color: '#fff',
                  height: theme.spacing(2)
                }}
                onClick={() => handleUpdateAlertActiveIndex('down')}
              >
                <ChevronLeftIcon />
              </Button>
              <Typography fontSize={theme.spacing(2)}>{`${
                alertActiveIndex + 1
              } de ${chargesOverdue.length}`}</Typography>
              <Button
                sx={{
                  minHeight: 8,
                  color: '#fff',
                  height: theme.spacing(2)
                }}
                onClick={() => handleUpdateAlertActiveIndex('up')}
              >
                <ChevronRightIcon />
              </Button>
            </Box>
          )}
          <Box
            sx={{
              flex: 1,
              p: 1,
              backgroundColor: '#fff',
              color: '#CA0202',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1
            }}
          >
            <WarningAmberIcon sx={{ fontSize: theme.spacing(5) }} />
            <Typography fontSize={theme.spacing(5)} fontWeight="bold">
              ATENÇÃO
            </Typography>
          </Box>
          <Stack spacing={1} sx={{ p: 2 }}>
            <Typography
              textAlign="center"
              fontSize={theme.spacing(2.5)}
              sx={{ px: 1.5 }}
            >
              {charges.length &&
                `Você possui um boleto vencido em ${formatDate(
                  chargesOverdue[alertActiveIndex].dueDate
                )}`}
            </Typography>
            <Typography
              textAlign="center"
              fontSize={theme.spacing(1.65)}
              fontStyle="italic"
              sx={{ px: 1.5 }}
            >
              Emita um novo boleto para pagar
            </Typography>
          </Stack>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: { xs: 320, md: 384 },
                display: 'flex',
                // flexDirection: 'column',
                gap: 1,
                alignItems: 'center'
              }}
            >
              <Button
                variant="outlined"
                sx={{ textTransform: 'none' }}
                color="secondary"
                onClick={onAlreadyHasPaid}
                fullWidth
              >
                Ja quitei este boleto!
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  // flexGrow: 1,
                  textTransform: 'none',
                  color: '#CA0202'
                }}
                onClick={handleOpenConfirmationDialog}
                fullWidth
              >
                Emitir novo boleto
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>
    )
  }

  if (charges.length <= 0) {
    return null
  }

  return (
    <Drawer
      anchor="bottom"
      open={open}
      // onClose={handleCloseBilletAlert}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.warning.main,
          pb: customerSettings.whiteLabel === 'ACTIVE' ? 4 : 0
        }
      }}
    >
      <Box sx={{ pt: 1, pb: 2, color: '#fff' }}>
        {charges.length > 1 && (
          <Box
            sx={{
              mb: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              gap: 1
            }}
          >
            <Button
              sx={{
                minHeight: 8,
                color: '#fff',
                height: theme.spacing(2)
              }}
              onClick={() => handleUpdateAlertActiveIndex('down')}
            >
              <ChevronLeftIcon />
            </Button>
            <Typography fontSize={theme.spacing(2)}>{`${
              alertActiveIndex + 1
            } de ${charges.length}`}</Typography>
            <Button
              sx={{
                minHeight: 8,
                color: '#fff',
                height: theme.spacing(2)
              }}
              onClick={() => handleUpdateAlertActiveIndex('up')}
            >
              <ChevronRightIcon />
            </Button>
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            p: 1,
            backgroundColor: '#fff',
            color: theme.palette.warning.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1
          }}
        >
          <WarningAmberIcon sx={{ fontSize: theme.spacing(5) }} />
          <Typography fontSize={theme.spacing(5)} fontWeight="bold">
            ATENÇÃO
          </Typography>
        </Box>
        <Stack spacing={1} sx={{ p: 2 }}>
          <Typography
            textAlign="center"
            fontSize={theme.spacing(2.5)}
            sx={{ px: 1.5 }}
          >
            {charges.length &&
              `Você possui um boleto com vencimento em ${formatDate(
                charges[alertActiveIndex].dueDate
              )}`}
          </Typography>
          <Typography
            textAlign="center"
            fontSize={theme.spacing(1.65)}
            fontStyle="italic"
            sx={{ px: 1.5 }}
          >
            Não emita um novo boleto antes de quitar o seu boleto em aberto!
          </Typography>
        </Stack>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: { xs: 300, md: 384 },
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  // flexGrow: 1,
                  textTransform: 'none',
                  color: theme.palette.warning.main
                }}
                onClick={() =>
                  handleGoToCheckout(charges[alertActiveIndex].type)
                }
                fullWidth
              >
                Ver boleto ou PIX
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  // flexGrow: 1,
                  textTransform: 'none',
                  color: theme.palette.warning.main
                }}
                onClick={() => handleGoToCheckout('CREDIT_CARD')}
                fullWidth
              >
                Pagar no cartão
              </Button>
            </Box>
            <Button
              variant="outlined"
              sx={{ textTransform: 'none' }}
              color="secondary"
              onClick={onAlreadyHasPaid}
              fullWidth
            >
              Ja quitei este boleto!
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export { ActiveBilletAlert }
