import React from 'react'
import {
  Box,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextField as MuiTextField,
  TextFieldProps,
  Typography,
  useTheme
} from '@mui/material'

import { formatCurrency } from '../../utils/format'
import { Button } from '../Button'
import PaperTextItem from '../PaperTextItem'
import { creditCardMask } from '../../utils/mask'

// import { Container } from './styles';

const TextField = styled((props: TextFieldProps) => (
  <MuiTextField variant="standard" {...props} />
))(() => ({}))

interface CreditCardFormProps {
  activity: {
    loading: boolean
    rule?: string
  }
  cardData: {
    cardNumber: string
    holderName: string
    securityCode: string
    expirationMonth: string
    expirationYear: string
    expiration: string
  }
  cardDataError: {
    // cardNumber: boolean
    // holderName: boolean
    // securityCode: boolean
    // expirationMonth: boolean
    // expirationYear: boolean
    // expiration: boolean
    INVALID_NUMBER: boolean
    INVALID_SECURITY_CODE: boolean
    INVALID_EXPIRATION_MONTH: boolean
    INVALID_EXPIRATION_YEAR: boolean
    INVALID_PUBLIC_KEY: boolean
    INVALID_HOLDER: boolean
    showBox: boolean
  }
  installmentsOptions: {
    installment: number
    amountInstallment: number
    totalAmount: number
  }[]
  installments: number | ''
  onChange: (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<number>,
    target: string,
    value: string | number
  ) => void
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const CreditCardForm: React.FC<CreditCardFormProps> = ({
  activity,
  cardData,
  cardDataError,
  installmentsOptions,
  installments,
  onChange,
  onClick
}) => {
  const theme = useTheme()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target.id
    const value = event.target.value
    onChange(event, target, value.toUpperCase())
  }

  const handleSelect = (event: SelectChangeEvent<number>) => {
    const target = event.target.name
    const value = event.target.value
    onChange(event, target, value)
  }
  return (
    <Stack spacing={1}>
      <Box>
        <InputLabel id="installmentNumber">Parcelamento</InputLabel>
        <Select
          variant="standard"
          name="installments"
          value={installments}
          onChange={handleSelect}
          labelId="installmentNumber"
          fullWidth
        >
          {installmentsOptions.length > 0 &&
            installmentsOptions.map((option, index) => (
              <MenuItem
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around'
                }}
                value={option.installment}
              >
                <PaperTextItem
                  primary={`${option.installment}x ${formatCurrency(
                    option.amountInstallment
                  )}`}
                  tail={`${formatCurrency(option.totalAmount)}`}
                />
              </MenuItem>
            ))}
        </Select>
      </Box>
      <TextField
        id="holderName"
        label="Nome impresso no cartão"
        value={cardData.holderName}
        onChange={handleChange}
        error={cardDataError.INVALID_HOLDER}
        helperText={
          cardDataError.INVALID_HOLDER &&
          'Nome deve ser igual ao impresso no cartão'
        }
        sx={{ display: { md: 'none' } }}
      />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <TextField
          id="cardNumber"
          autoComplete="cc-number"
          label="Número do cartão"
          value={creditCardMask(cardData.cardNumber)}
          onChange={handleChange}
          placeholder="0000 0000 0000 0000"
          error={cardDataError.INVALID_NUMBER}
          helperText={
            cardDataError.INVALID_NUMBER &&
            'Número deve ser igual ao impresso no cartão'
          }
          inputProps={{
            inputMode: 'numeric'
          }}
          fullWidth
        />
        <TextField
          id="expiration"
          label="Vencimento"
          value={cardData.expiration}
          onChange={handleChange}
          placeholder="mm / aaaa"
          error={
            cardDataError.INVALID_EXPIRATION_MONTH ||
            cardDataError.INVALID_EXPIRATION_YEAR
          }
          helperText={
            (cardDataError.INVALID_EXPIRATION_MONTH ||
              cardDataError.INVALID_EXPIRATION_YEAR) &&
            'Vencimento inválido'
          }
          inputProps={{
            inputMode: 'numeric'
          }}
          sx={{
            display: { xs: 'none', md: 'inline-flex' },
            width: theme.spacing(40)
          }}
        />
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 2 }}>
        <TextField
          id="expiration"
          label="Vencimento"
          value={cardData.expiration}
          onChange={handleChange}
          placeholder="mm / aaaa"
          error={
            cardDataError.INVALID_EXPIRATION_MONTH ||
            cardDataError.INVALID_EXPIRATION_YEAR
          }
          helperText={
            (cardDataError.INVALID_EXPIRATION_MONTH ||
              cardDataError.INVALID_EXPIRATION_YEAR) &&
            'Vencimento inválido'
          }
          inputProps={{
            inputMode: 'numeric'
          }}
        />
        <TextField
          id="securityCode"
          label="Código de Segurança"
          value={cardData.securityCode
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1')}
          onChange={handleChange}
          error={cardDataError.INVALID_SECURITY_CODE}
          helperText={cardDataError.INVALID_SECURITY_CODE && 'Código inválido'}
          inputProps={{
            inputMode: 'numeric'
          }}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
        <TextField
          id="holderName"
          label="Nome impresso no cartão"
          value={cardData.holderName}
          onChange={handleChange}
          error={cardDataError.INVALID_HOLDER}
          helperText={
            cardDataError.INVALID_HOLDER &&
            'Nome deve ser igual ao impresso no cartão'
          }
          fullWidth
        />
        <TextField
          id="securityCode"
          label="Código de Segurança"
          value={cardData.securityCode
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1')}
          onChange={handleChange}
          error={cardDataError.INVALID_SECURITY_CODE}
          helperText={cardDataError.INVALID_SECURITY_CODE && 'Código inválido'}
          inputProps={{
            inputMode: 'numeric'
          }}
          sx={{ width: theme.spacing(40) }}
        />
      </Box>
      {/* {(cardDataError.holderName ||
          cardDataError.cardNumber ||
          cardDataError.expiration ||
          cardDataError.securityCode) && ( */}
      {cardDataError.showBox && (
        <Paper
          variant="outlined"
          sx={{
            marginTop: '1rem',
            padding: '.25rem',
            backgroundColor: '#ff3d7122',
            color: theme.palette.error.main
          }}
          elevation={0}
        >
          <Typography sx={{ fontSize: '.85rem', marginBottom: '.5rem' }}>
            Por favor, verifique os dados do cartão e tente novamente.
          </Typography>
          <Typography sx={{ fontSize: '.85rem', marginBottom: '.5rem' }}>
            O nome deve ser igual ao escrito no cartão.
          </Typography>
          <Typography sx={{ fontSize: '.85rem', marginBottom: '.5rem' }}>
            O Vencimento deve ter o formato desse exemplo: Mês/Ano: 01/2025.
          </Typography>
          <Typography sx={{ fontSize: '.85rem', marginBottom: '.5rem' }}>
            Verifique o código de três dígitos atrás do cartão.
          </Typography>
        </Paper>
      )}
      <Box sx={{ flexGrow: 1, p: theme.spacing(2, 0, 0) }}>
        <Button
          variant="contained"
          rounded
          size="large"
          fullWidth
          sx={{ height: theme.spacing(6) }}
          onClick={onClick}
          loading={activity.rule === 'pay.card' && activity.loading}
        >
          PAGAR COM CARTÃO
        </Button>
      </Box>
    </Stack>
    // </Box>
  )
}

export default CreditCardForm
