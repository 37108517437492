import { CheckoutData } from '../../shared/interfaces/checkoutData'
import api from '../api'

interface GetChargeByHashServiceProps {
  shareHash: string
}
class GetChargeByHashService {
  async execute({
    shareHash
  }: GetChargeByHashServiceProps): Promise<CheckoutData> {
    const url = `/v2/appCharges/checkout/${shareHash}`

    const data = await api
      .get(url)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { GetChargeByHashService }
