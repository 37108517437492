import { Debt } from '../../shared/interfaces/debt'
import api from '../api'
interface GetDebtsRecalculatedServiceProps {
  idPersonDebt: number
  idOrganization: number
  idCustomer: number
  debts: { debtId: number }[]
}

class GetDebtsRecalculatedService {
  async execute(props: GetDebtsRecalculatedServiceProps): Promise<Debt[]> {
    const url = `/v1/appDebts/recalculation`

    const data = await api
      .post(url, props)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { GetDebtsRecalculatedService }
