import { Organization } from './organization'

export enum DebtSituation {
  'ACTIVATION' = 'Acionamento',
  'SUSPENDED' = 'Suspenso',
  'CONTESTED' = 'Contestada',
  'COUNTERPROPOSAL' = 'Contraproposta',
  'LAWSUIT' = 'Ação Judicial'
}

export interface Debt {
  selected?: boolean
  id: number
  referenceId: string
  description: string
  dueDate: string
  overdueDays: number
  amount: number
  totalAmount: number
  fine: number
  interest: number
  additionalValues: number
  negativationFee: number
  negativation: boolean
  commissionCharged: number
  addition: number
  organization: Organization
  paymentFee: number
  paymentFeeCharged: number
  interestType: string
  discount: number
  commission: number
  situation: string
}
