import { Box, useTheme } from '@mui/material'

const EnvironmentMark = () => {
  const theme = useTheme()
  const env = process.env.REACT_APP_VERCEL_ENV
  if (env === 'production') return null

  return (
    <Box
      sx={{
        p: 0.2,
        display: 'flex',
        justifyContent: 'center',
        ...(env === 'development' && {
          backgroundColor: theme.palette.error.main
        }),
        ...(env === 'stage' && { backgroundColor: theme.palette.warning.main }),

        color: '#fff',
        zIndex: 9999,
        // width: '100%',
        position: 'fixed',
        left: 0,
        right: 0
      }}
    >
      Esse é um ambiente de&nbsp;
      <i>
        <u>
          {env === 'development' && 'DESENVOLVIMENTO'}
          {env === 'stage' && 'TESTES'}
        </u>
      </i>
    </Box>
  )
}

export { EnvironmentMark }
