import { Box, styled } from '@mui/material'

const ImageBox = styled(Box)(({ theme }) => ({
  // flexGrow: 1,
  // padding: theme.spacing(0.25, 1),
  width: theme.spacing(8),
  height: theme.spacing(8),
  // backgroundColor: '#455'
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}))

const Image = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  // flexGrow: 1
  // width: theme.spacing(8),
  // height: theme.spacing(8)
  width: '100%',
  height: '100%'
}))

export { Image, ImageBox }
