import { User } from '../../shared/interfaces/user'
import api from '../api'

class ValidateUserAuthService {
  async execute(): Promise<User> {
    const url = `/v1/auth/validateUser`
    const data = await api
      .post(url)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { ValidateUserAuthService }
