import api from '../api'

interface SetExpiredChargeServiceProps {
  idCharge: number
  idCustomer: number
}

class SetExpiredChargeService {
  async execute({ idCharge, idCustomer }: SetExpiredChargeServiceProps) {
    const url = `/v1/appCharges/customer/${idCustomer}/charge/${idCharge}/expired`

    const data = await api
      .post(url)
      .then(({ data }) => ({ ...data, status: 'success' }))
      .catch(err => {
        throw err
      })
    return data
  }
}

export { SetExpiredChargeService }
