import api from '../api'

interface UpdateUserCommunicationPreferencesServiceProps {
  typeCommunication: string
  timeCommunication: string
}

class UpdateUserCommunicationPreferencesService {
  async execute({
    typeCommunication,
    timeCommunication
  }: UpdateUserCommunicationPreferencesServiceProps) {
    const url = `/v1/appUsers/typeCommunication`

    const data = await api
      .patch(url, {
        typeCommunication,
        timeCommunication
      })
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })
    return data
  }
}

export { UpdateUserCommunicationPreferencesService }
