import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps
} from '@mui/material'
import { ReactNode } from 'react'
import { Button } from '../Button'

interface ConfirmationDialogProps {
  children?: ReactNode
  title: string | ReactNode
  loading?: boolean
  open: boolean
  onClose: () => void
  onConfirm: () => void
  cancelButtonProps?: {
    label?: string
    sx?: SxProps
  }
  confirmationButtonProps?: {
    label?: string
    sx?: SxProps
  }
}

const ConfirmationDialog = ({
  title,
  children,
  loading,
  open,
  onClose,
  onConfirm,
  cancelButtonProps = {
    label: 'Cancelar'
  },
  confirmationButtonProps = {
    label: 'Confirmar'
  }
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="small"
          onClick={onClose}
          // {...cancelButtonProps.sx}
        >
          {cancelButtonProps.label}
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={onConfirm}
          disabled={loading}
          loading={loading}
          // {...confirmationButtonProps.sx}
        >
          {confirmationButtonProps.label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { ConfirmationDialog }
