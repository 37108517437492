import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useTheme,
  styled,
  ListItemIcon,
  CSSObject,
  Theme,
  ListItemButton
} from '@mui/material'

import { Image, ImageBox } from '../Image'

import HomeIcon from '@mui/icons-material/Home'
import HistoryIcon from '@mui/icons-material/History'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import HelpIcon from '@mui/icons-material/Help'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'

import { useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../../services/auth/common'
import { ROUTES } from '../../constants/routes'

// import { Container } from './styles';

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.drawer.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(12),
  // necessary for content to be below app bar
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    // justifyContent: 'flex-end',
    height: theme.spacing(12)
  },
  ...theme.mixins.toolbar
}))

const drawerItems = [
  {
    label: 'Inicio',
    pathname: '/board',
    icon: <HomeIcon />
  },
  {
    label: 'Próximas parcelas',
    pathname: '/next-debts',
    icon: <CalendarMonthIcon />
  },
  {
    label: 'Histórico de Pagamento',
    pathname: '/payment-history',
    icon: <HistoryIcon />
  }
]

interface Props {
  window?: () => Window
  drawerOpen: boolean
  drawerMobileOpen: boolean
  drawerToggle: () => void
  drawerMobileToggle?: () => void
}
const Sidebar = (props: Props) => {
  const theme = useTheme()
  const {
    window,
    drawerOpen,
    drawerMobileOpen,
    drawerToggle,
    drawerMobileToggle
  } = props
  const navigate = useNavigate()
  const location = useLocation()

  const drawer = (
    <>
      <DrawerHeader>
        <Box
          sx={{
            flexGrow: 1,
            display: drawerOpen ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ImageBox
            sx={{
              // [theme.breakpoints.up('sm')]: {
              width: '100%',
              height: theme.spacing(10),
              ml: { sm: 2 }
              // padding: { xs: 1, md: 0.25 }
              // }
            }}
          >
            <Image src={theme.logo.primary} />
          </ImageBox>
        </Box>
        <IconButton
          onClick={drawerToggle}
          sx={{ display: { xs: 'none', md: 'inline-flex' } }}
        >
          {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </DrawerHeader>
      {/* </Toolbar> */}
      <Divider />
      <List>
        {drawerItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            onClick={() => navigate(item.pathname)}
          >
            <ListItemButton
              sx={{
                justifyContent: 'center',
                // px: 2
                pl: 2,
                pr: 0
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '32px',
                  mr: 2,
                  justifyContent: 'center'
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem key="help" onClick={() => navigate('/help')} disablePadding>
          <ListItemButton
            sx={{
              justifyContent: 'center',
              // px: 2
              pl: 2,
              pr: 0
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '32px',
                mr: 2,
                justifyContent: 'center'
              }}
            >
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={'Ajuda'} />
          </ListItemButton>
        </ListItem>
        <ListItem key="logout" onClick={logout} disablePadding>
          <ListItemButton
            sx={{
              justifyContent: 'center',
              // px: 2
              pl: 2,
              pr: 0
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: '32px',
                mr: 2,
                justifyContent: 'center'
              }}
            >
              <LogoutIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={'Sair'}
              sx={{ color: theme.palette.error.main }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={drawerMobileOpen}
        onClose={drawerMobileToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: theme.drawer?.width
          }
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        anchor="left"
        sx={{
          display: { xs: 'none', md: 'inline-flex' },
          // '& .MuiDrawer-paper': {
          //   boxSizing: 'border-box',
          //   width: theme.drawer?.width
          // }
          width: theme.drawer.width,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...(drawerOpen && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme)
          }),
          ...(!drawerOpen && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme)
          })
        }}
      >
        {drawer}
      </Drawer>
    </>
  )
}

export default Sidebar
