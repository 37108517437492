import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { watcherSaga } from './rootSaga'
import appSlice from './slice/appSlice'
import authSlice from './slice/authSlice'
import userSlice from './slice/userSlice'
import debtSlice from './slice/debtSlice'
import organizationSlice from './slice/organizationSlice'
import snackbarSlice from './slice/snackbarSlice'

const sagaMiddleware = createSagaMiddleware()

const reducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  user: userSlice,
  debt: debtSlice,
  organization: organizationSlice,
  snackbar: snackbarSlice
})

export const store = configureStore({
  reducer,
  middleware: [sagaMiddleware]
})

sagaMiddleware.run(watcherSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
