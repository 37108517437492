import { Box, SxProps } from '@mui/material'
import React from 'react'

// import { Container } from './styles';

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: number | string
  value: number | string
  sx?: SxProps
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  )
}

export default TabPanel
