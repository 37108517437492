import React, { useEffect, useState } from 'react'
import {
  Box,
  CssBaseline,
  IconButton,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material'

import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

import MenuIcon from '@mui/icons-material/Menu'

import Sidebar from '../Sidebar'
import { Image, ImageBox } from '../Image'

import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { setSidebarOpen } from '../../redux/slice/appSlice'
import { HelpButton } from '../HelpButton'

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => {
  return {
    // position: 'relative',
    maxWidth: '100vw',
    flexGrow: 1,
    padding: theme.spacing(4, 0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${theme.drawer.width}`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    }),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 8, 0, 12)
    }
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  height: theme.spacing(8),
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.drawer.width}px)`,
      marginLeft: `${theme.drawer.width}px`
    },
    // width: `calc(100% - ${theme.drawer.width}px)`,
    // width: { md: `calc(100% - ${theme.drawer.width}px)` },
    // marginLeft: `${theme.drawer.width}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

interface LayoutProps {
  title?: string
}

const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)
  const { sidebarOpen } = useAppSelector(state => state.app)
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false)

  useEffect(() => {
    document.title = `4IES${title ? ' | ' + title : ''}`
  }, [])

  const handleDrawerToggle = () => {
    dispatch(setSidebarOpen(!sidebarOpen))
  }
  const handleDrawerMobileToggle = () => {
    setSidebarMobileOpen(!sidebarMobileOpen)
  }

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#fafafa', height: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={sidebarOpen}
        sx={{
          backgroundColor: { md: 'transparent' },
          color: { md: theme.palette.text.primary },
          boxShadow: { md: 'none' }
        }}
        // color={theme.breakpoints.up('sm') ? 'transparent' : 'default'}
        // elevation={0}
      >
        <Toolbar sx={{ height: '100%', paddingLeft: 0 }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { md: 'none' }
            }}
          >
            <ImageBox>
              <Image
                src={theme.logo.secondary}
                alt=""
                sx={{
                  objectFit: 'contain',
                  width: theme.spacing(7),
                  height: theme.spacing(7)
                }}
              />
            </ImageBox>
          </Box>
          <IconButton
            color="inherit"
            onClick={handleDrawerMobileToggle}
            edge="end"
            sx={{ mr: 0.1, display: { md: 'none' } }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* <Box component="nav"> */}
      <Sidebar
        drawerOpen={sidebarOpen}
        drawerMobileOpen={sidebarMobileOpen}
        drawerToggle={handleDrawerToggle}
        drawerMobileToggle={handleDrawerMobileToggle}
      />
      {/* </Box> */}
      <Main open={sidebarOpen}>
        <DrawerHeader />
        <HelpButton
          {...(matches && {
            sx: {
              display: 'none'
            }
          })}
        />
        {children}
      </Main>
    </Box>
  )
}

export default Layout
