import React from 'react'
import { useParams } from 'react-router-dom'

import { BiBarcodeReader, BiUserCheck } from 'react-icons/bi'
import { FaUniversity } from 'react-icons/fa'

import {
  Box,
  useTheme,
  Typography,
  Button,
  Divider,
  SxProps,
  Stack,
  CircularProgress
} from '@mui/material'

import headerImg from '../../assets/images/payment/voucher/headerImg.png'
import logo from '../../assets/images/logo/logo-4ies-branca-contact.png'
import bankIcon from '../../assets/images/clarity_bank-solid.svg'

import { GetChargeVoucherService } from '../../services/charge/GetChargeVoucherService'
import { Voucher as VoucherProps } from '../../shared/interfaces/voucher'
import { formatCurrency, formatDate } from '../../utils/format'
import { format, parseISO } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import html2canvas from 'html2canvas'
import { fileDownloader } from '../../utils/FileDownloader'
import { billetMask, cnpjMask, cpfHiddenMask } from '../../utils/mask'

import DownloadIcon from '@mui/icons-material/Download'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

const VoucherDetailsItem = ({
  label,
  value,
  labelSx,
  valueSx
}: {
  label: string
  value?: string | string[]
  labelSx?: SxProps
  valueSx?: SxProps
}) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        mt: 2,
        width: '100%',
        display: 'flex',
        padding: theme.spacing(0, 2),
        justifyContent: 'space-between'
      }}
    >
      <Typography
        fontSize={{ xs: theme.spacing(1.5), md: theme.spacing(1.75) }}
        sx={{ fontWeight: 'bold', ...labelSx }}
      >
        {label}:
      </Typography>
      {/* <Typography
        fontSize={{ xs: theme.spacing(1.5), md: theme.spacing(1.75) }}
      > */}
      {typeof value === 'string' ? (
        <Typography
          fontSize={{ xs: theme.spacing(1.5), md: theme.spacing(1.75) }}
          sx={valueSx}
        >
          {value.replace('<br>', '').replace(';', '')}
        </Typography>
      ) : (
        <Box>
          {value?.map((desc, index) => (
            <Typography
              key={index}
              fontSize={{ xs: theme.spacing(1.5), md: theme.spacing(1.75) }}
              sx={valueSx}
            >
              {desc.replace('<br>', '').replace(';', '')}
            </Typography>
          ))}
        </Box>
      )}
      {/* </Typography> */}
    </Box>
  )
}

const Voucher = () => {
  const theme = useTheme()
  const { idCustomer, idCharge, idOrganization } = useParams()

  const [voucher, setVoucher] = React.useState<VoucherProps>()

  React.useEffect(() => {
    // console.log(idCustomer, idCharge, idOrganization)
    handleGetVoucherData()
  }, [])

  const handleGetVoucherData = async () => {
    const service = new GetChargeVoucherService()

    try {
      const response = await service.execute({
        idCharge: Number(idCharge),
        idOrganization: Number(idOrganization),
        idCustomer: Number(idCustomer)
      })

      setVoucher(response)
    } catch (err) {
      console.log({ err })
    }
  }

  const printVoucher = async () => {
    const paymentDate = voucher
      ? format(parseISO(voucher?.paymentDate), '_yyyy_MM_dd', {
          locale: ptBR
        })
      : ''
    const html = document.querySelector('#capture')
    if (html) {
      const data = await html2canvas(html as HTMLElement, {
        useCORS: true
      })
      const img = data.toDataURL('image/png')
      fileDownloader(img, `comprovante${paymentDate}.png`)
    }
  }
  if (!voucher) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    )
  }
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F5F5F5',
        minHeight: '100vh',
        height: '100%'
      }}
    >
      <Button onClick={printVoucher}>
        <DownloadIcon sx={{ mr: 1 }} />
        Salvar
      </Button>
      <Box
        id="capture"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
          // height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: theme.spacing(1.5),
            py: 2,
            px: 1,
            width: 320,
            maxWidth: 320,
            backgroundColor: '#fff'
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 3
            }}
          >
            <img
              id="logo"
              src={theme.logo.primaryBase64.toString()}
              alt="Logo da Instituição"
              height={theme.spacing(6)}
              crossOrigin="anonymous"
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Typography color="primary" fontWeight="bold">
                COMPROVANTE DE PAGAMENTO
              </Typography>
              <Typography>
                {format(parseISO(voucher?.paymentDate), 'dd MMM yyyy - HH:mm', {
                  locale: ptBR
                }).toUpperCase()}
                {/* {voucher.paymentDate} */}
              </Typography>
            </Box>
          </Box>
          <Stack sx={{ width: '100%', mt: 2 }}>
            <Divider />
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                p: 1
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <BiUserCheck size={30} color={theme.palette.primary.main} />{' '}
                <Typography fontSize={theme.spacing(2.4)} color="#8D8D8D">
                  Pagador
                </Typography>
              </Box>
              <Typography fontSize={theme.spacing(1.9)}>
                {voucher?.person.fullName}
              </Typography>
              <Typography fontSize={theme.spacing(1.9)} color="#8D8D8D">
                {cpfHiddenMask(voucher?.person.documentId)}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                p: 1
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccountBalanceIcon
                  sx={{ color: theme.palette.primary.main }}
                />
                <Typography fontSize={theme.spacing(2.4)} color="#8D8D8D">
                  Favorecido
                </Typography>
              </Box>
              <Typography fontSize={theme.spacing(1.9)}>
                {voucher?.customer.fullName}
              </Typography>
              <Typography fontSize={theme.spacing(1.9)} color="#8D8D8D">
                {cnpjMask(voucher?.customer.documentId)}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                py: 1
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 1 }}
              >
                <BiBarcodeReader size={30} color={theme.palette.primary.main} />{' '}
                <Typography fontSize={theme.spacing(2.4)} color="#8D8D8D">
                  Documentos
                </Typography>
              </Box>
              {voucher?.debts.map((debt, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 1,
                    mt: 1,
                    backgroundColor: '#F5F5F5',
                    borderRadius: 1
                  }}
                >
                  <Typography fontSize={theme.spacing(1.65)} color="#8D8D8D">
                    MENSALIDADE
                  </Typography>
                  {/* TODO: verificar quais dados serão utilizados */}
                  <Typography fontSize={theme.spacing(1.9)}>
                    {debt.referenceId}
                  </Typography>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box>
                      <Typography
                        fontSize={theme.spacing(1.65)}
                        color="#8D8D8D"
                      >
                        VENCIMENTO
                      </Typography>
                      <Typography fontSize={theme.spacing(1.9)}>
                        {formatDate(debt.dueDate)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        fontSize={theme.spacing(1.65)}
                        color="#8D8D8D"
                      >
                        VALOR R$
                      </Typography>
                      <Typography textAlign="end" fontSize={theme.spacing(1.9)}>
                        {debt.amount.toFixed(2).replace('.', ',')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                p: 1,
                mt: 1,
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Typography textAlign="end" fontSize={theme.spacing(1.65)}>
                  ENCARGOS
                </Typography>
                <Typography textAlign="end" fontSize={theme.spacing(1.9)}>
                  {formatCurrency(voucher?.addition)}
                </Typography>
              </Box>
              <Box>
                <Typography textAlign="end" fontSize={theme.spacing(1.65)}>
                  DESCONTOS
                </Typography>
                <Typography textAlign="end" fontSize={theme.spacing(1.9)}>
                  {formatCurrency(voucher?.discount)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  textAlign="end"
                  fontWeight="bold"
                  fontSize={theme.spacing(1.65)}
                >
                  PAGO R$
                </Typography>
                <Typography
                  textAlign="end"
                  fontWeight="bold"
                  fontSize={theme.spacing(1.95)}
                >
                  {voucher?.amountPaid.toFixed(2).replace('.', ',')}
                </Typography>
              </Box>
            </Box>
            {!!voucher?.billetPayNumber && (
              <Box
                sx={{
                  mt: 1,
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1
                }}
              >
                <Typography fontSize={theme.spacing(1.9)} color="#8D8D8D">
                  Linha digitável do boleto
                </Typography>
                <Typography fontSize={theme.spacing(1.65)}>
                  {billetMask(voucher?.billetPayNumber)}
                </Typography>
              </Box>
            )}
            <Divider />
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                p: 1
              }}
            >
              <Typography fontSize={theme.spacing(1.9)} color="#8D8D8D">
                ID da transação
              </Typography>
              <Typography fontSize={theme.spacing(1.65)}>
                {voucher?.paymentVoucherId}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#8D8D8D'
          }}
        >
          <Typography>Intermediadora</Typography>
          <Typography>4IES GESTÃO E TECNOLOGIA LTDA</Typography>
          <Typography>34.560.209/0001-21</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Voucher
