import { useEffect, useState } from 'react'
import { Box, useMediaQuery, useTheme, Fab } from '@mui/material'

import Layout from '../../components/Layout'
import PageTitle from '../../components/PageTitle'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useParams, useSearchParams } from 'react-router-dom'
import JunoCheckout from '../../utils/cardHash'
import { CheckoutScreen } from '../../components/CheckoutScreen'

const Checkout = () => {
  const theme = useTheme()

  const { hash } = useParams()
  const [searchParams] = useSearchParams()
  const method = searchParams.get('method')

  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)

  const [showScrollButton, setShowScrollButton] = useState(false)
  const [scrollHeight, setScrollHeight] = useState(0)
  const resizeObserver = new ResizeObserver(entries => entries)

  useEffect(() => {
    if (window) {
      resizeObserver.observe(window.document.body)
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    handleScrollHeight()
  }, [resizeObserver])

  const handleScrollHeight = () => {
    const height = window.document.body.scrollHeight
    setScrollHeight(height)
    if (
      window.scrollY <
      window.document.body.scrollHeight - window.innerHeight
    ) {
      setShowScrollButton(true)
    } else {
      setShowScrollButton(false)
    }
  }
  const handleScroll = () => {
    if (
      window.scrollY <
      window.document.body.scrollHeight - window.innerHeight
    ) {
      setShowScrollButton(true)
    } else {
      setShowScrollButton(false)
    }
  }
  const handleScrollToEnd = () => {
    window.scrollTo(0, scrollHeight)
  }

  if (!hash) {
    return <Layout></Layout>
  }

  return (
    <Layout title="Checkout">
      {matches && showScrollButton && (
        <Box
          sx={{
            zIndex: 9999,
            position: 'fixed',
            bottom: 56,
            right: 4,
            backgroundColor: '#fff',
            borderColor: theme.palette.primary.main,
            borderWidth: '8px',
            borderRadius: 16,
            p: 0.1
          }}
        >
          <Fab
            size="small"
            variant="extended"
            sx={{ borderColor: '#fff', borderWidth: 16 }}
            color="primary"
            aria-label="add"
            onClick={handleScrollToEnd}
          >
            <KeyboardArrowDownIcon fontSize="small" />
          </Fab>
        </Box>
      )}
      <JunoCheckout />
      <Box
        sx={{
          maxWidth: {
            xs: '100%',
            sm: theme.breakpoints.values.sm
          },
          p: 1
        }}
      >
        <PageTitle title="Pagamento" />
        <CheckoutScreen shareHash={String(hash)} method={method ?? undefined} />
      </Box>
    </Layout>
  )
}

export default Checkout
