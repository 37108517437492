import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, takeEvery } from 'redux-saga/effects'
import { GetOrganizationByCustomerService } from '../../services/organization/getOrganizationByCustomerService'
import { Organization } from '../../shared/interfaces/organization'
import {
  getOrganizationByCustomerFailure,
  getOrganizationByCustomerStart,
  getOrganizationByCustomerSuccess
} from '../slice/organizationSlice'

export function* handleGetOrganizationByCustomer({
  payload
}: PayloadAction<{ idCustomer: number }>): Generator<
  unknown,
  void,
  Organization[]
> {
  try {
    const { idCustomer } = payload
    const service = new GetOrganizationByCustomerService()
    const response = yield call(service.execute, { idCustomer })

    yield put(getOrganizationByCustomerSuccess(response))
  } catch (err) {
    yield put(getOrganizationByCustomerFailure(String(err)))
  }
}

export function* watchOrganization() {
  yield takeEvery(
    getOrganizationByCustomerStart.type,
    handleGetOrganizationByCustomer
  )
}
