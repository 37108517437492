import api from '../api'

interface AuthenticateUserServiceProps {
  username: string
  password: string
  utmSource?: string
}

class AuthenticateUserService {
  async execute(props: AuthenticateUserServiceProps) {
    const url = props.utmSource
      ? `/v1/auth/signIn/utmSource/${props.utmSource}`
      : `/v1/auth/signIn`

    const data = await api
      .post(url, null, {
        auth: props
      })
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { AuthenticateUserService }
