import { AxiosError } from 'axios'
import api from '../api'

import { Authentication } from '../../shared/interfaces/authentication'

interface SingleSignOnServiceProps {
  utmSource: string
  identificationHash: string
  authorization: string
  // TODO: Update to new endpoint
  // username: string
  // password: string
}

class SingleSignOnService {
  async execute({
    utmSource,
    identificationHash,
    authorization
  }: SingleSignOnServiceProps): Promise<Authentication> {
    const url = `/v1/auth/${utmSource}/sso/signIn/${identificationHash}`

    const data = await api
      .post(url, null, {
        headers: {
          authorization
        }
      })
      .then(({ data }) => data)
      .catch(err => {
        const error = err as AxiosError
        if (error.isAxiosError) {
          throw error.response?.data
        }
        throw err
      })
    return data
  }
}

export { SingleSignOnService }
