import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Organization } from '../../shared/interfaces/organization'

export interface OrganizationState {
  status: 'idle' | 'loading' | 'failed'
  error?: string
  organizations: Organization[]
}

const initialState: OrganizationState = {
  status: 'idle',
  organizations: []
}

export const organizationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getOrganizationByCustomerStart: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{ idCustomer: number }>
    ) => ({
      ...state,
      status: 'loading'
    }),
    getOrganizationByCustomerSuccess: (
      state,
      action: PayloadAction<Organization[]>
    ) => ({
      ...state,
      status: 'idle',
      organizations: action.payload
    }),
    getOrganizationByCustomerFailure: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      status: 'failed',
      error: action.payload
    })
  }
})

export const {
  getOrganizationByCustomerStart,
  getOrganizationByCustomerSuccess,
  getOrganizationByCustomerFailure
} = organizationSlice.actions

export default organizationSlice.reducer
