import api from '../api'

interface RegisterNewPasswordServiceProps {
  id: number
  typeId: string
  documentId: string
  pin: string
  pass: string
}

class RegisterNewPasswordService {
  async execute(props: RegisterNewPasswordServiceProps) {
    const url = `/v1/auth/password`
    const data = await api
      .post(url, props)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { RegisterNewPasswordService }
