import { Box, CircularProgress, Paper, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { HelpBox } from '../../components/HelpBox'
import { ROUTES } from '../../constants/routes'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import {
  singleSignOnFailure,
  singleSignOnStart,
  singleSignOnSuccess
} from '../../redux/slice/authSlice'
import { login, storeCustomer } from '../../services/auth/common'
import { SingleSignOnService } from '../../services/auth/SingleSignOnService'

const SingleSignOn = () => {
  const theme = useTheme()

  const navigate = useNavigate()
  // const params = useParams()
  const [searchParams] = useSearchParams()

  // const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAppSelector(state => state.auth)

  const { ka, kd, src } = Object.fromEntries(new URLSearchParams(searchParams))

  useEffect(() => {
    handleSignOn()
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      navigate(ROUTES.BOARD.pathname)
    }
  }, [isAuthenticated])

  const handleSignOn = async () => {
    // dispatch(
    //   singleSignOnStart({
    //     utmSource: src,
    //     identificationHash: String(kd),
    //     authorization: String(ka)
    //   })
    // )

    const service = new SingleSignOnService()
    try {
      const response = await service.execute({
        utmSource: src,
        identificationHash: String(kd),
        authorization: String(ka)
      })
      if (response) {
        storeCustomer(response.customers[0])
        const payload = {
          data: response,
          ...(response.customers.length === 1 && {
            customer: response.customers[0]
          })
        }

        dispatch(singleSignOnSuccess(payload))
        login(response.accessToken, response.refreshToken)
      }
    } catch (err) {
      setHasError(true)
      dispatch(singleSignOnFailure(String(err)))
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {hasError ? (
        <Paper sx={{ p: 2 }} variant="outlined">
          <HelpBox message="Não foi possível acessar a plataforma de pagamentos." />
        </Paper>
      ) : (
        <CircularProgress color="secondary" />
      )}
    </Box>
  )
}

export default SingleSignOn
