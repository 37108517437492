import { createTheme, ThemeProvider } from '@mui/material/styles'

import Routes from './routes'
import { useAppSelector } from './redux/hooks'
import { NewsPopup } from './components/NewsPopup'

declare module '@mui/material/styles' {
  interface Theme {
    drawer: {
      width: number
    }
    logo: {
      primary: string
      secondary: string
      primaryBase64: string
      secondaryBase64: string
    }
  }

  interface ThemeOptions {
    drawer: {
      width: number
    }
    logo: {
      primary: string
      secondary: string
      primaryBase64: string
      secondaryBase64: string
    }
  }
}

function App() {
  const { customerSettings } = useAppSelector(state => state.app)

  const theme = createTheme({
    typography: {
      fontFamily: ['Inter'].join(',')
    },
    palette: {
      primary: {
        main: customerSettings.hexColorPrimary
      },
      secondary: {
        main: '#fff'
      },
      error: {
        main: '#FF3D71'
      },
      warning: {
        main: '#FFA800'
      }
    },
    drawer: {
      width: 256
    },
    logo: {
      primary: customerSettings.urlImgLogo,
      secondary: customerSettings.urlImgLogoSecondary,
      primaryBase64: customerSettings.urlImgLogoBase64,
      secondaryBase64: customerSettings.urlImgLogoSecondaryBase64
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  )
}

export default App
