import { Box, CircularProgress, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import { GetChargeByHashService } from '../../services/charge/GetChargeByHashService'

const Billet = () => {
  const { hash } = useParams()

  const [billetLink, setBilletLink] = useState('')
  // }, [])
  useEffect(() => {
    if (hash) {
      handleGetChargeByHash(hash)
    }
  }, [hash])

  function downloadURI(uri: string, name: string) {
    const link = document.createElement('a')
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // delete link
  }

  function download(url: string, filename: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
      })
      .catch(console.error)
  }

  const handleGetChargeByHash = async (shareHash: string) => {
    try {
      const service = new GetChargeByHashService()
      const response = await service.execute({ shareHash })

      if (response.billetLink) {
        setBilletLink(response.billetLink)
        // downloadURI('data:text/html,HelloWorld!', response.billetLink)
        download(`${response.billetLink}:FDL`, 'boleto.pdf')
      }
    } catch (err) {
      console.log({ err })
    }
  }
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4
      }}
    >
      <embed
        // data="https://pay-sandbox.juno.com.br/charge/boleto.pdf?token=1985149:m:13890265e8d34e60c05ca2cf9ddd5a05584f8b23e768a8412e0558e11d2ec636:FDL"
        // src="https://pay-sandbox.juno.com.br/charge/boleto.pdf?token=1985149:m:13890265e8d34e60c05ca2cf9ddd5a05584f8b23e768a8412e0558e11d2ec636:FDL#view=fitH"
        src={`${billetLink}:FDL`}
        type="application/pdf"
        title="PrPay Pagamentos - Boleto Online"
        width="100%"
        height="100%"
        // style={{border: 'none'}}
        style={{
          borderRadius: '.5rem',
          backgroundColor: '#fff',
          objectFit: 'fill'
        }}
      ></embed>
      {/* <CircularProgress color="secondary" /> */}
    </Box>
  )
}

export default Billet
