import React, { useEffect } from 'react'

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
  Link
} from '@mui/material'
import { HelpButton } from '../HelpButton'
// import SelectSource from '../SelectSource'

import whatsAppIconWhite from '../../assets/images/whatsapp-icon-white.svg'
import supportIcon from '../../assets/images/support-icon.svg'
import supportIconWhite from '../../assets/images/support-icon-white.svg'

// import { Container } from './styles';

interface SignInLayoutProps {
  children: React.ReactNode
  title?: string | null | undefined
}

const SignInLayout: React.FC<SignInLayoutProps> = ({ children, title }) => {
  const theme = useTheme()
  const matchesXS = useMediaQuery(
    `(max-width:${theme.breakpoints.values.xs}px)`
  )

  const matchesSM = useMediaQuery(
    `(max-width:${theme.breakpoints.values.sm}px)`
  )

  useEffect(() => {
    document.title = `4IES${title ? ' | ' + title : ''}`
  }, [])

  return (
    <Box
      id="layout-box"
      sx={{
        flex: 1,
        // flexGrow: 1,
        display: 'flex',
        // height: '100vh',
        height: '100%',
        backgroundColor: { xs: theme.palette.primary.main, md: 'transparent' },
        color: { xs: theme.palette.grey[200], md: theme.palette.grey[900] }
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: theme.palette.primary.main,
          // backgroundImage: `url(${backgroundImg})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: 0,
          display: { xs: 'none', md: 'block' }
        }}
      >
        <Box
          sx={{
            // backgroundColor: '#546',
            flexGrow: 1,
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={theme.logo.secondary} alt="" height="140rem" />
        </Box>
      </Box>
      <Box
        id="form-box"
        sx={{
          flex: 1,
          minHeight: '100vh',
          width: { xs: '100%', md: '40%' },
          // width: '40%',
          padding: theme.spacing(2),
          backgroundColor: {
            xs: theme.palette.primary.main,
            md: 'transparent'
          },
          // backgroundColor: { xs: '', md: 'transparent' },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'unset' },
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: 'flex', md: 'none' },
            width: theme.breakpoints.values.xs,
            alignItems: 'center',
            justifyContent: 'center',
            mb: theme.spacing(2)
          }}
        >
          <img
            src={theme.logo.secondary}
            alt="Logo"
            height="150px"
            width="300px"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: { xs: 'none', md: theme.spacing(2, 3) },
            minWidth: { xs: '100%', md: '20rem' },
            maxWidth: { xs: '20rem', md: '24rem' }
          }}
        >
          <Box
            component="legend"
            sx={{
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography component="h5" variant="h5" textAlign="center">
              {title}
            </Typography>
          </Box>
          {children}
          {/* <SvgIcon>
            <whatsAppIconWhite />
          </SvgIcon> */}
          {/* <Typography>
            XS: {matchesXS && 'OK'} - SM: {matchesSM && 'OK'}
          </Typography> */}
          <Stack direction="row" spacing={2} mt={4} mb={4}>
            <Link
              underline="none"
              sx={{
                width: '100%',
                p: 0
              }}
              href={`https://wa.me/5511915523600`}
              target="_blank"
            >
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{
                  height: 56,
                  backgroundColor: '#75cd1f',
                  paddingLeft: 1.5,
                  paddingRight: 1.5,
                  '&:hover': {
                    backgroundColor: '#457714'
                  }
                }}
                startIcon={
                  <img
                    src={whatsAppIconWhite}
                    width={matchesSM ? theme.spacing(2.5) : theme.spacing(3)}
                    height={matchesSM ? theme.spacing(2.25) : theme.spacing(3)}
                  />
                }
              >
                <Typography
                  // fontSize={9.6}
                  textAlign="left"
                  fontWeight="bold"
                  sx={{ px: 0, fontSize: { xs: 9, md: 9.6 } }}
                >
                  BOLETO EXPRESSO NO WHATSAPP
                </Typography>
              </Button>
            </Link>
            <Link
              href="https://www.4ies.tech/suporteexpresso"
              target="_blank"
              underline="none"
              sx={{
                width: '100%',
                p: 0
              }}
            >
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                sx={{ height: 56, paddingLeft: 1.5 }}
                startIcon={
                  <img
                    src={supportIcon}
                    width={matchesSM ? theme.spacing(2) : theme.spacing(3)}
                    height={matchesSM ? theme.spacing(2) : theme.spacing(3)}
                  />
                }
              >
                <Typography
                  fontSize={9.6}
                  textAlign="left"
                  sx={{
                    color: theme.palette.primary.main,
                    px: 0,
                    fontSize: { xs: 9, md: 9.6 }
                  }}
                  fontWeight="bold"
                >
                  OUTRAS DÚVIDAS OU SUPORTE
                </Typography>
              </Button>
            </Link>
          </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default SignInLayout
