import { Box } from '@mui/material'
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate
} from 'react-router-dom'
import { CoBrandingMark } from './components/CoBrandingMark'
import ErrorPage from './components/ErrorPage'
import RequireAuth from './components/RequireAuth'
import { WhiteLabelWrapper } from './components/WhiteLabelWrapper'
import { ROUTES_PROPS } from './constants/routes'
import { customerSettingsInitialState } from './redux/slice/appSlice'
import { getCustomer } from './services/auth/get'

import { GetCustomerSettingsService } from './services/customer/GetCustomerSettingsService'

const getCustomerSettings = async (utmSource: string) => {
  const service = new GetCustomerSettingsService()
  // const { data } = await service.execute({ utmSource }).then(response => {
  const response = await service.execute({ utmSource })
  if (response) {
    const { customerSettings } = response.data
    if (customerSettings.whiteLabel === 'ACTIVE') {
      return customerSettings
    }
    return {
      ...customerSettingsInitialState,
      principiaMigration: customerSettings.principiaMigration
    }
  }
}

const router = createBrowserRouter(
  ROUTES_PROPS.map(route => ({
    path: route.pathname,
    element: route.private ? (
      <RequireAuth>
        <WhiteLabelWrapper>
          <route.component />
        </WhiteLabelWrapper>
      </RequireAuth>
    ) : (
      <WhiteLabelWrapper>
        <route.component />
      </WhiteLabelWrapper>
    ),
    loader: async ({ params }) => {
      const { utm } = params
      if (utm) {
        const customerSettings = await getCustomerSettings(utm)
        return { ...route, customerSettings }
      }
      if (route.pathname !== '/') {
        const customer = getCustomer()
        if (customer) {
          const customerSettings = await getCustomerSettings(customer.utmSource)
          return { ...route, customerSettings }
        }
      }
      if (!route.whiteLabel) {
        return { ...route, customerSettings: customerSettingsInitialState }
      }
      return { ...route, customerSettings: customerSettingsInitialState }
    },
    ...(process.env.VERCEL_ENV !== 'local' && { errorElement: <ErrorPage /> })
  }))
)

const Routes = () => (
  <Box
    sx={{ position: 'relative' }}
    // sx={{ display: 'flex', flexDirection: 'column' }}
  >
    <RouterProvider router={router} />
    {/* <CoBrandingMark /> / */}
  </Box>
)

export default Routes
