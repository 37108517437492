import { User } from '../../shared/interfaces/user'
import api from '../api'

interface GetPersonDataByIdCustomerProps {
  idCustomer: number
}

class GetPersonDataByIdCustomer {
  async execute({ idCustomer }: GetPersonDataByIdCustomerProps) {
    const url = `/v1/appPersons/customer/${idCustomer}`
    const data = await api
      .get(url)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })
    return data
  }
}

export { GetPersonDataByIdCustomer }
