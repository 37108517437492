import { Box, CircularProgress, Divider, useTheme } from '@mui/material'

interface LoadingBoxProps {
  spacing?: number
  vertical?: boolean // |
  horizontal?: boolean // __
}

const LoadingBox = ({ spacing }: LoadingBoxProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: theme.spacing(spacing ? 4 * spacing : 4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default LoadingBox
