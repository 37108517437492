/* eslint-disable no-undef */
// export default {

import { Component } from 'react'
const canUseDOM = () => {
  if (
    typeof window === 'undefined' ||
    !window.document ||
    !window.document.createElement
  ) {
    return false
  }
  return true
}

export default class JunoCheckout extends Component {
  constructor(props) {
    super(props)
    this.insertScript = this.insertScript.bind(this)
    this.onScriptLoaded = this.onScriptLoaded.bind(this)
  }

  onScriptLoaded() {
    if (typeof this.props.onLoaded === 'function') {
      this.props.onLoaded()
    }
  }

  insertScript() {
    const script = document.createElement('script')
    script.id = 'juno-api'
    script.src = process.env.REACT_APP_JUNO_URL
    script.addEventListener('load', this.onScriptLoaded)
    document.body.appendChild(script)
  }

  componentDidMount() {
    if (canUseDOM) {
      this.insertScript()
    }
  }

  render() {
    return null
  }
}

const prod = process.env.REACT_APP_VERCEL_ENV === 'production'
export const generateHash = cardData =>
  new Promise(resolve => {
    // console.log({ vercel_env: prod, public: process.env.REACT_APP_JUNO_KEY })
    const checkout = new DirectCheckout(process.env.REACT_APP_JUNO_KEY, prod)
    return checkout.getCardHash(cardData, resolve, function (error) {
      console.log('err on generate hash => ', error)
    })
  })

export const isValidCardNumber = cardNumber => {
  const checkout = new DirectCheckout(process.env.REACT_APP_JUNO_KEY, prod)
  const isValidCardNumber = checkout.isValidCardNumber(cardNumber)
  return isValidCardNumber
}

export const isValidSecurityCode = (cardNumber, securityCode) => {
  const checkout = new DirectCheckout(process.env.REACT_APP_JUNO_KEY, prod)
  const isValidSecurityCode = checkout.isValidSecurityCode(
    cardNumber,
    securityCode
  )
  return isValidSecurityCode
}
export const isValidExpireDate = (expirationMonth, expirationYear) => {
  const checkout = new DirectCheckout(process.env.REACT_APP_JUNO_KEY, prod)
  const isValidExpireDate = checkout.isValidExpireDate(
    expirationMonth,
    expirationYear
  )

  return isValidExpireDate
}
