import api from '../api'

interface ValidatePinServiceProps {
  id: number
  typeId: string
  documentId: string
  pin: string
}
class ValidatePinService {
  async execute(props: ValidatePinServiceProps) {
    const url = `/v1/auth/pin/validate`
    const data = await api
      .post(url, props)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })

    return data
  }
}

export { ValidatePinService }
