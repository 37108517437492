import React, { useState } from 'react'

import {
  Box,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Paper,
  styled,
  SwipeableDrawer,
  Typography,
  useTheme
} from '@mui/material'

import VisibilityIcon from '@mui/icons-material/Visibility'
import InfoIcon from '@mui/icons-material/Info'
import { Debt } from '../../shared/interfaces/debt'
import { formatCurrency, formatDate } from '../../utils/format'
import { grey } from '@mui/material/colors'
import PaperTextItem from '../PaperTextItem'
import { Button } from '../Button'
import { GetDebtsRecalculatedService } from '../../services/debt/GetDebtsRecalculatedService'
import { useAppSelector } from '../../redux/hooks'
import { CreateChargeService } from '../../services/charge/CreateChargeService'
import { useNavigate } from 'react-router-dom'

// import { Container } from './styles';

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 3,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  // position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)'
}))

enum DebtSituation {
  'ACTIVATION' = 'Acionamento',
  'SUSPENDED' = 'Suspenso',
  'CONTESTED' = 'Contestada',
  'COUNTERPROPOSAL' = 'Contraproposta',
  'LAWSUIT' = 'Ação Judicial'
}

interface DebtListItemProps {
  activity: {
    loading: boolean
    rule?: string
  }
  checked: boolean
  labelId: string
  debt: Debt
  onToggleCheck: () => void
  onClickDetail: ({ debt }: { debt: Debt }) => void
}

const DebtListItem: React.FC<React.Attributes & DebtListItemProps> = ({
  activity,
  debt,
  checked,
  labelId,
  onToggleCheck,
  onClickDetail
}) => {
  const theme = useTheme()
  const [detailOpen, setDetailOpen] = useState(false)
  const [debtDetail, setDebtDetail] = useState<Debt>()

  const navigate = useNavigate()
  // const handleSetDetail = async (debt: Debt) => {
  //   setDebtDetail(debt)
  // }
  // TODO: remove after api done
  const [TEMP_DEBT, setTempDebt] = useState('COUNTERPROPOSAL')

  const handleOnClickDetail = () => {
    onClickDetail({ debt })
  }

  return (
    <React.Fragment>
      <ListItem
        sx={{ display: { sm: 'flex', md: 'none' } }}
        disabled={activity.rule === 'debt.select' && activity.loading}
        {...(debt.situation === 'ACTIVATION' &&
          debt.negativation && {
            secondaryAction: (
              <Box>
                {debt.situation === 'ACTIVATION' && debt.negativation && (
                  <Typography
                    component="span"
                    variant="caption"
                    color="error"
                    sx={{ fontSize: 9 }}
                  >
                    NEGATIVADO
                  </Typography>
                )}
                <IconButton
                  id="negativation"
                  edge="end"
                  onClick={handleOnClickDetail}
                >
                  <InfoIcon color="error" />
                </IconButton>
              </Box>
            )
          })}
        {...(debt.situation !== 'ACTIVATION' && {
          secondaryAction: (
            <Box>
              {debt.situation !== 'ACTIVATION' && (
                <Typography
                  component="span"
                  variant="caption"
                  color={theme.palette.warning.main}
                  sx={{ fontSize: 9 }}
                >
                  {DebtSituation[
                    debt.situation as keyof typeof DebtSituation
                  ].toLocaleUpperCase()}
                </Typography>
              )}
              <IconButton
                id="negativation"
                edge="end"
                onClick={handleOnClickDetail}
                // sx={{ p: 0 }}
              >
                <InfoIcon color="warning" />
              </IconButton>
            </Box>
          )
        })}
        {...(debt.situation === 'ACTIVATION' && {
          secondaryAction: (
            <Box>
              {debt.situation !== 'ACTIVATION' && !debt.negativation && (
                <Typography
                  component="span"
                  variant="caption"
                  color={theme.palette.warning.main}
                  sx={{ fontSize: 9 }}
                >
                  {DebtSituation[
                    debt.situation as keyof typeof DebtSituation
                  ].toLocaleUpperCase()}
                </Typography>
              )}
              <IconButton id="detail" edge="end" onClick={handleOnClickDetail}>
                <VisibilityIcon />
              </IconButton>
            </Box>
          )
        })}
        disablePadding
      >
        {/* <ListItemButton role={undefined} dense> */}
        <ListItemIcon sx={{ minWidth: '32px' }}>
          <Checkbox
            edge="start"
            checked={checked}
            onClick={onToggleCheck}
            disabled={debt.situation !== 'ACTIVATION'}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': `xs${labelId}` }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', pb: 1 }}
            >
              <Typography component="span" align="left">
                {formatDate(debt.dueDate)} - {formatCurrency(debt.amount)}
              </Typography>
              {/* {debt.situation === 'ACTIVATION' && debt.negativation && (
                <Typography
                  component="span"
                  variant="caption"
                  color="error"
                  sx={{ mr: 5, fontSize: 9 }}
                >
                  NEGATIVADO
                </Typography>
              )}
              {debt.situation !== 'ACTIVATION' && (
                <Typography
                  component="span"
                  variant="caption"
                  color={theme.palette.warning.main}
                  sx={{ mr: 5, fontSize: 9 }}
                >
                  {DebtSituation[
                    debt.situation as keyof typeof DebtSituation
                  ].toLocaleUpperCase()}
                </Typography>
              )} */}
            </Box>
          }
          secondary={debt.description}
          secondaryTypographyProps={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '90%',
            whiteSpace: 'nowrap'
          }}
        />
      </ListItem>
      <Divider sx={{ display: { md: 'none' } }} />

      <ListItem
        // key={key}
        disabled={activity.rule === 'debt.select' && activity.loading}
        sx={{
          display: { xs: 'none', md: 'flex' },
          height: theme.spacing(14),
          padding: 0.5,
          '& :hover': {
            cursor: 'pointer',
            filter: 'brightness(0.98)'
          }
        }}
        {...(debt.situation === 'ACTIVATION' && { onClick: onToggleCheck })}
      >
        <Paper
          sx={{
            display: 'flex',
            flexGrow: 1,
            padding: theme.spacing(1, 4),
            ...(checked && {
              filter: 'brightness(0.98)'
            })
          }}
          variant="outlined"
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `md${labelId}` }}
              disabled={debt.situation !== 'ACTIVATION'}
            />
          </ListItemIcon>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // flexGrow: 1
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'space-between'
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: theme.spacing(1.5),
                    fontWeight: 'bold'
                  }}
                  component="span"
                >
                  {debt.description}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1
                // padding: theme.spacing(0.5, 1)
              }}
            >
              <ListItemText
                primary="Valor Parcela"
                secondary={formatCurrency(debt.amount)}
              />
              <ListItemText
                primary="Multa"
                secondary={formatCurrency(debt.fine)}
              />
              <ListItemText
                primary="Juros"
                secondary={formatCurrency(debt.interest)}
              />
              <ListItemText
                primary="Perda Convênio"
                secondary={formatCurrency(debt.additionalValues)}
              />
              <ListItemText
                primary="Vencimento"
                secondary={formatDate(debt.dueDate)}
                {...(debt.overdueDays > 0 && {
                  secondaryTypographyProps: {
                    color: '#f45'
                  }
                })}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: theme.spacing(16)
                  // maxWidth: theme.spacing(16),
                  // backgroundColor: 'rgba(255, 61, 113, 0.1)'
                }}
              >
                {debt.situation === 'ACTIVATION' && debt.negativation && (
                  <Typography
                    sx={{
                      width: '100%',
                      backgroundColor: 'rgba(255, 61, 113, 0.1)',
                      fontSize: theme.spacing(1.5),
                      borderRadius: theme.spacing(0.5),
                      p: 0.5
                    }}
                    color="error"
                    textAlign="center"
                  >
                    NEGATIVADO
                  </Typography>
                )}
                {debt.situation !== 'ACTIVATION' && (
                  <Typography
                    sx={{
                      width: '100%',
                      color: '#fff',
                      backgroundColor: '#FFA800',
                      fontSize: theme.spacing(1.5),
                      borderRadius: theme.spacing(0.5),
                      p: 0.5
                    }}
                    textAlign="center"
                  >
                    {DebtSituation[
                      debt.situation as keyof typeof DebtSituation
                    ].toLocaleUpperCase()}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </ListItem>

      {/* <Divider /> */}
    </React.Fragment>
  )
}

export default DebtListItem
