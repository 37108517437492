import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Customer } from '../../shared/interfaces/customer'
import { User } from '../../shared/interfaces/user'

export interface UserState {
  status: 'idle' | 'loading' | 'failed'
  error?: string
  user: User | null
  selectedCustomer: Customer | null
}

const initialState: UserState = {
  status: 'idle',
  user: null,
  selectedCustomer: null
}

export const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getUserDataStart: (state, action: PayloadAction<number>) => ({
      ...state,
      status: 'loading'
    }),
    getUserDataSuccess: (state, action: PayloadAction<User>) => ({
      ...state,
      status: 'idle',
      user: action.payload
    }),
    getUserDataFailure: (state, action: PayloadAction<string>) => ({
      ...state,
      status: 'failed',
      error: action.payload
    })
    // storeUserData: (state, action: PayloadAction<>) => ({
    //   ...state,
    //   selectedCustomer: action.payload
    // }),
  }
})

export const { getUserDataStart, getUserDataSuccess, getUserDataFailure } =
  userSlice.actions

export default userSlice.reducer
