import { Debt } from '../../shared/interfaces/debt'
import api from '../api'

type Charge = {
  status: string
  type: string
  amount: number
  dueDate: string
  billetLink: string
  billetBarcodeNumber: string
  billetPayNumber: string
  shareHash: string
  person: {
    firstName: string
  }
  customer: {
    displayName: string
    urlImgLogo: string
  }
  debts: Debt[]
  chargePix: {
    id: number
    payload: string
    image: string
  }
  paymentMethods: [
    {
      type: string
      installmentsOptions: [
        {
          installment: number
          amountInstallment: number
          totalAmount: number
        }
      ]
    }
  ]
}

interface CreateChargeServiceProps {
  idCustomer: number
  idOrganization: number
  debts: { debtId: number }[]
  totalAmount: number
}
class CreateChargeService {
  async execute(props: CreateChargeServiceProps): Promise<Charge> {
    const url = `/v2/appCharges`
    const data = api
      .post(url, props)
      .then(({ data }) => data)
      .catch(err => {
        throw err
      })
    return data
  }
}

export { CreateChargeService }
