import { Fragment, SyntheticEvent, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  List,
  ListItem,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material'
import { PaymentMethod } from '../../shared/enum/payment'
import {
  CheckoutData,
  InstallmentsOption
} from '../../shared/interfaces/checkoutData'
import { BilletBox } from '../BilletBox'
import { CheckoutDetails } from '../CheckoutDetails'
import CreditCardForm from '../CreditCardForm'
import PageTitle from '../PageTitle'
import { PaymentSuccessBox } from '../PaymentSuccessBox'

import { FaBarcode, FaCreditCard } from 'react-icons/fa'
import { monthYearMask } from '../../utils/mask'
import { PaymentChargeService } from '../../services/charge/PaymentChargeService'
import {
  activityEnd,
  activityStart,
  setCustomerSettings,
  setUtmSource
} from '../../redux/slice/appSlice'
import { setSnackbar } from '../../redux/slice/snackbarSlice'
import { AxiosError } from 'axios'
import DirectCheckout from '../../lib/direct-checkout'
import { GetChargeByHashService } from '../../services/charge/GetChargeByHashService'
import { PaymentChargeWithNoAuthService } from '../../services/charge/PaymentChargeWithNoAuthService'
import { GetChargeByHashWithNoAuthService } from '../../services/charge/GetChargeByHashWithNoAuthService'
import LoadingBox from '../LoadingBox'
import { HelpTypes } from '../HelpBox/Helps/HelpTypes'
import { Button } from '../Button'
import { useNavigate } from 'react-router-dom'
import { HelpBox } from '../HelpBox'
import { ROUTES } from '../../constants/routes'
import { Environment } from '../../lib/direct-checkout/direct-checkout'

interface Props {
  shareHash: string
  withAuth?: boolean
  method?: string
}

const paymentMethodType = {
  BOLETO_PIX: { label: 'BOLETO PIX', icon: <FaBarcode size={24} /> },
  BOLETO: { label: 'BOLETO', icon: <FaBarcode size={24} /> },
  PIX: { label: 'PIX', icon: <FaBarcode size={24} /> },
  CREDIT_CARD: {
    label: 'CARTÃO DE CRÉDITO',
    icon: <FaCreditCard size={24} />
  }
}

const CheckoutScreen = ({ shareHash, withAuth = true, method }: Props) => {
  const dispatch = useAppDispatch()
  const { activity } = useAppSelector(state => state.app)
  const directCheckout = new DirectCheckout()

  const [checkoutData, setCheckoutData] = useState<CheckoutData>()
  const [isExpired, setIsExpired] = useState(false)
  const navigate = useNavigate()

  // const [paymentMethod, setPaymentMethod] = useState<string | false>(
  //   method || false
  // )
  const [paymentMethod, setPaymentMethod] = useState<string | false>(
    method ?? false
  )

  const [installments, setInstallments] = useState<InstallmentsOption>()
  const [installmentsOptions, setInstallmentsOptions] = useState(
    [] as InstallmentsOption[]
  )

  const [creditCard, setCreditCard] = useState({
    hash: 'teste',
    cardNumber: '',
    holderName: '',
    securityCode: '',
    expirationMonth: '',
    expirationYear: '',
    expiration: ''
  })
  const [creditCardError, setCreditCardError] = useState({
    INVALID_NUMBER: false,
    INVALID_SECURITY_CODE: false,
    INVALID_EXPIRATION_MONTH: false,
    INVALID_EXPIRATION_YEAR: false,
    INVALID_PUBLIC_KEY: false,
    INVALID_HOLDER: false,
    showBox: false
  })

  useEffect(() => {
    if (shareHash) {
      handleGetChargeByHash(shareHash)
    }
  }, [shareHash])

  const handleGetChargeByHash = async (shareHash: string) => {
    try {
      const service = withAuth
        ? new GetChargeByHashService()
        : new GetChargeByHashWithNoAuthService()
      const response = await service.execute({ shareHash })

      if (!response) {
        setIsExpired(true)
      }
      if (response) {
        dispatch(setUtmSource(response.customer.utmSource))
        const options = response.paymentMethods.find(
          item => item.type === 'CREDIT_CARD'
        )?.installmentsOptions
        if (options) {
          setInstallmentsOptions(
            options.sort((a, b) => a.installment - b.installment)
          )

          setInstallments(options[0])
          setCheckoutData(response)
        }
      }
    } catch (err) {
      console.log({ err })
      setIsExpired(true)
    }
  }

  const handleChangePaymentMethod =
    (method: keyof typeof PaymentMethod) =>
    (event: SyntheticEvent, newExpanded: boolean) => {
      setPaymentMethod(newExpanded ? method : false)
      setInstallments(
        newExpanded && method === PaymentMethod.CREDIT_CARD
          ? installmentsOptions[0]
          : undefined
      )
    }

  const handleChangeCardData = (
    event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<number>,
    field: string,
    value: string | number
  ) => {
    if (field === 'installments') {
      const installmentIndex = installmentsOptions.findIndex(
        item => item.installment === value
      )
      setInstallments(installmentsOptions[installmentIndex])
      return
    }

    setCreditCardError({
      ...creditCardError,
      [field]: false
    })
    if (field === 'expiration') {
      const expiration = monthYearMask(value)
      const [month, year] = expiration.split(' / ')
      if (Number(month) > 12) return
      setCreditCard({
        ...creditCard,
        [field]: monthYearMask(value),
        expirationMonth: month,
        expirationYear: year
      })
      return
    }
    setCreditCard({ ...creditCard, [field]: value })
  }

  const handlePayCharge = async () => {
    setCreditCardError({
      INVALID_NUMBER: false,
      INVALID_SECURITY_CODE: false,
      INVALID_EXPIRATION_MONTH: false,
      INVALID_EXPIRATION_YEAR: false,
      INVALID_PUBLIC_KEY: false,
      INVALID_HOLDER: false,
      showBox: false
    })
    const service = withAuth
      ? new PaymentChargeService()
      : new PaymentChargeWithNoAuthService()

    if (paymentMethod === 'BOLETO_PIX' || paymentMethod === 'BOLETO') {
      dispatch(activityStart({ loading: true, rule: 'pay.billet' }))
    }
    if (paymentMethod === 'PIX') {
      dispatch(activityStart({ loading: true, rule: 'pay.pix' }))
    }

    if (paymentMethod === 'CREDIT_CARD') {
      dispatch(activityStart({ loading: true, rule: 'pay.card' }))
      const cardDataHasError = await validateCardData()

      if (cardDataHasError) {
        dispatch(
          setSnackbar({
            isOpen: true,
            message: 'Verifique os dados do cartão',
            severity: 'error'
          })
        )
        dispatch(activityEnd())
        return
      }
    }

    let cardHash
    if (paymentMethod === 'CREDIT_CARD') {
      try {
        cardHash = await directCheckout.validateAndEncryptCard({
          publicKey: String(process.env.REACT_APP_CARD_PUB_KEY),
          holderName: creditCard.holderName,
          cardNumber: creditCard.cardNumber.replace(/ /g, ''),
          securityCode: creditCard.securityCode,
          expMonth: creditCard.expirationMonth,
          expYear: creditCard.expirationYear,
          environment: process.env.REACT_APP_VERCEL_ENV as Environment
        })
      } catch (err) {
        dispatch(
          setSnackbar({
            isOpen: true,
            message: String(err),
            severity: 'error'
          })
        )
      }
    }
    const paymentData = {
      chargeType:
        paymentMethod === 'BOLETO' || paymentMethod === 'PIX'
          ? 'BOLETO_PIX'
          : String(paymentMethod),
      shareHash: shareHash,
      totalAmount: Number(checkoutData?.amount),
      ...(paymentMethod === 'CREDIT_CARD' && {
        creditCard: {
          hash: String(cardHash?.encryptedCard)
        },
        installments: installments?.installment
      })
    }
    try {
      const response = await service.execute(paymentData)
      setCheckoutData({ ...checkoutData, ...response })
    } catch (err) {
      const error = err as AxiosError
      if (error.isAxiosError) {
        const { response } = error
        const errorMessage = response?.data.message
        dispatch(
          setSnackbar({
            isOpen: true,
            message:
              String(typeof errorMessage) === 'array'
                ? String(errorMessage[0])
                : String(errorMessage),
            severity: 'error'
          })
        )
      }
    }
    dispatch(activityEnd())
  }

  const validateCardData = async () => {
    let hasError = {
      INVALID_NUMBER: false,
      INVALID_SECURITY_CODE: false,
      INVALID_EXPIRATION_MONTH: false,
      INVALID_EXPIRATION_YEAR: false,
      INVALID_PUBLIC_KEY: false,
      INVALID_HOLDER: false
    }
    const validateCard = await directCheckout.validate({
      publicKey: String(process.env.REACT_APP_CARD_PUB_KEY),
      holderName: creditCard.holderName,
      cardNumber: creditCard.cardNumber.replace(/ /g, ''),
      securityCode: creditCard.securityCode,
      expMonth: creditCard.expirationMonth,
      expYear: creditCard.expirationYear,
      environment: process.env.REACT_APP_VERCEL_ENV as Environment
    })
    if (validateCard.errors.length > 0) {
      validateCard.errors.forEach(error => {
        if (error) {
          hasError = { ...hasError, [error.code]: true }
        }
      })
    }
    setCreditCardError({
      ...hasError,
      showBox:
        hasError.INVALID_HOLDER ||
        hasError.INVALID_NUMBER ||
        hasError.INVALID_SECURITY_CODE ||
        hasError.INVALID_EXPIRATION_MONTH ||
        hasError.INVALID_EXPIRATION_YEAR
    })
    return (
      hasError.INVALID_HOLDER ||
      hasError.INVALID_NUMBER ||
      hasError.INVALID_SECURITY_CODE ||
      hasError.INVALID_EXPIRATION_MONTH ||
      hasError.INVALID_EXPIRATION_YEAR
    )
  }

  if (!checkoutData) {
    if (isExpired) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: 6,
            alignItems: 'center'
          }}
        >
          <Paper sx={{ width: '100%', flexGrow: 1, p: 2 }} variant="outlined">
            <Typography fontWeight="bold">
              {withAuth
                ? 'Esse pagamento expirou ou não é mais válido.'
                : 'Ops... Esse link de pagamento expirou!'}
            </Typography>
            <Typography fontSize={12}>
              {withAuth
                ? 'Veja suas mensalidades em aberto ou acesso seu histórico de pagamentos.'
                : 'Não se preocupe, clique abaixo para retirar um novo boleto ou ver suas mensalidades.'}
            </Typography>
            {!withAuth && (
              <Box
                sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}
              >
                <Link
                  href="https://wa.me/5521987328602"
                  underline="none"
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#04B500',
                      '&:hover': { backgroundColor: '#059503' }
                    }}
                    fullWidth
                  >
                    Emitir Boleto no WhatsApp
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  onClick={() => navigate('/')}
                  fullWidth
                >
                  Central de Pagamentos
                </Button>
              </Box>
            )}
            {withAuth && (
              <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => navigate(ROUTES.BOARD.pathname)}
                  fullWidth
                >
                  Ver mensalidades
                </Button>
                <Button
                  variant="contained"
                  onClick={() => navigate(ROUTES.PAYMENT_HISTORY.pathname)}
                  fullWidth
                >
                  Histórico de pagamento
                </Button>
              </Box>
            )}
          </Paper>
          {!withAuth && (
            <Box sx={{ mt: 4 }}>
              <HelpBox description="Caso tenha dúvida, fique à vontade para falar conosco nos canais abaixo." />
            </Box>
          )}
        </Box>
      )
    }
    return (
      <Box
        sx={{
          display: 'flex',
          py: 6,
          alignItems: 'center'
        }}
      >
        <LoadingBox />
      </Box>
    )
  }

  return (
    <Stack spacing={1} sx={{ pb: 6 }}>
      <CheckoutDetails data={checkoutData} installments={installments} />

      {checkoutData.status !== 'PAID' && (
        <Box>
          <PageTitle
            title="Selecione a forma de pagamento"
            sx={{ display: checkoutData.paymentMethods ? '' : 'none' }}
          />

          <Paper
            sx={{
              mb: 2,
              display: checkoutData.paymentMethods ? '' : 'none'
            }}
          >
            <List>
              {checkoutData?.paymentMethods.map((method, index) => (
                <Fragment key={index}>
                  <ListItem
                    key={index}
                    sx={{
                      flexGrow: 1,
                      p: 1,
                      ...(method.type === paymentMethod && {
                        color: '#fff'
                      })
                    }}
                  >
                    <Accordion
                      elevation={0}
                      expanded={paymentMethod === method.type}
                      onChange={handleChangePaymentMethod(
                        method.type as keyof typeof PaymentMethod
                      )}
                      sx={{
                        flexGrow: 1
                      }}
                    >
                      <AccordionSummary>
                        <Box
                          sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center'
                          }}
                        >
                          {
                            paymentMethodType[
                              method.type as keyof typeof paymentMethodType
                            ].icon
                          }
                          <Typography sx={{ fontSize: '1.25rem' }}>
                            {
                              paymentMethodType[
                                method.type as keyof typeof paymentMethodType
                              ].label
                            }
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 2 }}>
                        {method.type === PaymentMethod.CREDIT_CARD && (
                          <CreditCardForm
                            cardData={creditCard}
                            cardDataError={creditCardError}
                            installmentsOptions={installmentsOptions}
                            installments={installments?.installment || ''}
                            onClick={handlePayCharge}
                            onChange={handleChangeCardData}
                            activity={activity}
                          />
                        )}
                        {(method.type === PaymentMethod.BOLETO_PIX ||
                          method.type === PaymentMethod.BOLETO) && (
                          <BilletBox
                            data={checkoutData}
                            onCreateBillet={handlePayCharge}
                            loading={
                              activity.rule === 'pay.billet' && activity.loading
                            }
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </ListItem>
                  {checkoutData?.paymentMethods.length !== index + 1 && (
                    <Divider />
                  )}
                </Fragment>
              ))}
            </List>
          </Paper>
        </Box>
      )}
      {checkoutData?.status === 'PAID' && <PaymentSuccessBox />}
    </Stack>
  )
}

export { CheckoutScreen }
