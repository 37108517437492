import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomerSettings } from '../../shared/interfaces/customerSettings'
import logo4IES from '../../assets/images/logo/logo-4ies-padrao.png'
import logo4IESWhite from '../../assets/images/logo/logo-4ies-branca.png'

export const customerSettingsInitialState = {
  ready: false,
  appBaseUrl: '',
  customerContactEmail: 'contato@4ies.com.br',
  customerContactLandLine: '1932019516',
  customerContactWhatsapp: '19992611693',
  customerFromName: '',
  emailFromName: '',
  hexColorPrimary: '#810786',
  hexColorSecondary: '',
  urlImgLogo: logo4IES,
  urlImgLogoSecondary: logo4IESWhite,
  urlImgLogoBase64: logo4IES,
  urlImgLogoSecondaryBase64: logo4IESWhite,
  whiteLabel: 'INACTIVE',
  principiaMigration: undefined
}

type AppConfig = {
  primaryColor: string
  logo: {
    primary: string
    secondary: string
  }
}

type Activity = {
  loading: boolean
  rule?: string
}

export interface AppState {
  activity: Activity
  sidebarOpen: boolean
  sidebarMobileOpen: boolean
  domain: 'prpay' | 'somosvalor' | '4ies'
  utmSource: string
  appConfig: AppConfig
  customerSettings: CustomerSettings
}

const initialState: AppState = {
  activity: { loading: false, rule: '' },
  sidebarOpen: true,
  sidebarMobileOpen: false,
  domain: '4ies',
  utmSource: '',
  appConfig: {
    primaryColor: '#0513c3',
    logo: {
      primary: 'https://app-dev.somosvalor.com.br/images/logo.svg',
      secondary: 'https://app-dev.somosvalor.com.br/images/logo-branca.svg'
    }
  },
  customerSettings: customerSettingsInitialState
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    activityStart: (state, action: PayloadAction<Activity>) => ({
      ...state,
      activity: action.payload
    }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activityEnd: (state, action: PayloadAction) => ({
      ...state,
      activity: { loading: false, rule: '' }
    }),
    setSidebarOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      sidebarOpen: action.payload
    }),
    setSidebarMobileOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      sidebarMobileOpen: action.payload
    }),
    setAppDomain: (
      state,
      action: PayloadAction<'prpay' | 'somosvalor' | '4ies'>
    ) => ({
      ...state,
      domain: action.payload
    }),
    setAppConfig: (state, action: PayloadAction<AppConfig>) => ({
      ...state,
      appConfig: action.payload
    }),
    setCustomerSettings: (state, action: PayloadAction<CustomerSettings>) => ({
      ...state,
      customerSettings: {
        ...action.payload,
        hexColorPrimary: action.payload.hexColorPrimary ?? '#810786',
        urlImgLogo: action.payload.urlImgLogo ?? logo4IES,
        urlImgLogoSecondary: action.payload.urlImgLogoSecondary ?? logo4IESWhite
      }
    }),
    setUtmSource: (state, action: PayloadAction<string>) => ({
      ...state,
      utmSource: action.payload
    })
  }
})

export const {
  activityStart,
  activityEnd,
  setAppDomain,
  setSidebarOpen,
  setUtmSource,
  setSidebarMobileOpen,
  setAppConfig,
  setCustomerSettings
} = appSlice.actions

export default appSlice.reducer
