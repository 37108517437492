import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
  styled
} from '@mui/material'

const StyledButton = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'rounded'
})<{ rounded?: boolean }>(({ theme, rounded, size }) => ({
  borderRadius: rounded ? '25px' : '4px',
  height: theme.spacing(5),
  ...(size === 'large' && {
    height: theme.spacing(7),
    fontSize: theme.spacing(2)
  })
}))

interface StyledButtonProps {
  rounded?: boolean
  loading?: boolean
}

export const Button: React.FC<StyledButtonProps & ButtonProps> = ({
  loading = false,
  ...props
}) => {
  return (
    <StyledButton {...props} disabled={props.disabled || loading}>
      {loading ? (
        <CircularProgress
          {...(props.color === 'primary' && { color: 'secondary' })}
          {...(props.color === 'secondary' && { color: 'primary' })}
          // color={props.color === 'secondary' ? 'primary' : 'secondary'}
          size={props.size === 'large' ? 32 : 20}
        />
      ) : (
        props.children
      )}
    </StyledButton>
  )
}
