// const useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		container: {
// 			zIndex: 9000,
// 			position: 'absolute',
// 			bottom: '1rem',
// 			right: '1rem',
// 			display: 'flex',
// 			// flexDirection: 'column',
// 			alignItems: 'center',
// 			[theme.breakpoints.down('sm')]: {
// 				position: 'fixed',
// 			},
// 		},
// 		text: {
// 			[theme.breakpoints.down('sm')]: {
// 				display: 'none',
// 			},
// 			marginTop: '.25rem',
// 			marginRight: '4rem',
// 			fontSize: '1.25rem',
// 		},
// 	})

import {
  Box,
  Fab,
  Link,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

interface Props {
  sx?: SxProps
  help?: boolean
  whatsapp?: boolean
}
const HelpButton = ({ sx, help = true, whatsapp }: Props) => {
  const theme = useTheme()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)
  return (
    <Box
      component="div"
      sx={{
        zIndex: 9000,
        position: 'fixed',
        bottom: '2.5rem',
        right: '1rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: 1,
        ...(matches && {
          position: 'fixed'
          // bottom: '1rem'
        }),
        ...sx
      }}
    >
      {whatsapp && (
        <Link
          href={`https://wa.me/5511915523600/?text=Preciso%20de%20ajuda`}
          target="_blank"
          style={{
            textDecoration: 'none'
          }}
        >
          <Fab
            sx={{
              background: '#46C757',
              height: '4rem',
              width: '4rem'
            }}
          >
            <WhatsAppIcon sx={{ fontSize: '3rem', color: '#fff' }} />
          </Fab>
        </Link>
      )}
      {help && (
        <Link
          href={`https://wa.me/5511915523600/?text=Preciso%20de%20ajuda`}
          target="_blank"
          style={{
            textDecoration: 'none'
          }}
        >
          <Fab
            sx={{
              background: '#46C757',
              fontWeight: 'bold',
              fontSize: '1.07rem'
            }}
            variant="extended"
          >
            <HelpOutlineOutlinedIcon sx={{ mr: 0.5, fontSize: '2rem' }} /> Ajuda
          </Fab>
        </Link>
      )}
    </Box>
  )
}

export { HelpButton }
