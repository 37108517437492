import { PayloadAction } from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { GetDebtsByCustomerAndOrganizationService } from '../../services/debt/GetDebtsByCustomerAndOrganizationService'
import { GetDebtsRecalculatedService } from '../../services/debt/GetDebtsRecalculatedService'
import { Debt } from '../../shared/interfaces/debt'
import { activityEnd } from '../slice/appSlice'
import {
  DebtState,
  getDebtsByCustomerAndOrganizationFailure,
  getDebtsByCustomerAndOrganizationStart,
  getDebtsByCustomerAndOrganizationSuccess,
  getDebtsRecalculatedFailure,
  getDebtsRecalculatedStart,
  getDebtsRecalculatedSuccess
} from '../slice/debtSlice'
import { RootState } from '../store'

export function* handleGetDebtsByCustomerAndOrganization({
  payload
}: PayloadAction<{ idCustomer: number; idOrganization: number }>): Generator<
  unknown,
  void,
  Debt[]
> {
  try {
    const { idCustomer, idOrganization } = payload
    const service = new GetDebtsByCustomerAndOrganizationService()
    const response = yield call(service.execute, { idCustomer, idOrganization })
    if (!response.length) {
      yield put(getDebtsByCustomerAndOrganizationSuccess([]))
      return
    }
    const debts = response.map(debt => ({
      ...debt,
      selected: debt.situation === 'ACTIVATION'
    }))
    yield put(getDebtsByCustomerAndOrganizationSuccess(debts))
  } catch (err) {
    yield put(getDebtsByCustomerAndOrganizationFailure(String(err)))
  }
}

export function* handleGetDebtsRecalculated({
  payload
}: PayloadAction<{
  idPersonDebt: number
  idCustomer: number
  idOrganization: number
  debts: { debtId: number }[]
}>): Generator<unknown, void, Debt[]> {
  try {
    const service = new GetDebtsRecalculatedService()
    const response = yield call(service.execute, payload)

    const debtsWithRecalculated = yield formatRecalculatedDebts(response)

    yield put(getDebtsRecalculatedSuccess(debtsWithRecalculated))
    yield put(activityEnd())
  } catch (err) {
    yield put(getDebtsRecalculatedFailure(String(err)))
    yield put(activityEnd())
  }
}

function* formatRecalculatedDebts(recalculatedDebts: Debt[]) {
  const { debts }: DebtState = yield select((state: RootState) => state.debt)

  const newDebts = debts.map(debt => {
    const recalculated = recalculatedDebts.find(item => item.id === debt.id)
    if (recalculated) {
      return { ...recalculated, selected: true }
    }
    return { ...debt, selected: false }
  })
  return newDebts
}

export function* watchDebt() {
  yield takeEvery(
    getDebtsByCustomerAndOrganizationStart.type,
    handleGetDebtsByCustomerAndOrganization
  )
  yield takeEvery(getDebtsRecalculatedStart.type, handleGetDebtsRecalculated)
}
