import React from 'react'

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'

import {
  Box,
  CircularProgress,
  ListItemText,
  Paper,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { Button } from '../Button'
import { formatCurrency } from '../../utils/format'

// import { Container } from './styles';

const Item = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}))

interface DebtsListTotalProps {
  activity: { loading: boolean; rule?: string }
  subTotal: number
  taxes: number
  total: number
  onClick: () => void
  visible?: boolean
  disabled?: boolean
}

const DebtsListTotal: React.FC<DebtsListTotalProps> = ({
  activity,
  subTotal,
  taxes,
  total,
  onClick,
  visible = true,
  disabled
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`)

  if (!visible) {
    return null
  }
  return (
    <Box
      sx={{
        p: theme.spacing(2, 0.5),
        maxWidth: {
          xs: '100%',
          sm: '100%'
        },
        ...(matches && {
          p: 0,
          position: 'fixed',
          bottom: visible ? theme.spacing(2.5) : '-100%',
          left: 0,
          right: 0
        })
      }}
    >
      <Paper
        variant={matches ? 'elevation' : 'outlined'}
        elevation={0}
        sx={{ p: theme.spacing(0, 2) }}
      >
        <Stack
          direction={matches ? 'column' : 'row'}
          sx={{
            p: theme.spacing(4, 6),
            display: { xs: 'none', md: 'flex' }
          }}
          // alignItems="center"
          // justifyContent="center"
        >
          <ListItemText
            primary="Valor Parcelas"
            secondary={
              activity.rule === 'debt.select' && activity.loading
                ? '. . .'
                : formatCurrency(subTotal)
            }
            primaryTypographyProps={{
              fontSize: 18
            }}
            secondaryTypographyProps={{
              fontSize: 22
            }}
          />
          <ListItemText
            primary="Encargos"
            secondary={
              activity.rule === 'debt.select' && activity.loading
                ? '. . .'
                : formatCurrency(taxes)
            }
            primaryTypographyProps={{
              fontSize: 18
            }}
            secondaryTypographyProps={{
              fontSize: 22
            }}
          />
          <ListItemText
            primary="Valor Total"
            secondary={
              activity.rule === 'debt.select' && activity.loading
                ? '. . .'
                : formatCurrency(total)
            }
            primaryTypographyProps={{
              fontSize: 18
            }}
            secondaryTypographyProps={{
              fontSize: 22,
              color: theme.palette.primary.main
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: theme.spacing(25),
              maxWidth: theme.spacing(25)
            }}
          >
            <Button
              rounded
              disabled={activity.loading || disabled}
              variant="contained"
              onClick={onClick}
              fullWidth
              // size="large"
              loading={activity.rule === 'create.charge' && activity.loading}
            >
              {activity.rule === 'debt.select' && activity.loading ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} /> Calculando
                </>
              ) : (
                <>
                  <AttachMoneyIcon /> Pagar Total
                </>
              )}
            </Button>
          </Box>
        </Stack>
        <Stack
          spacing={1}
          sx={{
            p: theme.spacing(2, 0),
            display: { xs: 'flex', md: 'none' }
          }}
        >
          <Item>
            <Typography color="GrayText">Valor Parcelas</Typography>
            <Typography color="GrayText">
              {activity.rule === 'debt.select' && activity.loading
                ? '. . .'
                : formatCurrency(subTotal)}
            </Typography>
          </Item>
          <Item>
            <Typography color="GrayText">Encargos</Typography>
            <Typography color="GrayText">
              {activity.rule === 'debt.select' && activity.loading
                ? '. . .'
                : formatCurrency(taxes)}
            </Typography>
          </Item>
          <Item>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
              TOTAL A PAGAR
            </Typography>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
              {activity.rule === 'debt.select' && activity.loading
                ? '. . .'
                : formatCurrency(total)}
            </Typography>
          </Item>
          <Button
            rounded
            disabled={activity.loading || disabled}
            variant="contained"
            // size="large"
            onClick={onClick}
            fullWidth
            loading={activity.rule === 'create.charge' && activity.loading}
          >
            {activity.rule === 'debt.select' && activity.loading ? (
              <>
                <CircularProgress size={20} sx={{ mr: 1 }} /> Calculando
              </>
            ) : (
              <>
                <AttachMoneyIcon /> Pagar Total
              </>
            )}
          </Button>
        </Stack>
      </Paper>
    </Box>
  )
}

export default DebtsListTotal
