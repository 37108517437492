import { useState } from 'react'
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material'

import CopyToClipboard from 'react-copy-to-clipboard'

import pixLogo from '../../assets/images/pix-106.svg'
import { CheckoutData } from '../../shared/interfaces/checkoutData'
import { Button } from '../Button'
import { setSnackbar } from '../../redux/slice/snackbarSlice'
import { useAppDispatch } from '../../redux/hooks'
import { fileDownloader } from '../../utils/FileDownloader'
import { format, parseISO } from 'date-fns'

interface BilletDataBoxProps {
  data: CheckoutData | undefined
  showPDFButton?: boolean
}

const BilletDataBox = ({ data }: BilletDataBoxProps) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()

  const [downloadLoading, setDownloadLoading] = useState(false)

  const handleDownloadBillet = async () => {
    setDownloadLoading(true)

    await fileDownloader(
      String(data?.billetLink),
      `BOLETO_${format(parseISO(String(data?.dueDate)), 'yyyy_MM_dd')}`
    )
    setDownloadLoading(false)
  }

  return (
    <Box>
      <Stack spacing={theme.spacing(2)}>
        {(data?.type === 'PIX' || data?.type === 'BOLETO_PIX') && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around'
              }}
            >
              <img src={pixLogo} alt="PIX Logo" height="150px" width="150px" />
              <img
                src={`data:image/jpeg;base64,${data?.chargePix?.image}`}
                alt="PIX QR Code"
                height="150px"
                width="150px"
              />
            </Box>
            <TextField
              label="Pix Copia e Cola"
              value={data?.chargePix?.payload}
              size="small"
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    text={String(data?.chargePix?.payload)}
                    onCopy={() =>
                      dispatch(
                        setSnackbar({
                          isOpen: true,
                          message: 'Copiado',
                          severity: 'success'
                        })
                      )
                    }
                  >
                    <InputAdornment position="end">
                      {/* <Box> */}
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: theme.palette.info.main
                        }}
                      >
                        Copiar
                      </Typography>
                      {/* </Box> */}
                    </InputAdornment>
                  </CopyToClipboard>
                )
              }}
            />
          </>
        )}
        {(data?.type === 'BOLETO' || data?.type === 'BOLETO_PIX') && (
          <>
            <TextField
              label="Boleto - Código de barras"
              value={data?.billetPayNumber}
              size="small"
              InputProps={{
                endAdornment: (
                  <CopyToClipboard
                    text={String(data?.billetPayNumber)}
                    onCopy={() =>
                      dispatch(
                        setSnackbar({
                          isOpen: true,
                          message: 'Copiado',
                          severity: 'success'
                        })
                      )
                    }
                  >
                    <InputAdornment position="end">
                      {/* <Box> */}
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          color: theme.palette.info.main
                        }}
                      >
                        Copiar
                      </Typography>
                      {/* </Box> */}
                    </InputAdornment>
                  </CopyToClipboard>
                )
              }}
            />
            <Button
              variant="contained"
              rounded
              onClick={handleDownloadBillet}
              loading={downloadLoading}
            >
              Gerar PDF do Boleto
            </Button>
          </>
        )}
      </Stack>
    </Box>
  )
}

export { BilletDataBox }
